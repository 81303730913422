import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Translate } from "react-localize-redux";
import { updatePasswordSettings } from '../../actions';
import { globalRegExes } from '../../utilities/RegEx';

const FIELDS = {
    password: {
        name: 'password',
        type: 'password',
        label: 'currentPassword',
        required: true,
        errorMsg: 'passwordIsInvalid',
        placeHolder: '*******'
    },
    passwordNew: {
        name: 'passwordNew',
        type: 'password',
        label: 'passwordNew',
        required: true,
        errorMsg: 'passwordIsInvalid',
        placeHolder: ' Min. 8 characters'
    },
    passwordNewConfirm: {
        name: 'passwordNewConfirm',
        type: 'password',
        label: 'passwordConfirm',
        required: true,
        errorMsg: 'passwordConfirmIsInvalid',
        placeHolder: 'Min. 8 characters'
    }
};

class PasswordSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            error: '',
            showPassword: false,
            showPasswordNew: false,
            showPasswordNewConfirm: false
        };
    }

    togglePasswordVisibility(fieldName) {
        this.setState((prevState) => ({
            [fieldName]: !prevState[fieldName]
        }));
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;
        const className = `form-group ${touched && error ? 'has-error' : 'has-success'}`;

        const isPasswordField = input.name && input.name.includes('password');
        const fieldType = this.state[`show${_.startCase(input.name)}`] ? 'text' : type;

        return (
            <div className={`change-password-field-wrapper ${className}`}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <label className="control-label"><Translate id={label} /></label>
                        {touched && error && (
                            <div className="help-block with-errors"
                                style={{
                                    color: 'rgb(255, 97, 93)',
                                    position: 'relative',
                                    marginBottom: '-25px',
                                    top: '50px',
                                    textAlign: 'right',
                                }}
                            >
                                <span><Translate id={`errors.${error}`} /></span>
                            </div>
                        )}
                        <input
                            {...input}
                            type={fieldType}
                            className="form-control placeholder-no-fix"
                            autoComplete="off"
                            placeholder={field.placeHolder}
                        />
                        {isPasswordField && (
                            <button
                                type="button"
                                onClick={() => this.togglePasswordVisibility(`show${_.startCase(input.name)}`)}
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '37px', background: 'none', border: 'none', zIndex: 999 }}
                            >
                                <i className={this.state[`show${_.startCase(input.name)}`] ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }


    onSubmit(values) {
        this.props.updatePasswordSettings(values).then((resp) => {
            if (resp.success) {
                this.setState({
                    submitted: true
                });
            } else {
                this.setState({
                    error: resp.error
                });
            }
        });
    }

    render() {
        const { handleSubmit } = this.props;

        if (this.state.submitted) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="successLabel" />!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.settings" /></h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="">
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className="row">
                            <div className="col-md-12">
                                <Field name={FIELDS.password.name} component={this.renderField.bind(this)} {...FIELDS.password} key={FIELDS.password.name} />
                            </div>
                            <div className="col-md-12">
                                <Field name={FIELDS.passwordNew.name} component={this.renderField.bind(this)} {...FIELDS.passwordNew} key={FIELDS.passwordNew.name} />
                            </div>
                            <div className="col-md-12">
                                <Field name={FIELDS.passwordNewConfirm.name} component={this.renderField.bind(this)} {...FIELDS.passwordNewConfirm} key={FIELDS.passwordNewConfirm.name} />
                            </div>
                        </div>
                        {this.state.error != '' &&
                            <div className="alert alert-danger">
                                <strong><Translate id="oops" />!</strong> <Translate id={`errors.${this.state.error}`} />
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-2">
                                <div className="col align-self-end">
                                    <button className='profile-button' type="submit"><Translate id="save" /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
    }
};

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName] && field.required) {
            errors[fieldName] = field.errorMsg;
        } else {
            if (fieldName == 'password' || fieldName == 'passwordNew') {
                if (formProps[fieldName].length < 8) {
                    errors[fieldName] = 'passwordIsTooShort';
                } else if (formProps[fieldName].length > 36) {
                    errors[fieldName] = 'passwordIsTooLong';
                } else if (!formProps[fieldName].match(globalRegExes.password)) {
                    errors[fieldName] = field.errorMsg;
                }
            }
        }
    });

    if (formProps.passwordNew != '' &&
        formProps.passwordNewConfirm != '' &&
        formProps.passwordNew != formProps.passwordNewConfirm
    ) {
        errors.passwordNewConfirm = 'passwordConfirmIsInvalid';
    }

    return errors;
}

function mapStateToProps({ user }) {
    return {
        user
    };
}

export default connect(mapStateToProps, { updatePasswordSettings })(
    reduxForm({
        validate,
        form: 'updatePasswordSettingsForm',
        fields: FIELDS,
        enableReinitialize: true
    })(PasswordSettingsForm)
)