import { FETCH_TOTP, UPDATE_TOTP, VERIFY_TOTP, CLEAR_TOTP } from '../actions/types';

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_TOTP:
        case UPDATE_TOTP:
        case VERIFY_TOTP:
            return { ...state, ...action.payload };
        case CLEAR_TOTP:
            return false;
        default:
            return state;
    }
}