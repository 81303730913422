import _ from 'lodash';
import React, { useState } from 'react'

export const CarsFilters = () => {
  return (
    <form className="car-filter-box">
      <div className="row">
        <div className="col-6 col-lg-8">
          <div className="select-filter-box">
            <span className="d-block mb-3">Brand</span>
            <div className="custom-select">
              <select>
                <option value="">Select</option>
                <option value="">Audi</option>
                <option value="">BMW</option>
                <option value="">Porsche</option>
                <option value="">Lamborgini</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 text-right">
          <div className="select-filter-box">
            <span className="d-block mb-3">&nbsp;</span>
            <span>Sort by</span>
            <div className="custom-select">
              <select>
                <option value="">Price</option>
                <option value="">Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
