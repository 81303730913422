import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class AboutUsGR extends Component {
  render() {
    return (
      <div className="intro-box">
        <div className="card-cover card-cover-2">
          <img src="/img/covers/About.PNG" alt="" />
          <h1 style={{ marginTop: '20px' }}><Translate id="weAreBonex" /></h1>
          <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">Για εμάς</h2>
            <p>Το <b>BoneX</b>&nbsp;είναι ένα καινοτομικό ψηφιακό πορτοφόλι, το οποίο υποστηρίζεται από αξιόπιστους επενδυτές και χρησιμοποιείται από εκατοντάδες πελάτες στην Βουλγαρία. Το ίδιο πορτοφόλι προσφέρει έναν εύκολο, γρήγορο και σίγουρο τρόπο αγοράς και πώλησης κρυπτονομισμάτων όπως Bitcoin, Ethereum και Bitcoin Cash στις χαμηλότερες τιμές της αγοράς.</p>
            <p>Η ομάδα επαγγελματιών πίσω από το&nbsp;<b>BoneX</b>&nbsp;δεσμεύεται με την εξασφάλιση ακίνδυνου και σίγουρου εμπορίου ψηφιακών νομισμάτων, συνειδητοποιώντας πλήρως, ότι εσείς εμπιστεύεστε τα χρήματά σας στα χέρια τους.</p>
            <h3 className="mb-more">Οι βασικές προτεραιότητες της <b>BoneX</b> σε σύγκριση με τις λοιπές πλατφόρμες ανταλλαγής κρυπτονομισμάτων είναι:</h3>
            <div className="row">
              <div className="col-12 col-lg-10 col-lg-offset-1">
                <div className="benefits-list no-margin-sides">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-user-alt"></i>
                          <span></span>
                        </div>
                        <h3>Γρήγορη και εύκολη δημιουργία λογαριασμού</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-sync"></i>
                          <span></span>
                        </div>
                        <h3>Σιγουριά των συναλλαγών<br /> (SSL και Cloudfare προστασία, κωδικοποιημένος Source code και βάση δεδομένων)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-coins"></i>
                          <span></span>
                        </div>
                        <h3>Τα πιο ανταγωνιστικά τέλη για τις συναλλαγές</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="far fa-magic"></i>
                          <span></span>
                        </div>
                        <h3>24/7 εξυπηρέτηση <br /> (αυτόματη επεξεργασία κάθε μιας συναλλαγής)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-phone-laptop"></i>
                          <span></span>
                        </div>
                        <h3>Διεπαφή, η οποία είναι κατάλληλη για αρχάριους</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-headset"></i>
                          <span></span>
                        </div>
                        <h3>Άριστη υποστήριξη των χρηστών</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transcard">
              <h2 className="text-center mt-0">Με την BoneX κάθε ένας χρήστης έχει την δυνατότητα να εισέλθει με έναν εξαιρετικά εύκολο, γρήγορο, ευπρόσιτο και άνετο τρόπο, στον κόσμο των κρυπτονομισμάτων.</h2>
              <p>Vα παρακολουθεί σε πραγματικό χρόνο τις κινήσεις και τις τάσεις των τιμών τους, καθώς επίσης να επωφεληθεί από τα προσφερόμενα από την πλατφόρμα εργαλεία δημιουργίας των πολύτιμων για κάθε επενδυτή βασικών και τεχνικών αναλύσεων.</p>
              <p>Δημιουργήστε για μερικά δευτερόλεπτα το δικό σας <b>BoneX</b>&nbsp;πρφίλ και γίνετε μέρος του μέλλοντος του blockchain.</p>
              <p><b>Οι δικοί μας συνεργάτες</b></p>
              <p><b>Οι δικοί μας συνεργάτες από τα μέσα μαζικής ενημέρωσης </b></p>
              <figure>
                <img alt="" src="/img/Partners.PNG" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AboutUsGR;
