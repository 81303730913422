import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MiningOasisGR extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2 mining-cover">
          <img src="/img/banner_mining.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">
            <div className="mining-text">
              <p>
                Η Bonex Mining Oasis παρέχει φιλοξενία εξοπλισμού ακόλουθης γενιάς, που υπερβαίνει την ασφάλεια και την αξιοπιστία. Η BoneX Mining Oasis διακρίνεται με τις καλύτερες προθεσμίες υλοποίησης και ενεργειακή απόδοση, που σας εξασφαλίζει την δυνατότητα να «σκάβετε» τα κρυπτονομίσματά σας με την ησυχία σας.
              </p>
              <p>
                Η Bonex Mining Oasis εξειδικεύτηκε στην εκτέλεση απλών λύσεων για όλες τις δικές σας ανάγκες «ανασκαφής». Οι αποκλειστικές συνεργασίες με τους παρόχους υπηρεσιών υλισμικού, εξασφαλίζουν κορυφαίες στη βιομηχανία προθεσμίες εκτέλεσης και τα πιο καινούρια προϊόντα για την παραγωγή κρυπτονομισμάτων.
              </p>
              <p>
                Σε σύγκριση με τους περισσότερους προμηθευτές υλισμικού, εμείς δεν έχουμε μέγιστο όριο παραγγελιών, που εξασφαλίζουν εύκολη συναλλαγή τόσο για μικρές, όσο και για μεγάλες κινήσεις.
              </p>
              <div className="title-with-icon">
                <h3>Καινοτομική λύση</h3>
                <figure>
                  <img src="/img/icons/mining-icon-1.png" alt="" />
                </figure>
              </div>
              <p>
                Η Bonex Mining Oasis αφιερώθηκε στην παροχή πρώτης κλάσης υπηρεσιών υποδομής με μέγιστη απόδοση των επενδύσεων. Η δική μας δέσμευση για την δημιουργία και την υποστήριξη του blockchain οικοσυστήματος /αλυσίδας συστοιχιών/ είναι ασύγκριτη στην επικράτεια της Βουλγαρίας.
              </p>
              <p>
                Η Bonex Mining Oasis είναι βιώσιμη επιλογή για την εγκατάσταση ιδιόκτητου υλισμικού «ανασκαφής», εάν έχετε μικρό προϋπολογισμό, για να φτιάξετε τον δικό σας ιδιόκτητο εξοπλισμό «ανασκαφής». Πολλές εταιρείες εξάγουν τα συστήματά τους, για να έχουν μεγαλύτερη ασφάλεια και ενισχυμένες συνδέσεις.
              </p>
              <div className="title-with-icon">
                <h3>24/7/365 ασφάλεια της επένδυσης</h3>
                <figure>
                  <img src="/img/icons/mining-icon-2.png" alt="" />
                </figure>
              </div>
              <p>
                Οι δικοί μας εξοπλισμοί που αναπτύσσονται με γρήγορους ρυθμούς διαθέτουν τεχνική υποστήριξη 24/7/365. Χάρη της ασφάλειας σε επίπεδο 3 DC επί τόπου, οι δικοί μας πελάτες είναι ήσυχοι, γνωρίζοντας, ότι οι επενδύσεις τους προστατεύονται.
              </p>
              <p>
                Σύστημα πυρασφάλειας για παρακολούθηση της θερμοκρασίας στις αίθουσες φιλοξενίας εξοπλισμού μέσω οπτικών αισθητήρων καπνού.
                Η ασφάλεια εγγυάται με 24/7 φυσική φύλαξη, έλεγχο της πρόσβασης έως την περιφέρεια και σύστημα αποστολής σήματος και φύλαξης της μονάδας. Μεταλλικός φράκτης, με ύψος 2200mm, με αγκαθωτό σύρμα και καλώδιο αισθητήρων κατά ρήξης. Βιντεοπαρακολούθηση με HD κάμερες και "Motion Detection" εγγραφές.
              </p>
              <div className="title-with-icon">
                <h3>Άνετη πρόσβαση</h3>
                <figure>
                  <img src="/img/icons/mining-icon-3.png" alt="" />
                </figure>
              </div>
              <p>
                Πρόσβαση σε εφαρμογή παρακολούθησης /monitoring/, μέσω της οποίας σε πραγματικό χρόνο μπορείτε να παρακολουθείτε την ηλεκτρική ενέργεια που καταναλώσατε σε κάθε ένα μηχάνημα, καθώς επίσης να το ενεργοποιήσετε και απενεργοποιήσετε εξ αποστάσεως σε περίπτωση ανάγκης.
              </p>
              {/*
              <div className="title-with-icon">
                <h3>Προνομιακοί και ευέλικτοι όροι</h3>
                <figure>
                  <img src="/img/icons/mining-icon-4.png" alt="" />
                </figure>
              </div>
              <p>
                Η BoneX Mining Oasis παρέχει επίσης την δυνατότητα να αγοράσετε μηχανήματα εξόρυξης κρυπτονομισμάτων /crypto mining/ σε μια από τις καλύτερες τιμές στην επικράτεια της Ευρώπης, επειδή συνεργαζόμαστε άμεσα με κατασκευαστή. Σε εμάς μπορείτε να βρείτε πλούσια γκάμα διαθέσιμων μηχανημάτων, καθώς επίσης επαγγελματική διάταξη ανάλογα με τον προϋπολογισμό του πελάτη. Για πρώτη φορά στην Βουλγαρία θα σας παράσχουμε και την δυνατότητα να πάρετε το δικό σας μηχάνημα σε δόσεις. Μόνο με 50% ίδια συμμετοχή εσείς μπορείτε να πάρετε σε δόσεις μηχάνημα από εμάς με τόκο από 1% το μήνα για χρονικό διάστημα όχι μεγαλύτερο από 12 μήνες.
              </p>
              */}
              <div className="border-text">
                <p>
                  Η Bonex προσφέρει ολικές λύσεις για τις δικές σας επενδύσεις σε κρυπτονομίσματα.
                </p>
                <div className="mining-btn-box">
                  <Link to="/contacts"><span>Επικοινωνήστε μαζί μας</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MiningOasisGR;
