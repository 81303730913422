import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import { fetchAccount, fetchRates } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import AssetLightWidget from "../../components/assets/AssetLightWidget";
import UserSidebarNew from "../../components/users/UserSidebarNew";
import AccountSellFormNew from "../../components/accounts/AccountSellFormNew";

class AccountSellPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receivingMethod: "bank",
      txReceivingMethod: false,
    };

    this.onReceivingMethodChange = this.onReceivingMethodChange.bind(this);
    this.onBackToAccounts = this.onBackToAccounts.bind(this)
  }

  onReceivingMethodChange(receivingMethod) {
    this.props.fetchRates();
    this.setState({ receivingMethod });
  }

  onBackToAccounts() {
    window.location.replace("/accounts");
  }

  setReceivingMethod(receivingMethod) {
    this.props.fetchRates();
    this.setState({ receivingMethod });
  }

  head() {
    if (this.props.user && Object.keys(this.props.user).length !== 0 && !this.props.user.verified) {
      return <Redirect to="/verification" />;
    }

    return (
      <Helmet>
        <title>{`Продай ${this.props.account.type ? this.props.account.type + " " : ""
          } - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Продай ${this.props.account.type ? this.props.account.type + " " : ""
            } - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
    this.props.fetchRates();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.transaction)) {
      let txData = JSON.parse(props.transaction.data);
      this.setState({
        txReceivingMethod: txData.receivingMethod,
      });
    }
  }

  isTradeDisabled(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  render() {
    if (!_.isEmpty(this.props.currency) && !_.isEmpty(this.props.account) &&
      this.props.currency.type == "USDT" && this.props.account.type == "USDT") {
      return <Redirect to="/accounts" />;
    }

    return (
      <div className="container">
        <div className="row">
          {this.head()}
          <UserSidebarNew />
          <div className="container">
            <div className="profile-page-new-wrapper" style={{ borderRadius: "20px" }}>
              <div className="profile-page-box">
                <button type="button" onClick={this.onBackToAccounts} style={{ backgroundColor: "transparent", border: "none" }}>
                  <i className="fal fa-arrow-circle-left" style={{ fontSize: "30px", color: "#257199" }}></i>
                </button>
                {!this.isTradeDisabled(this.props.account.type) && (
                  <div className="row">
                    <div className="col-md-12">
                      {_.map(this.props.rates, rate => {
                        if (rate.type == this.props.account.type) {
                          return <AssetLightWidget {...rate} key={rate.id} />;
                        }
                      })}
                      <AccountSellFormNew
                        account={this.props.account}
                        action={this.onReceivingMethodChange}
                        receivingMethod={this.state.receivingMethod}
                        activeLanguage={this.props.activeLanguage}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        className="white-box transparent-box-new no-pad new-style-tabs"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="portlet-title tabbable-line">
                          <span>Select</span>
                          <ul className="nav nav-tabs" id="form-tabs">
                            {(this.state.txReceivingMethod == "bank" || !this.state.txReceivingMethod) &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT") ? (
                              <li
                                className={`${this.state.receivingMethod == "bank"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setReceivingMethod.bind(this, "bank")}
                              >
                                <a href="#bank" data-toggle="tab">
                                  <img src="/img/bank.png" />
                                  <Translate id="bankTransfer" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {/* (this.state.txReceivingMethod == 'epay' || !this.state.txReceivingMethod) ? (
                              <li className={`${this.state.receivingMethod == 'epay' ? 'active' : ''}`} onClick={this.setReceivingMethod.bind(this, 'epay')}>
                                  <a href="#epay" data-toggle="tab">
                                      <img src="/img/epay.png" />
                                      ePay
                                  </a>
                              </li>
                              ) : '' */}
                            {(
                              (this.state.txPaymentMethod == "easypay" || !this.state.txPaymentMethod) &&
                              this.props.currency.type !== "EUR" && this.props.activeLanguage.code != 'ro' && this.props.currency.type !== "RON" &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT")
                            ) ? (
                              <li
                                className={`${this.state.receivingMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setReceivingMethod.bind(
                              //   this,
                              //   "easypay"
                              // )}
                              >
                                <a href="#easypay" data-toggle="tab">
                                  <img src="/img/easy-pay.png" />
                                  EasyPay
                                </a>
                              </li>
                            ) : (
                              ""
                            )}

                            {((this.state.txReceivingMethod == "usdt" || !this.state.txReceivingMethod) && (this.props.account.type !== "USDT")) ? (
                              <li
                                className={`${this.state.paymentMethod == "usdt"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setReceivingMethod.bind(
                              //   this,
                              //   "usdt"
                              // )}
                              >
                                <a href="#usdt" data-toggle="tab">
                                  <img src="/img/assets/usdt.png" style={{ 'width': '40px' }} /><br />
                                  <Translate id="payments.usdt" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                        <div className="portlet-body">
                          <div className="tab-content">
                            {this.state.txReceivingMethod == "bank" ||
                              !this.state.txReceivingMethod ? (
                              <div
                                className={`tab-pane fade in ${this.state.receivingMethod == "bank"
                                  ? "active"
                                  : ""
                                  }`}
                                id="bank"
                              >
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toRecieveBankTransfer" />{" "}
                                        <strong>
                                          <Translate id="bankAccount" />
                                        </strong>{" "}
                                        <Translate id="whenSelling" />{" "}
                                        <strong>{this.props.account.type}</strong>,{" "}
                                        <Translate id="correctBank" />
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div className="col-lg-4">
                                      <div className="ref-number-box">
                                        <figure>
                                          <i className="far fa-university"></i>
                                        </figure>
                                        <h5>
                                          <Translate id="referenceNumber" />:
                                        </h5>
                                        <strong>
                                          {this.props.transaction.reference}
                                        </strong>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.account.title}{" "}
                                      <Translate id="txFees2" /> {this.props.config['tx_tax_' + this.props.account.type]} {" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="txFees3" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="taxFor" />{" "}
                                      <strong>
                                        <Translate id="bank.bank" />
                                      </strong>{" "}
                                      <Translate id="taxFor2" />{" "}
                                      {this.props.account.type}{" "}
                                      <Translate id="taxFor3" />{" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="taxFor4" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* (this.state.txReceivingMethod == 'epay' || !this.state.txReceivingMethod) ? (
                              <div className={`tab-pane fade in ${this.state.receivingMethod == 'epay' ? 'active' : ''}`} id="epay">
                                  <div className="row">
                                      <div className="col-lg-8">
                                          <div className="payment-text">
                                              <p><Translate id="toRecieveMoney" /> <strong>{this.props.account.type}</strong>, <Translate id="correctEpayData" /></p>
                                          </div>
                                      </div>
                                      {(!_.isEmpty(this.props.transaction)) ? (
                                          <div className="col-lg-4">
                                              <div className="ref-number-box">
                                                  <figure>
                                                      <p><img src="/img/epay_big.png" style={{width: '70px' }}/></p>
                                                  </figure>
                                                  <h5><Translate id="referenceNumber" />:</h5>
                                                  <strong>{this.props.transaction.reference}</strong>
                                              </div>
                                          </div>
                                      ) : ''}
                                      <div className="col-lg-12">
                                          <p><Translate id="thePriceIsIndicative" /> <Translate id="txFees1" /> {this.props.account.title} <Translate id="txFees2" /> {this.props.config['tx_tax_'+this.props.account.type]} {this.props.account.type}, <Translate id="txFees3" /></p>
                                          <p><Translate id="moreInfoAfterAction" /></p>
                                          <p><Translate id="taxFor" /> <strong>ePay</strong> <Translate id="taxFor2" /> {this.props.account.type} <Translate id="taxFor3" /> {this.props.account.type}, <Translate id="taxFor4" /></p>
                                      </div>
                                  </div>
                              </div>
                              ) : '' */}
                            {((this.state.txReceivingMethod == "easypay" ||
                              !this.state.txReceivingMethod) &&
                              this.props.currency.type != "EUR" && this.props.activeLanguage.code != 'ro') ? (
                              <div
                                className={`tab-pane fade in ${this.state.receivingMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                                id="easypay"
                              >
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toRecieveDesk" />{" "}
                                        <strong>EasyPay</strong>{" "}
                                        <Translate id="whenSelling" />{" "}
                                        <strong>{this.props.account.type}</strong>,{" "}
                                        <Translate id="correctData" />
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div className="col-lg-4">
                                      <div className="ref-number-box">
                                        <figure>
                                          <p>
                                            <img
                                              src="/img/easypay_big.png"
                                              style={{ width: "60px" }}
                                            />
                                          </p>
                                        </figure>
                                        <h5>
                                          <Translate id="referenceNumber" />:
                                        </h5>
                                        <strong>
                                          {this.props.transaction.reference}
                                        </strong>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.account.title}{" "}
                                      <Translate id="txFees2" /> {this.props.config['tx_tax_' + this.props.account.type]} {" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="txFees3" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="taxFor" />{" "}
                                      <strong>EasyPay</strong>{" "}
                                      <Translate id="taxFor2" />{" "}
                                      {this.props.account.type}{" "}
                                      <Translate id="taxFor3" />{" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="taxFor4" />
                                    </p>
                                    <p>
                                      <Translate id="forActualEPTax" />{" "}
                                      <a
                                        href="https://www.easypay.bg/site"
                                        target="_blank"
                                      >
                                        <Translate id="here" />
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {((this.state.txReceivingMethod == "usdt" || !this.state.txReceivingMethod) && (this.props.account.type !== "USDT")) ? (
                              <div
                                className={`tab-pane fade in ${this.state.receivingMethod == "usdt"
                                  ? "active"
                                  : ""
                                  }`}
                                id="usdt"
                              >
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <strong><Translate id="payments.usdt" /></strong>{" "}
                                        <Translate id="whenSelling" />{" "}
                                        <strong>{this.props.account.type}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <p><strong><Translate id="buySellDisclaimer" /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user, account, rates, transaction, config, currency }) {
  return { user, account, rates, transaction, config, currency };
}

export default {
  component: connect(mapStateToProps, { fetchAccount, fetchRates })(
    requireAuth(withLocalize(AccountSellPage))
  ),
  loadData: ({ dispatch }) => dispatch(fetchAccount()),
};
