import { FETCH_ACCOUNT, UPDATE_ACCOUNT, CLEAR_USER_ACCOUNT } from '../actions/types';

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_ACCOUNT:
            return { ...state, ...action.payload };
        case UPDATE_ACCOUNT:
            return { ...state, ...action.payload };
        case CLEAR_USER_ACCOUNT:
            return null;
        default:
            return state;
    }
}