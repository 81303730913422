import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Translate } from "react-localize-redux";

class ContactsPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Контакти - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Контакти - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            {this.head()}
            <div className="intro-box">
              <div className="row">
                <div className="col-lg-12">
                  <div className="intro-text">
                    <h2>
                      <span>
                        <Translate id="contacts" />
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="infoFigureBox p20 contact-page-info-cont">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-page-info">
                    <div className="contact-page-info-text">
                      <div className="figure">
                        <i className="far fa-clock"></i>
                      </div>
                      <p>
                        <Translate id="contactsTexts.workingHours" />:
                      </p>
                      <p>
                        <Translate id="contactsTexts.workingHoursInfo" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-page-info">
                    <div className="contact-page-info-text">
                      <div className="figure">
                        <i className="far fa-envelope-square"></i>
                      </div>
                      <p>
                        <Translate id="contactsTexts.throughEmail" />:
                      </p>
                      <a href="mailto:support@bonex.org" title="">
                        support@bonex.org
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p>
                  <strong>
                    <Translate id="contactsTexts.forQuestions" />:{" "}
                    <a
                      href="mailto:privacy@bonex.org"
                      title=""
                      style={{ color: "#000" }}
                    >
                      privacy@bonex.org
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default {
  component: ContactsPage
};
