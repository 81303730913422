import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser } from '../../actions';
import { Redirect } from "react-router-dom";
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import AccountsListNew from '../../components/accounts/AccountsListNew';
import getTotalAccountBalancePrice from '../../utilities/getTotalAccountBalancePrice';
import { Translate } from 'react-localize-redux';

const currencyTypeToCurrencySymbol = {
    BGN: 'лв.',
    EUR: '€',
    USDT: '$T'
}

class AccountsListPageNew extends Component {
    head() {
        if (this.props.user && Object.keys(this.props.user).length !== 0 && !this.props.user.verified) {
            return <Redirect to="/verification" />;
        }

        return (
            <Helmet>
                <title>{`Акаунти - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Акаунти - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
        this.props.fetchUser();
    }

    render() {
        const totalBalance = getTotalAccountBalancePrice(this.props.currency.type, this.props.rates, this.props.accounts)
        return (
            <div className="container">
                <div className="">
                    {this.head()}
                    <UserSidebarNew current="accounts" />
                    <div className="">
                        <div className='wallet-overview-wrapper' style={{marginBottom: "32px"}}>
                            <h1 style={{marginBottom:"50px"}} ><Translate id="balanceOverview"/></h1>
                            <h5><Translate id="totalBalance"/> {`(${this.props.currency.type})`}</h5> 
                            <h1>{totalBalance} {currencyTypeToCurrencySymbol[this.props.currency.type]}</h1>
                        </div>
                        <div className="wallet-accounts-wrapper profile-cols-height" style={{marginBottom: "50px"}}>
                            <div className="profile-page-box">
                                <AccountsListNew />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, user, rates, accounts, currency }) {
    return { auth, user, rates, accounts, currency };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(AccountsListPageNew)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
