import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import PagesItem from '../components/pages/PagesItem';

class FireblocksPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Партньорство с Fireblocks - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Партньорство с Fireblocks - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        {this.head()}
                        <div className="intro-box">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="intro-text">
                                        <h2><span><Translate id="partnership-with-fireblocks" /></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="infoFigureBox p20" style={{display: "block"}}>
                            <PagesItem slug="fireblocks" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


export default {
    component: FireblocksPage
};