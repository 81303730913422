import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { signIn, clearAuthErrors } from '../../actions';
import { globalRegExes } from '../../utilities/RegEx';
import ReCAPTCHA from "react-google-recaptcha";

const FIELDS = {
    email: {
        type: 'email',
        label: 'email',
        errorMsg: 'emailIsInvalid',
        placeHolder: 'mail@website.com',
    },
    password: {
        type: 'password',
        label: 'password',
        errorMsg: 'passwordIsInvalidEmpty',
        placeHolder: 'Min. 8 characters'
    }
};

const recaptchaRef = React.createRef();

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totp: false,
            captcha: false
        }
    }

    async handleFormSubmit({ email, password }) {
        await recaptchaRef.current.executeAsync();

        this.props.signIn({ email, password, captcha: this.state.captcha }).then((data) => {
            if (!_.isEmpty(data) && data.totp != 'none') {
                this.setState({
                    totp: true
                });
            }
        });
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;

        const className = `form-group form-field-wrapper ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input
                            className="form-control placeholder-no-fix login-field"
                            {...input}
                            type={type}
                            placeholder={field.placeHolder}
                        />
                        <label
                            className="control-label visible-ie8 visible-ie9"
                            style={{ color: touched && error ? '#ff615d' : '' }}
                        >
                            <Translate id={label} />
                        </label>
                        {touched && error ? (
                            <div
                                className="help-block with-errors"
                                style={{
                                    color: '#ff615d'
                                }}
                            >
                                <span><Translate id={`errors.${error}`} /></span>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }

    renderAlert() {
        if (this.props.auth.authenticated && this.props.user.totp == 'none') {
            return (
                <Redirect to="/settings" />
            );
        } else if (this.state.totp) {
            return (
                <Redirect to="/auth" />
            );
        } else if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    <strong><Translate id="oops" />!</strong> <Translate id={`errors.${this.props.errorMessage}`} />
                </div>
            );
        }
    }

    componentWillUnmount() {
        this.props.clearAuthErrors();
    }

    onReCAPTCHAChange(captchaCode) {
        if (!captchaCode) {
            return;
        }
        this.setState({ captcha: captchaCode });
        recaptchaRef.current.reset();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div id="login-form-container" className="login-form-container">
                <form
                    className="login-form default-forms"
                    id="login-form"
                    onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                >
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LdsqJkpAAAAAGO3UsV4vJ_V5cGhq5amflTOrSvH"
                        onChange={this.onReCAPTCHAChange.bind(this)}
                    />
                    <div className="form-cont-header">
                        <h3 className="form-title">
                            <Translate id="signin" /> | <Link to="/signup"><Translate id="signup" /></Link>
                        </h3>
                    </div>
                    <div className="form-group-box">
                        {_.map(FIELDS, (field, fieldName) => {
                            return (
                                <Field
                                    name={fieldName}
                                    component={this.renderField}
                                    key={fieldName}
                                    {...field}
                                />
                            );
                        })}
                        {this.renderAlert()}
                        <div className="form-check row">
                            <div className='custom-remember-check col-xs-7'>
                                <label className="custom-check" htmlFor='tnc'>
                                    <input name='tns' type='checkbox' id='tnc' />
                                    <i></i>
                                    <Translate id="rememberMyPass" />
                                </label>
                            </div>
                            <div className='forgot-password col-sm-5'>
                                <Link to="/forgot">Forgot password?</Link>
                            </div>
                        </div>
                        <div className="form-actions">
                            <button action="submit" className="submit"><Translate id="signin" /></button>
                        </div>
                    </div>
                </form >
            </div >
        );
    }
}

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName]) {
            errors[fieldName] = field.errorMsg;
        } else {
            if (fieldName == 'email' && !formProps[fieldName].match(globalRegExes.email)) {
                errors[fieldName] = field.errorMsg;
            }

            if (fieldName == 'password' && formProps[fieldName].length < 6) {
                errors[fieldName] = field.errorMsg;
            }
        }
    });

    return errors;
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        user,
        errorMessage: auth && auth.error ? auth.error : ''
    };
}

export default reduxForm({
    validate,
    form: 'signin'
})(connect(mapStateToProps, { signIn, clearAuthErrors })(SignInForm));
