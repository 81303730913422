import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MiningOasisRO extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2 mining-cover">
          <img src="/img/banner_mining.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">
            <div className="mining-text">
              <p>
                Bonex Mining Oasis oferă colocare de ultimă generație care merge dincolo de securitate și fiabilitate. BoneX Mining Oasis se remarcă prin cele mai bune timpi de implementare și eficiență energetică, permițându-vă să vă minați criptomonedele în liniște.
              </p>
              <p>
                Bonex Mining Oasis este specializat în implementarea unor soluții simple pentru toate nevoile dumneavoastră de minare. Parteneriatele exclusive cu producătorii de servicii hardware asigură timpi de livrare de top în industrie și cele mai recente produse de minare de criptomonede.
              </p>
              <p>
                Spre deosebire de majoritatea furnizorilor de hardware, nu avem volume maxime de comenzi, ceea ce permite o tranzacție ușoară atât pentru operațiunile mici, cât și pentru cele mari.
              </p>
              <div className="title-with-icon">
                <h3>Soluție inovativă</h3>
                <figure>
                  <img src="/img/icons/mining-icon-1.png" alt="" />
                </figure>
              </div>
              <p>
                Bonex Mining Oasis este dedicat furnizării de servicii de infrastructură de primă clasă cu un randament maxim al investițiilor. Angajamentul nostru de a crea și susține ecosistemul blockchain este de neegalat în Bulgaria.
              </p>
              <p>
                Bonex Mining Oasis sunt o opțiune viabilă pentru a vă acomoda propriul hardware de minare dacă aveți un buget mic pentru a vă face propria instalație de minare. Multe companii își externalizează sistemele pentru a avea o securitate mai mare și conexiuni susținute.
              </p>
              <div className="title-with-icon">
                <h3>24/7/365 siguranța investiției</h3>
                <figure>
                  <img src="/img/icons/mining-icon-2.png" alt="" />
                </figure>
              </div>
              <p>
                Facilitățile noastre în creștere rapidă dispun de asistență tehnică 24/7/365. Cu un sistem de securitate DC de nivel 3, clienții noștri sunt liniștiți știind că investiția lor este protejată.
              </p>
              <p>
                Sistem de protecție împotriva incendiilor pentru monitorizarea temperaturii în halele de colocare prin intermediul unei rețele de senzori convenționali de fum-optici și termici. Securitatea este asigurată prin pază fizică 24 de ore din 24, 7 zile din 7, control al accesului în perimetru și un sistem de detectare a intruziunilor la fața locului. Gard metalic, înalt de 2200 mm, cu sârmă ghimpată și cablu cu senzor antiefracție. Supraveghere video cu camere HD și înregistrări cu "Motion Detection".
              </p>
              <div className="title-with-icon">
                <h3>Acces facil</h3>
                <figure>
                  <img src="/img/icons/mining-icon-3.png" alt="" />
                </figure>
              </div>
              <p>
                Accesul la o aplicație de monitorizare care vă permite să monitorizați în timp real consumul de energie electrică al oricărui aparat, precum și să îl porniți și opriți de la distanță atunci când este necesar.
              </p>
              {/*
              <div className="title-with-icon">
                <h3>Condiții preferențiale și flexibile</h3>
                <figure>
                  <img src="/img/icons/mining-icon-4.png" alt="" />
                </figure>
              </div>
              <p>
              BoneX Mining Oasis oferă, de asemenea, posibilitatea de a achiziționa mașini de minat cripto la unele dintre cele mai bune prețuri din Europa, deoarece lucrăm direct cu producătorul. La noi puteți găsi o gamă largă de utilaje disponibile, precum și o configurație personalizată în funcție de bugetul clientului. Inclusiv pentru prima dată în Bulgaria, vom oferi posibilitatea de a vă achiziționa mașina în regim de închiriere. Cu o franșiză de numai 50%, puteți achiziționa de la noi un utilaj în regim de închiriere la o rată a dobânzii de 1% pe lună pentru o perioadă de maximum 12 luni.
              </p>
            */}
              <div className="border-text">
                <p>
                  Bonex oferă o soluție completă pentru investițiile dvs. cripto.
                </p>
                <div className="mining-btn-box">
                  <Link to="/contacts"><span>Contactați-ne</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MiningOasisRO;
