import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MiningOasisBG extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2 mining-cover">
          <img src="/img/banner_mining.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">
            <div className="mining-text">
              <p>
                Bonex Mining Oasis предоставя колокация от следващо поколение, която надхвърля сигурността и надеждността. BoneX Mining Oasis се откроява с най-добрите срокове за внедряване и енергийна ефективност, което ви позволява да копаете криптовалутата си със спокойствие.
              </p>
              <p>
                Bonex Mining Oasis е специализиран в изпълнението на прости решения за всички ваши нужди за копаене. Ексклузивните партньорства с производителите на хардуер услуги, осигуряват водещи в индустрията срокове за изпълнение и най-новите продукти за добив на криптовалута.
              </p>
              <p>
                За разлика от повечето доставчици на хардуер, ние нямаме максимални обеми на поръчки, които позволяват лесна транзакция както за малки, така и за големи операции.
              </p>
              <div className="title-with-icon">
                <h3>Иновативно решение</h3>
                <figure>
                  <img src="/img/icons/mining-icon-1.png" alt="" />
                </figure>
              </div>
              <p>
                Bonex Mining Oasis е посветен на предоставянето на първокласни инфраструктурни услуги при максимална възвръщаемост на инвестициите. Нашият ангажимент за създаване и поддържане на блокчейн екосистемата е несравним на територията на България.
              </p>
              <p>
                Bonex Mining Oasis е жизнеспособна опция за настаняване на собствен хардуер за копаене, ако имате малък бюджет, за да направите свое собствено съоръжение за добив. Много компании изнасят своите системи, за да имат по-висока сигурност и подкрепени връзки.
              </p>
              <div className="title-with-icon">
                <h3>24/7/365 сигурност на инвестицията</h3>
                <figure>
                  <img src="/img/icons/mining-icon-2.png" alt="" />
                </figure>
              </div>
              <p>
                Нашите бързо разрастващи се съоръжения разполагат с техническа поддръжка 24/7/365. Благодарение на сигурността на ниво 3 DC на място, нашите клиенти са спокойни, знаейки, че техните инвестиции са защитени.
              </p>
              <p>
                Противопожарна система за мониторинг на температурата в колокационните зали чрез мрежа от конвенционални димно-оптични и термични датчици.
                Сигурността се гарантира от 24/7 физическа охрана, контрол на достъп до периметъра и СОД система на обекта.
                Метална ограда, висока 2200mm, с бодлива тел и сензорен кабел против взлом.
                Видео наблюдение с HD камери и "Motion Detection" записи.
              </p>
              <div className="title-with-icon">
                <h3>Удобен достъп</h3>
                <figure>
                  <img src="/img/icons/mining-icon-3.png" alt="" />
                </figure>
              </div>
              <p>
                Достъп до мониторинг приложение, чрез което можете в реално време да следите изразходената от вас електроенергия на всяка една машина, както и да я включите и изключите от разстояние при нужда.
              </p>
              {/*
              <div className="title-with-icon">
                <h3>Преференциални и гъвкави условия</h3>
                <figure>
                  <img src="/img/icons/mining-icon-4.png" alt="" />
                </figure>
              </div>
              <p>
                BoneX Mining Oasis предоставя също възможността да закупите криптомайнинг машини на едни най-дорбите цени на територията на Европа, тъй като работим директно с производител. При нас можете да намерите голяма гама от налични машини, както и персонална конфигурация според бюджета на клиента. Включително за пръв път в България ще предоставим възможност да вземете вашата машина на изплащане. Със само 50% самоучастие Вие можете да вземете на изплащане машина от нас при лихва от 1% на месец за период от не повече от 12 месеца.
              </p>
              */}
              <div className="border-text">
                <p>
                  Bonex предлага цялостно решение за Вашите крипто инвестиции.
                </p>
                <div className="mining-btn-box">
                  <Link to="/contacts"><span>Свържете се с нас</span></Link>
                </div>
              </div>
              <p>Bitcoin Mining Oasis отваря врати на 1- ви май!</p>

              <p>Какво предлагаме:</p>

              <p>Месечна такса от 45 лв на риг с ДДС като се таксува дъно, а не видеокарта.</p>

              <p>Това включва:</p>

              <p>1. Клетка във вентилирана, прахово-филтрирана зала.</p>
              <p>2. Интернет връзка.</p>
              <p>3. Техническа поддръжка - Във времевата рамка от 10:00 и 22:00ч. има на разположение технически екип, който реагира при евентуален проблем. В случай на повреда може сами да предоставите резервна част или да закупите от нас.</p>
              <p>4. Софтуерна поддръжка - Това означава, че вашите машини се наблюдават от бот 24/7 и от технически екип в работно време. Не е необходимо да ни уведомявате, ако има проблем с машината, ние сами ще видим и ще отстраним повредата. В софтуерната поддръжка влиза също и оптималното настройване на машините, пуул-а.</p>
              <p>5. Закупуване на смарт контакт на цена от 20 лв. - Смарт контактът позволява да проследяването консумация в реално време, като съхранява данни за изминали месеци и предоставя възможност да рестартирате машината си дистанционно.</p>

              <p>Обектът се намира на територията на най-големия метрологичен завод в България, като това дава възможност да предоставим на нашите клиенти с 22% по-ниска цена на промишлен ток от всяка друга в страната.</p>

              <p>Всеки, който има желание да ползва услугите и се запише предварително до 20-ти април, ще получи един месец абсолютно безплатно обслужване и поддръжка, както и 50% отстъпка от обслужване и поддръжка от втория месец.</p>
            </div>
          </div>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">Обяви</h2>
            <div className="mb-more text-center">
              <a className="btn-link" title="" href="#">Създай обява</a>
              <div className="mrgt-3 mrgb-3">
                <form className="form text-left p-5">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="name" type="input" id="name" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Име на обявата</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="hashrate" type="input" id="hashrate" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Hashrate</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="year" type="input" id="year" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Година</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="power" type="power" id="middle_name" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">KW/h</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <div className="label-animated">
                            <textarea name="description" id="description" className="form-control"></textarea>
                            <label className="control-label">Описание</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="price" type="input" id="price" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Цена</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="seller" type="input" id="seller" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Продавч</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="email" type="input" id="email" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Мейл</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="phone" type="input" id="phone" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Телефон</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group custom-style-input">
                          <div className="inputsContainer">
                            <div className="inputsContBox">
                              <div className="row">
                                <div className="col-md-12 has-success">
                                  <div className="form-group">
                                    <div className="custom-upload">
                                      <input type="file" name="selfie" value="" id="selfie" accept=".jpg, .png, .jpeg" className="form-control" />
                                      <span>Снимки</span>
                                      <div className="custom-upload-fake">
                                        <figure>
                                          <i className="sprite upload-icon"></i>
                                        </figure>
                                        <span>Качи</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inputsContainer-bottom">
                              <p>
                                <i className="info-symbol">i</i>Макс. размер : 10MB, Тип: jpeg, jpg, или png
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix">&nbsp;</div>
                  <div className="form-actions text-center">
                    <button type="submit" className="btn btn-primary">Изпрати</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="benefits-list no-margin-sides">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box offer-entry">
                    <div className="figure">
                      <img src="/img/mining/circle-bgr.png" alt="" />
                      <span></span>
                    </div>
                    <h3>
                      <strong>Bitmain X</strong>
                      Sell Harware
                    </h3>
                    <ol className="info-list blue-info">
                      <li>100Tfps</li>
                      <li>2021</li>
                      <li>10KW/h</li>
                    </ol>
                    <br />
                    <p>Selling my harware</p>
                    <br />
                    <ol className="product-gallery">
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                    </ol>
                    <br />
                    <div className="price-box">
                      100
                      <span>BGN</span>
                    </div>
                    <br />
                    <ol className="info-list">
                      <li>Tester</li>
                      <li><a href="mailto:email@email.com" title="">email@email.com</a></li>
                      <li><a href="tel:08888888888" title="">08888888888</a></li>
                    </ol>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box offer-entry">
                    <div className="figure">
                      <img src="/img/mining/circle-bgr.png" alt="" />
                      <span></span>
                    </div>
                    <h3>
                      <strong>Bitmain 2</strong>
                      Selling my hardware 2
                    </h3>
                    <ol className="info-list blue-info">
                      <li>1000TFPS</li>
                      <li>2022</li>
                      <li>100 KW/h</li>
                    </ol>
                    <br />
                    <p>Selling My hardware</p>
                    <br />
                    <ol className="product-gallery">
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                    </ol>
                    <br />
                    <div className="price-box">
                      1000
                      <span>BGN</span>
                    </div>
                    <br />
                    <ol className="info-list">
                      <li>Tester</li>
                      <li><a href="mailto:email@email.com" title="">email@email.com</a></li>
                      <li><a href="tel:0888888888" title="">0888888888</a></li>
                    </ol>
                  </div>
                </div>
                {/*<div className="col-lg-12 text-center">
                  <p><strong>Няма актуални обяви</strong></p>
                  <br />
            </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MiningOasisBG;
