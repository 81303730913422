import { FETCH_ACCOUNTS, GENERATE_ACCOUNTS, CLEAR_USER_ACCOUNTS } from '../actions/types';

export default (state = null, action) => {
    switch(action.type) {
        case FETCH_ACCOUNTS:
            return { ...state, ...action.payload };
        case GENERATE_ACCOUNTS:
            return { ...state, ...action.payload };
        case CLEAR_USER_ACCOUNTS:
            return null;
        default:
            return state;
    }
}