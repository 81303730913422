import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MiningOasisEN extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2 mining-cover">
          <img src="/img/banner_mining.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">
            <div className="mining-text">
              <p>
                Bonex Mining Oasis provides next-generation colocation that exceeds security and reliability. BoneX Mining Oasis features best terms for implementation and energy efficiency that allows you to mine your cryptocurrency in comfort.
              </p>
              <p>
                Bonex Mining Oasis is specialized in providing simple solutions for all your mining needs. Exclusive partnerships with the producers of hardware services е ensure industry-leading terms of execution and newest products for cryptocurrency mining.
              </p>
              <p>
                In contrast to most suppliers of hardware, we have no maximum volumes of orders that allow easy transactions both for small and for large operations.
              </p>
              <div className="title-with-icon">
                <h3>Innovative solutions</h3>
                <figure>
                  <img src="/img/icons/mining-icon-1.png" alt="" />
                </figure>
              </div>
              <p>
                Bonex Mining Oasis is dedicated to providing first-class infrastructure services with maximum return on investments. Our commitment to create and maintain the blockchain ecosystem is incomparable in the territory of Bulgaria.
              </p>
              <p>
                Bonex Mining Oasis are a vital option for accommodation of own mining hardware if you have a small budget, to make your own mining facility. A lot of companies outsource their systems in order to have higher security and supported connections.
              </p>
              <div className="title-with-icon">
                <h3>24/7/365 security of the investment</h3>
                <figure>
                  <img src="/img/icons/mining-icon-2.png" alt="" />
                </figure>
              </div>
              <p>
                Our rapidly growing facilities have 24/7/365 technical support. Thanks to the 3 DC level security on the spot, our customers are calm knowing that their investments are protected.
              </p>
              <p>
                The fire safety system for monitoring of the temperature in the colocation rooms through a network of conventional smoke optical thermal sensors. Security is guaranteed by 24/7 physical guarding, control of the access to the perimeter and signalling and guarding system of the site. Metal fence to the height of 2200 mm, with barbed wire and sensor cable against burglary. Video surveillance with HD cameras and "Motion Detection" records.
              </p>
              <div className="title-with-icon">
                <h3>Comfortable access</h3>
                <figure>
                  <img src="/img/icons/mining-icon-3.png" alt="" />
                </figure>
              </div>
              <p>
                Access to monitoring application through which, you may monitor in real time the electricity spent by you for each machine, and to turn it on or off remotely as necessary.
              </p>
              {/*
              <div className="title-with-icon">
                <h3>Preferential and flexible terms</h3>
                <figure>
                  <img src="/img/icons/mining-icon-4.png" alt="" />
                </figure>
              </div>
              <p>
              BoneX Mining Oasis also gives the option to purchase crypto mining  machines at some of the best prices in the territory of Europe because we work directly with a manufacturer. You may find a large range of available machines and a customized configuration according to the customer’s budget from us. Including, for the first time in Bulgaria, we will give the option for you to take your machine on leasing. Only with your financing of 50%, you may take the machine on leasing at an interest rate of 1% per month for a period of within 12 months.
              </p>
              */}
              <div className="border-text">
                <p>
                  Bonex offers wholesome solution for your crypto currency investments.
                </p>
                <div className="mining-btn-box">
                  <Link to="/contacts"><span>Contact us</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">ADS</h2>
            <div className="mb-more text-center">
              <a className="btn-link" title="" href="#">Create ads</a>
              <div className="mrgt-3 mrgb-3">
                <form className="form text-left p-5">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="name" type="input" id="name" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Ads name</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="hashrate" type="input" id="hashrate" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Hashrate</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="year" type="input" id="year" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Year</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="power" type="power" id="middle_name" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">KW/h</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <div className="label-animated">
                            <textarea name="description" id="description" className="form-control"></textarea>
                            <label className="control-label">Description</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="price" type="input" id="price" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Price</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="seller" type="input" id="seller" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Seller</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="email" type="input" id="email" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Email</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="label-animated">
                            <input name="phone" type="input" id="phone" className="form-control" autocomplete="off" value="" />
                            <label className="control-label">Telephone</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group custom-style-input">
                          <div className="inputsContainer">
                            <div className="inputsContBox">
                              <div className="row">
                                <div className="col-md-12 has-success">
                                  <div className="form-group">
                                    <div className="custom-upload">
                                      <input type="file" name="selfie" value="" id="selfie" accept=".jpg, .png, .jpeg" className="form-control" />
                                      <span>Photo</span>
                                      <div className="custom-upload-fake">
                                        <figure>
                                          <i className="sprite upload-icon"></i>
                                        </figure>
                                        <span>Upload</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inputsContainer-bottom">
                              <p>
                                <i className="info-symbol">i</i>Max size : 10MB, Type: jpeg, jpg, or png
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix">&nbsp;</div>
                  <div className="form-actions text-center">
                    <button type="submit" className="btn btn-primary">Send</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="benefits-list no-margin-sides">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box offer-entry">
                    <div className="figure">
                      <img src="/img/mining/circle-bgr.png" alt="" />
                      <span></span>
                    </div>
                    <h3>
                      <strong>Bitmain X</strong>
                      Sell Harware
                    </h3>
                    <ol className="info-list blue-info">
                      <li>100Tfps</li>
                      <li>2021</li>
                      <li>10KW/h</li>
                    </ol>
                    <br />
                    <p>Selling my harware</p>
                    <br />
                    <ol className="product-gallery">
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                    </ol>
                    <br />
                    <div className="price-box">
                      100
                      <span>BGN</span>
                    </div>
                    <br />
                    <ol className="info-list">
                      <li>Tester</li>
                      <li><a href="mailto:email@email.com" title="">email@email.com</a></li>
                      <li><a href="tel:08888888888" title="">08888888888</a></li>
                    </ol>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box offer-entry">
                    <div className="figure">
                      <img src="/img/mining/circle-bgr.png" alt="" />
                      <span></span>
                    </div>
                    <h3>
                      <strong>Bitmain 2</strong>
                      Selling my hardware 2
                    </h3>
                    <ol className="info-list blue-info">
                      <li>1000TFPS</li>
                      <li>2022</li>
                      <li>100 KW/h</li>
                    </ol>
                    <br />
                    <p>Selling My hardware</p>
                    <br />
                    <ol className="product-gallery">
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                      <li><a href="" title=""><img src="/img/mining/circle-bgr.png" alt="" /></a></li>
                    </ol>
                    <br />
                    <div className="price-box">
                      1000
                      <span>BGN</span>
                    </div>
                    <br />
                    <ol className="info-list">
                      <li>Tester</li>
                      <li><a href="mailto:email@email.com" title="">email@email.com</a></li>
                      <li><a href="tel:0888888888" title="">0888888888</a></li>
                    </ol>
                  </div>
                </div>
                {/*<div className="col-lg-12 text-center">
                  <p><strong>Няма актуални обяви</strong></p>
                  <br />
            </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MiningOasisEN;
