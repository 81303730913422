import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import Faq from '../components/faq/Faq';

class FaqPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: this.props.activeLanguage.code
    };
  }

  head() {
    return (
      <Helmet>
        <title>{`FAQ - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`FAQ - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
      // this.executeJQuery();
    }
  }

  componentDidMount() {
    // this.executeJQuery();
    // $(window).on('load resize', function () {
    //   equalheight('.benefits-list .benefit-entry-box');
    // });
  }

  renderPage() {
    return <Faq lang={this.state.lang} />;
  }

  render() {
    return (
      <div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      {this.head()}
                      {this.renderPage()}
                  </div>
              </div>
          </div>
      </div>
    );
  }
};

function mapStateToProps({ pagesEntry }) {
  return { pagesEntry };
}


export default {
  component: connect(mapStateToProps)(
    withLocalize(FaqPage)
  )
}
