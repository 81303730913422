import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser } from '../../actions';
import { Translate } from "react-localize-redux";
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import OrdersTableNew from '../../components/orders/OrdersTableNew';

class OrdersPageNew extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Поръчки - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Поръчки - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  componentDidMount() {
    if (typeof window != 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="container">
        <div style={{padding: "0px 15px"}}>
          <div className="row">
            {this.head()}
            <UserSidebarNew current="orders" />
            <div className="" >
              <div className="profile-page-new-wrapper">
                <div className='affiliate-box'>
                  <h1><Translate id="orders" /></h1>
                  <p style={{ color: "white" }}><Translate id="orderHistory" /></p>
                  <OrdersTableNew style={{ margin: 'auto' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default {
  component: connect(mapStateToProps, { fetchUser })(
    requireAuth(OrdersPageNew)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
