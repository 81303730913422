import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { unsetError } from "../../actions";

class ErrorBar extends Component {
    removeErrors() {
        setTimeout(() => {
            this.props.unsetError();
        }, 5000);
    }

    render() {
        if(this.props.errors) {
            this.removeErrors();
            return (
                <div id="bottom-msg" style={{ background: '#fc2121', color: '#FFF', padding: '10px 0'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text">
                                    <ul>
                                    {_.map(this.props.errors, (error, i) => {
                                        return <li key={i}>{error}</li>
                                    })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
};


function mapStateToProps({ errors }) {
    return { errors };
}

export default connect(mapStateToProps, { unsetError })(ErrorBar);