import { 
    FETCH_USER_DATA, 
    CLEAR_USER_DATA, 
    UPDATE_GENERAL_SETTINGS, 
    UPDATE_SECURITY_SETTINGS
} from '../actions/types';

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_USER_DATA:
            return { ...state, ...action.payload };
        case UPDATE_GENERAL_SETTINGS:
            return { ...state, ...action.payload };
        case UPDATE_SECURITY_SETTINGS:
            return { ...state, ...action.payload };
        case CLEAR_USER_DATA:
            return false;
        default:
            return state;
    }
}