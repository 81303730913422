import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Helmet from 'react-helmet';
import { fetchUser } from '../../actions';
import { Translate } from "react-localize-redux";
import requireAuth from '../../components/hocs/requireAuth';
import BonusFormNew from '../../components/bonus/BonusFormNew';
import { Link } from 'react-router-dom';
import BonusTableNew from '../../components/bonus/BonusTableNew';

class BonusPageNew extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="">
        <h2>REDEEM CODE</h2>
        <p><Translate id="receivedCode" /></p>
        <div className="">
          <BonusFormNew />
        </div>
        <div>
          <BonusTableNew />
        </div>
      </div>
    );
  }
};

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(mapStateToProps, { fetchUser })(
  requireAuth(BonusPageNew)
)
