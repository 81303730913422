import App from "./App";

import HomePage from "./pages/HomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/TermsConditionsPage";
import FireblocksPage from "./pages/FireblocksPage";
import TermsReferralPage from "./pages/TermsReferralPage";
import CookiesPage from "./pages/CookiesPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactsPage from "./pages/ContactsPage";
import AMLPage from "./pages/AMLPage";
import NotFoundPage from "./pages/NotFoundPage";
import CryptoAccountingPage from "./pages/CryptoAccountingPage";
import MiningOasisPage from "./pages/MiningOasisPage";
import MarginPlatformPage from "./pages/MarginPlatformPage";
import DefiPage from "./pages/DefiPage";
import FaqPage from "./pages/FaqPage";

import BookFlightPage from "./pages/flights/BookFlightPage";

import BuyPropertyPage from "./pages/BuyPropertyPage";
import PropertyBuyPage from "./pages/PropertyBuyPage";
import AddPropertyPage from "./pages/properties/AddPropertyPage";

import NewsListPage from "./pages/news/NewsListPage";
import NewsDetailsPage from "./pages/news/NewsDetailsPage";

import AssetBuyPage from "./pages/assets/AssetBuyPage";
import AssetSellPage from "./pages/assets/AssetSellPage";
import AssetBuyTextPage from "./pages/assets/AssetBuyTextPage";
import AssetSellTextPage from "./pages/assets/AssetSellTextPage";

import SpotTradingPage from "./pages/SpotTradingPage";

import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";
import SignOutPage from "./pages/auth/SignOutPage";
import ForgotPage from "./pages/auth/ForgotPage";
import AuthPage from "./pages/auth/AuthPage";
import ValidatePage from "./pages/auth/ValidatePage";
import ResetPage from "./pages/auth/ResetPage";
import ResetTotpPage from "./pages/auth/ResetTotpPage";

import ProfilePageNew from "./pages/users/ProfilePageNew";
import TransactionsPageNew from "./pages/users/TransactionsPageNew";
// import AffiliatePage from "./pages/users/AffiliatePage";
import SettingsPageNew from "./pages/users/SettingsPageNew";
import VerificationPageNew from "./pages/users/VerificationPageNew";
import PersonalVerificationPage from "./pages/users/PersonalVerificationPage";
import OrdersPageNew from "./pages/users/OrdersPageNew";
import MessagesPage from "./pages/users/MessagesPage";

// import CardInfoPage from "./pages/cards/CardInfoPage";
// import CardPage from "./pages/cards/CardPage";

import AccountsListPageNew from "./pages/accounts/AccountsListPageNew";
import AccountPageNew from "./pages/accounts/AccountPageNew";
import AccountBuyPageNew from "./pages/accounts/AccountBuyPageNew";
import AccountSellPageNew from "./pages/accounts/AccountSellPageNew";
import AccountDepositPageNew from "./pages/accounts/AccountDepositPageNew";
import AccountWithdrawPageNew from "./pages/accounts/AccountWithdrawPageNew";

import AccountSettingsPage from "./pages/accounts/AccountSettingsPage";
// import BonusPage from "./pages/users/BonusPage";

import GiveawayPage from "./pages/giveaway/GiveawayPage";
import GiveawayTermsPage from "./pages/giveaway/GiveawayTermsPage";
import GiveawayCancelPage from "./pages/giveaway/GiveawayCancelPage";

import OpenBankingPage from "./pages/bank/OpenBankingPage";

import CarsPage from "./pages/cars/CarsPage";
import CarsDetailsPage from "./pages/cars/CarsDetailsPage";

import CarsRentalPage from "./pages/cars/CarsRentalPage";
import CarsRentalDetailsPage from "./pages/cars/CarsRentalDetailsPage";

import BankPage from "./pages/bank/BankPage";

export default [
  {
    ...App,
    routes: [
      { ...HomePage, path: "/", exact: true },
      { ...AssetBuyTextPage, path: "/assets/:type/buy", exact: true },
      { ...AssetSellTextPage, path: "/assets/:type/sell", exact: true },
      { ...SignInPage, path: "/login", exact: true },
      { ...AuthPage, path: "/auth", exact: true },
      { ...SignOutPage, path: "/logout", exact: true },
      { ...SignUpPage, path: "/signup", exact: true },
      { ...SignUpPage, path: "/signup/:referral", exact: true },
      { ...ForgotPage, path: "/forgot", exact: true },
      { ...ResetPage, path: "/reset", exact: true },
      { ...ResetPage, path: "/reset/:token", exact: true },
      { ...ValidatePage, path: "/validate", exact: true },
      { ...ValidatePage, path: "/validate/:token", exact: true },
      { ...ResetTotpPage, path: "/reset-totp/", exact: true },
      { ...ResetTotpPage, path: "/reset-totp/:token", exact: true },
      { ...ProfilePageNew, path: "/profile", exact: true },
      { ...TransactionsPageNew, path: "/transactions", exact: true },
      { ...OrdersPageNew, path: "/orders", exact: true },
      { ...AccountsListPageNew, path: "/accounts", exact: true },
      { ...AccountPageNew, path: "/accounts/:type", exact: true },
      { ...AccountBuyPageNew, path: "/accounts/:type/buy", exact: true },
      { ...AccountSellPageNew, path: "/accounts/:type/sell", exact: true },
      { ...AccountDepositPageNew, path: "/accounts/:type/deposit", exact: true },
      { ...AccountWithdrawPageNew, path: "/accounts/:type/withdraw", exact: true },
      { ...AccountSettingsPage, path: "/accounts/:type/settings", exact: true },
      { ...SettingsPageNew, path: "/settings", exact: true },
      { ...VerificationPageNew, path: "/verification", exact: true },
      {
        ...PersonalVerificationPage,
        path: "/verification/personal",
        exact: true,
      },
      { ...SpotTradingPage, path: "/spot-trading", exact: true },
      // { ...CardPage, path: "/card", exact: true },
      { ...MessagesPage, path: "/messages", exact: true },
      // { ...CardInfoPage, path: "/card-info", exact: true },
      { ...GiveawayPage, path: "/giveaway", exact: true },
      { ...GiveawayTermsPage, path: "/giveaway-terms", exact: true },
      { ...GiveawayCancelPage, path: "/giveaway-unsubscribe", exact: true },
      { ...NewsListPage, path: "/news", exact: true },
      { ...NewsDetailsPage, path: "/news/:slug", exact: true },
      { ...PrivacyPolicyPage, path: "/privacy", exact: true },
      { ...TermsConditionsPage, path: "/terms", exact: true },
      { ...FireblocksPage, path: "/fireblocks", exact: true },
      { ...CookiesPage, path: "/cookies", exact: true },
      { ...TermsReferralPage, path: "/terms-referral", exact: true },
      { ...AMLPage, path: "/aml", exact: true },
      { ...AboutUsPage, path: "/about-us", exact: true },
      { ...ContactsPage, path: "/contacts", exact: true },
      { ...CryptoAccountingPage, path: "/crypto-accounting", exact: true },
      // { ...MiningOasisPage, path: "/mining-oasis", exact: true },
      { ...BuyPropertyPage, path: "/buy-property", exact: true },
      { ...AddPropertyPage, path: "/buy-property/add-property", exact: true },
      { ...PropertyBuyPage, path: "/buy-property-page", exact: true },
      // { ...DefiPage, path: "/defi", exact: true },
      { ...FaqPage, path: "/faq", exact: true },

      { ...BankPage, path: "/bank", exact: true },
      { ...OpenBankingPage, path: "/open-banking", exact: true },
      { ...MarginPlatformPage, path: "/margin-platform", exact: true },
      { ...BookFlightPage, path: "/book-flight", exact: true },
      { ...CarsPage, path: "/cars/bmw", exact: true },
      { ...CarsDetailsPage, path: "/cars/bmw/:id", exact: true },
      { ...CarsRentalPage, path: "/cars/rental", exact: true },
      { ...CarsRentalDetailsPage, path: "/cars/rental/:id", exact: true },
      { ...NotFoundPage },
    ],
  },
];
