import { SET_ERROR, UNSET_ERROR } from '../actions/types';

export default (state = null, action) => {
        switch(action.type) {
            case SET_ERROR:
                return { ...state, error: action.payload };
            case UNSET_ERROR:
                return null;
            default:
                return state;
        }
};