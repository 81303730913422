import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import PagesItem from '../components/pages/PagesItem';

class AMLPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`AML Policy - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`AML Policy - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                  {this.head()}
                  <div className="intro-box">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="intro-text">
                          <h2><span><Translate id="amlPolicy" /></span></h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="infoFigureBox p20" style={{display: "block"}}>
                    <PagesItem slug="aml" />
                  </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
};


export default {
  component: AMLPage
};
