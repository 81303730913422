import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class CryptoAccountingBG extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/crypto-cover-1.png" alt="" />
          <h1><Translate id="cryptoAccounting" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more">
              <span>Редът и добрата организация на работния процес са ключови за всеки бизнес, така както и довереният екип от професионалисти.</span>
            </h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>Ползите от крипто счетоводство</h3>
                    <ul className="text-left">
                      <li>Професионален екип се грижи за твоя бизнес</li>
                      <li>Ние познаваме крипто, с нас ще бъде лесно</li>
                      <li>Дейността и доходите ти са подредени и законово правилни</li>
                      <li>Имаш спокойствието да мислиш как да се разрастваш</li>
                      <li>Ние сме дигитални, общуваме там, където и ти, работим с дигитални документи и ще изградим организация, която пасва на твоя бизнес</li>
                      <li>Концентрираш се върху криптото, а документите са за нас</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/card-icon.png" alt="" />
                    </figure>
                    <h3>За цялостното онлайн обслужване</h3>
                    <ul className="text-left">
                      <li>Бърза и лесна комуникация онлайн, която спестява време</li>
                      <li>Силна данъчна и счетоводна експертиза</li>
                      <li>Оптимизация на оперативни процеси и бюджет за счетоводна услуга</li>
                      <li>Пластична услуга според целите и приоритетите на клиента</li>
                      <li>Професионални счетоводители с дългогодишен опит</li>
                      <li>Плащаш само това, което наистина ползваш</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            <p className="text-center">
              Всички услуги и консултации, които се извършват от криптоакаунтиг, са заплатени. Цените започват от 100лв с вкл. ДДС. Сумите могат да бъдат заплатени при първоначалния контакт със счетоводния отдел по банка или в крипто.
            </p>

            <p className="text-center bottom-text">
              <strong>
                За повече информация може да<br /> пишете на {" "}
                <a
                  href="mailto:cryptoaccounting@bonex.org"
                  title=""
                >cryptoaccounting@bonex.org</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CryptoAccountingBG;