import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import DefiBG from '../components/defi/DefiBG';
import DefiEN from '../components/defi/DefiEN';
import DefiRO from '../components/defi/DefiRO';
import DefiGR from '../components/defi/DefiGR';

class Defi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: this.props.activeLanguage.code
    };
  }

  head() {
    return (
      <Helmet>
        <title>{`Defi - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Defi - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
      // this.executeJQuery();
    }
  }

  componentDidMount() {
    // this.executeJQuery();
    // $(window).on('load resize', function () {
    //   equalheight('.benefits-list .benefit-entry-box');
    // });
  }

  renderPage() {
    if (this.state.lang == 'bg') {
      return <DefiBG />;
    } else if (this.state.lang == 'en') {
      return <DefiEN />;
    } else if (this.state.lang == 'ro') {
      return <DefiRO />;
    } else if (this.state.lang == 'gr') {
      return <DefiGR />;
    } else {
      return <DefiBG />;
    }
  }

  render() {
    return (
      <div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      {this.head()}
                      {this.renderPage()}
                  </div>
              </div>
          </div>
      </div>
    );
  }
};

function mapStateToProps({ pagesEntry }) {
  return { pagesEntry };
}

export default {
  component: connect(mapStateToProps)(
    withLocalize(Defi)
  )
}
