import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchUser, fetchMessages } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import MessagesList from '../../components/messages/MessagesList';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
  }

  head() {
    return (
      <Helmet>
        <title>{`INBOX - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`INBOX - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <UserSidebarNew />
            <div className="col-lg-12 order-cols-right">
              <div className="profile-page-cont profile-cols-height" style={{borderRadius: "20px"}}>
                <div className="profile-page-box">
                  <MessagesList />
                </div>
              </div>
            </div>
            {/* <UserSidebar current="messages" /> */}
          </div>
      </div>
    );
  }
};

function mapStateToProps({ auth, user, messages }) {
  return { auth, user, messages };
}

export default {
  component: connect(mapStateToProps, {})(
    requireAuth(ProfilePage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
