import { FETCH_ASSET, CLEAR_ASSET } from '../actions/types';

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_ASSET:
            return { ...state, ...action.payload };
        case CLEAR_ASSET:
            return null;
        default:
            return state;
    }
}