import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import AddPropertyForm from "./AddPropertyForm";

class BuyPropertyEN extends Component {
  render() {
    return (
      <div className="intro-text">
          <div className="card-cover card-cover-2">
              <img src="/img/covers/Bonex_Estate.PNG" alt="" />
              <h1 style={{ marginTop: '-30px' }}><Translate id="buyPropertyCrypto" /></h1>
          </div>
          <div className="article-full">
              <div className="transcard text-center pt-5">
                  <div className="row">
                      <div className="col-sm-12 col-lg-6 mb-3">
                          <a className="btn-link purple-gradient has-arrow has-big-icon w-100 disabled" href="" title="">
                              <i className="far fa-home-alt"></i>
                              View<br /> offers
                          </a>
                      </div>
                      <div className="col-sm-12 col-lg-6 mb-3">
                          <Link className="btn-link purple-gradient has-arrow has-big-icon w-100" to="buy-property/add-property">
                              <i className="fal fa-plus-circle"></i>
                              <Translate id="addProperty" />
                          </Link>
                      </div>
                  </div>
                  <h2 className="text-center animate">BECOME A PARTNER</h2>
                  <p className="font-26">You are selling real estate?</p>
                  <div className="lines-box text-center">
                    <p className="font-26">Become a partner of BoneX and expand your sales opportunities. Reach new clients from the crypto universe, receive instant and secure payments in BGN, Euro, USD or Crypto for your properties and manage your finances with ease in your preferred currency from anywhere in the world. BoneX will take care of everything so you can focus only on the things you are good at!</p>
                  </div>
                  <div className="lines-box">
                      <div className="row mb-5">
                          <div className="col-sm-12 col-lg-6 col-lg-offset-3 mb-5">
                              <a className="btn-link blue-gradient has-arrow has-big-icon" href="mailto:property@bonex.org" title="">
                                  <i className="fal fa-phone-alt"></i>
                                 CONTACT US
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="article-full">
              <div className="transcard text-center">
                  <h2 className="text-center animate">BONEX OFFERS YOU A UNIQUE OPPORTUNITY</h2>
                  <p className="font-26"><i><em>Use your cryptocurrencies to buy any real estate on the market.</em></i></p>
                  <p className="font-26"><i><em>Use your BoneX wallet and buy your new home or investment property in BTC, ETH, USDT, BCH, RVN, UNI, LINK, AAVE. BoneX has the experience and the know-how to transfer the cryptocurrency of your choice into classical currencies and send it directly to the seller of your desired home.</em></i></p>
              </div>
              <div className="property-logo-buy text-center">
                  <div className="logo-entry">
                      <img src="/img/assets/btc.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/eth.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/usdt.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/bch.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/rvn.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/uni.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/link.png" alt="" />
                  </div>
                  <div className="logo-entry">
                      <img src="/img/assets/aave.png" alt="" />
                  </div>
              </div>
              <div className="transcard text-center">
                  <p className="font-26"><i><em>We can arrange a meeting in our office with our legal team, which will explain to you and the seller of the property, what is the roadmap for such a transaction in the most understandable way possible. We are the first crypto exchange to provide this service and we already have experience. Take advantage of it! </em></i></p>
                  <a className="btn-link" href="mailto:property@bonex.org">CONTACT US</a>
                  <br /><br /><br /><br />
              </div>
          </div>
      </div>
    )
  }
}

export default BuyPropertyEN;
