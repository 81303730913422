import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { imageUrl } from '../../utilities/Files';

const NewsListItem = ({ item }) => {
    let lang = 'bg';
    if (item.parent_id == 20) {
        lang = 'en';
    } else if (item.parent_id == 283) {
        lang = 'ro';
    } else if (item.parent_id == 315) {
        lang = 'gr';
    }

    const thumb = () => {
        if (item && item.image) {
            return <img src={imageUrl(item.image.file, 'md')} className="card-img-top img-fluid" alt="" />
        } else {
            return <img src="/img/assets/btc.png" className="card-img-top img-fluid" alt="" />
        }
    }
    return (
        <div className="news-list-entry col-xs-12 col-sm-6 col-md-4">
            <div className="article">
                <figure>
                    <Link to={`/news/${lang}-${item.slug}`}>
                        {thumb()}
                    </Link>
                </figure>
                <div className="article-box">
                    <h3>
                        <Link to={`/news/${lang}-${item.slug}`} title={item.title}>{item.title}</Link>
                    </h3>
                    <div className="text">
                        {item.meta_description}
                    </div>
                    <div className="article-info">
                        <time>{new Date(item.date).toLocaleDateString()}</time>
                        <Link to={`/news/${lang}-${item.slug}`} title={item.title}><Translate id="seeMore" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsListItem;
