import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";


class LanguageToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  onLanguageSetClick(code) {
    this.setState({ open: !this.state.open });
    this.props.setActiveLanguage(code);
  }

  render() {
    const { languages, activeLanguage } = this.props;
    if (typeof window != 'undefined' && activeLanguage) {
      return (
        <div className={this.state.open ? "select-dropdown-box open" : "select-dropdown-box"}>
          <button onClick={() => this.setState({ open: !this.state.open })}>
            <span>
              <img src={`/img/flags/${activeLanguage.name}.png`} alt="" />
            </span>
            {activeLanguage.name}
          </button>
          <ul>
            {languages.map(lang =>
              activeLanguage.code == lang.code ? (
                <li key={lang.code}></li>
              ) : (
                <li key={lang.code}>
                  <button
                    className="btn btn-action btn-rounded"
                    onClick={() => this.onLanguageSetClick(lang.code)}
                  >
                    <span><img src={`/img/flags/${lang.name}.png`} alt="" /> </span>
                    {lang.name}
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      );
    } else {
      return <span></span>;
    }
  }
}



export default withLocalize(LanguageToggle);
