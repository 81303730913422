import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { activateBonus, setError } from '../../actions';

const FIELDS = {
  bonus: {
    type: 'input',
    label: 'bonusCode',
    errorMsg: 'bonusCodeIsInvalid',
    className: 'bonus-input-field'
  }
};

class BonusFormNew extends Component {

  async handleFormSubmit({ bonus }) {
    let call = await this.props.activateBonus({ bonus });
    if (call.success) {
      location.reload();
    } else {
      this.props.setError(call.error);
    }
  }

  renderField(field) {
    const { input, label, type, meta: { touched, error } } = field;

    const className = `form-group ${touched && error ? 'has-error' : 'has-success'}`;

    return (
      <div className={className}>
        <div className="bonus-form-field">
          <div className={`label-animated ${field.className}-wrapper has-content`}>
            <label className="control-label visible-ie8 visible-ie9" style={{
              top: "-5px",
              left: "1px"
            }}>
              <Translate id={label} />
            </label>
            <input
              className={`form-control placeholder-no-fix ${field.className}`}
              {...input}
              type={type}
              autoComplete="off"
            />
            <div className="">
            </div>
            {touched && error ? (
              <div className="help-block with-errors" style={{ marginLeft: '16px' }}>
                <span><Translate id={`errors.${error}`} /></span>
              </div>
            ) : ''}
            <button
              type="submit"
              className="submit profile-button"
            >
              <Translate id="activate" />
            </button>
          </div>
        </div>
      </div >
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        className="auth-form default-forms"
        id="bonus-form"
        onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
      >
        <div className="row">
          <div className='col-md-12'>
            {_.map(FIELDS, (field, fieldName) => {
              return (
                <Field
                  name={fieldName}
                  component={this.renderField}
                  key={fieldName}
                  {...field}
                />

              );
            })}
          </div>
        </div>
      </form>
    );
  }
}

function validate(formProps) {
  const errors = {};

  _.each(FIELDS, (field, fieldName) => {
    if (!formProps[fieldName]) {
      errors[fieldName] = field.errorMsg;
    } else {
      if (fieldName == 'totp' && formProps[fieldName].length < 6) {
        errors[fieldName] = field.errorMsg;
      }
    }
  });

  return errors;
}

function mapStateToProps({ auth, user }) {
  return {
    auth,
    user,
    errorMessage: auth && auth.error ? auth.error : ''
  };
}

export default reduxForm({
  validate,
  form: 'bonusForm'
})(connect(mapStateToProps, { activateBonus, setError })(BonusFormNew));

