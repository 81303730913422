import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fetchUser } from '../../actions';
import { Translate } from "react-localize-redux";
import ResetTotpForm from '../../components/totp/ResetTotpForm';

class ResetTotpPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Подновяване на Двуфакторна Автентикация (2FA) - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Подновяване на Двуфакторна Автентикация (2FA) - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  onSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div>
        {this.head()}
        <div className="intro-box">
          <div className="container">
              <div className="row">
                <div className="intro-text">
                  <h2><span><Translate id="lostTotp" /></span></h2>
                  <div className="article-full">
                    <ResetTotpForm token={this.props.match.params.token} />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default {
  component: connect(mapStateToProps, { fetchUser })(ResetTotpPage),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
