import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import { CarsSellItem } from '../../components/cars/CarsSellItem';
import { fetchCar, fetchRates } from '../../actions';

class CarsDetailsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    head() {
        return (
            <Helmet>
                <title>{`Cars Details - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Cars Details - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        document.body.classList.add('light-theme');
        this.props.fetchCar(this.props.match.params.id);

    }

    componentWillUnmount() {
        document.body.classList.remove('light-theme');
    }

    // componentDidUnmount() {
    //     document.body.classList.remove('light-theme');
    // }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.setState({
                lang: this.props.activeLanguage.code,
            });
            // this.executeJQuery();
        }
    }

    // componentDidMount() {
    //     this.props.fetchCar(this.props.match.params.id);
    // }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {this.head()}
                            <CarsSellItem item={this.props.car} rates={this.props.rates} currency={this.props.currency} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ car, rates, currency }) {
    return { car, rates, currency };
}

export default {
    component: connect(
        mapStateToProps,
        { fetchCar, fetchRates }
    )(withLocalize(CarsDetailsPage)),
    loadData: ({ dispatch }, match) => dispatch(fetchCar(match.params.id))
};

