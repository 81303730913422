import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { authIn, generateTotp, clearAuthErrors, requestResetTotp } from '../../actions';

const FIELDS = {
    totp: {
        type: 'input',
        label: '2FASettings.totpCode',
        errorMsg: 'totpIsInvalid'
    }
};

class AuthForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reset: false
        }
    }

    handleFormSubmit({ totp }) {
        this.props.authIn({ totp });
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;

        const className = `form-group form-field-wrapper ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input
                            className="form-control placeholder-no-fix login-field"
                            {...input}
                            type={type}
                            autoComplete="off"
                        />
                        <label className="control-label visible-ie8 visible-ie9">
                            <Translate id={label} />
                        </label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{
                                color: '#ff615d'
                            }}>
                                <span><Translate id={`errors.${error}`} /></span>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }

    totpActions() {
        if (this.props.user.totp === 'google') {
            return (
                <p><Translate id="insertCode" /> <strong>Google Authenticator</strong></p>
            );
        } else if (this.props.user.totp === 'email') {
            return (
                <p><Translate id="insertCode" /> <strong>E-mail</strong></p>
            );
        } else if (this.props.user.totp === 'sms') {
            return (
                <p><Translate id="insertCode" /> <strong>SMS</strong></p>
            );
        }
    }

    totpActionEmit() {
        if (this.props.user.totp === 'email' || this.props.user.totp === 'sms') {
            this.props.generateTotp(this.props.user.totp);
        }
    }

    renderAlert() {
        if (this.props.auth.authenticated) {
            return <Redirect to="/profile" />
        } else if (_.isEmpty(this.props.user)) {
            return <Redirect to="/login" />
        } else if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    <strong><Translate id="oops" />!</strong> {this.props.errorMessage}
                </div>
            );
        }
    }

    onResetClick() {
        if (!confirm('Are you sure?')) {
            return;
        }

        this.props.requestResetTotp();
        this.setState({ reset: true });
    }

    componentDidMount() {
        this.totpActionEmit();
    }

    componentWillUnmount() {
        this.props.clearAuthErrors();
    }

    render() {
        const { handleSubmit } = this.props;

        if (this.state.reset) {
            return (
                <div id="auth-form-container text-center" style={{ textAlign: 'center' }} className="auth-form-container">
                    <h3><Translate id="resetTotpRequest" /></h3>
                </div>
            );
        } else {
            return (
                <div id="auth-form-container" className="auth-form-container">
                    <form
                        className="auth-form default-forms"
                        id="auth-form"
                        onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                    >
                        <div className="form-cont-header">
                            <h3 className="form-title"><Translate id="2FA" /></h3>
                            {this.totpActions()}
                        </div>
                        <div className="form-group-box">
                            {_.map(FIELDS, (field, fieldName) => {
                                return (
                                    <Field
                                        name={fieldName}
                                        component={this.renderField}
                                        key={fieldName}
                                        {...field}
                                    />
                                );
                            })}
                            {this.renderAlert()}
                            <div className="form-actions">
                                <button type="submit" className="submit"><Translate id="send" /></button>
                            </div>
                            <a href="#" onClick={() => this.onResetClick()}><Translate id="lostTotp" /></a>
                        </div>
                    </form>
                </div>
            );
        }


    }
}

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName]) {
            errors[fieldName] = field.errorMsg;
        } else {
            if (fieldName == 'totp' && formProps[fieldName].length < 6) {
                errors[fieldName] = field.errorMsg;
            }
        }
    });

    return errors;
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        user,
        errorMessage: auth && auth.error ? auth.error : ''
    };
}

export default reduxForm({
    validate,
    form: 'authin'
})(connect(mapStateToProps, { authIn, generateTotp, clearAuthErrors, requestResetTotp })(AuthForm));

