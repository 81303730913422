import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class DefiBG extends Component {
    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/defi/Defibot_BG.png" alt="" />
                    <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <h2>КРИПТО <span className="purple-text">AI БОТ</span>,<br /> на който можеш да се довериш</h2>
                        <p><em className="purple-text">AI DeFiBot е продукт, който предлага това, за което всички ние живеем<br /> и това е истинската свобода, причината да се влюбим в криптото преди<br /> толкова много години.</em></p>
                        <br />
                        <h4><strong className="purple-text">Децентрализирано, чисто и без намеса.<br /><br />Когато се регистрирате в Бонекс, вие ще получите и код за 15% отсъпка от цената на първия ви лиценз!</strong></h4>
                        <br />
                        <p className="blue-text">DeFiBot ви позволява да държите парите си в криптовалута, без да ги<br /> заключвате или да изпращате средствата си на трета страна.</p>
                        <br /><br />
                    </div>
                </div>
                <div className="article-full visible-box article-mrg-btn">
                    <div className="transcard text-center">
                        <img className="defi-logo-content" src="/img/defi/defi-bot-logo.png" alt="" />
                        <h2>Създадохме Invictus DeFiBot, защото сме истински вярващи във финансовата свобода.</h2>
                        <h4><strong>Нашият AI алгоритъм може да търгува вашите BTC, ETH или USDT от вашия собствен акаунт дистанционно, чрез API ключ , в множество крипто борси като Coinbase, Binance,
                            Bybit и Bitmex.<br />
                            Ние разработваме и търгуваме с Invictus от 2017 г. и се чувстваме уверени да споделим този продукт със света.
                        </strong></h4>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>Алгоритъм създаден за вас</h3>
                                    <p>Алгоритъмът ни никога не използва повече от x2 ливъридж и никога не влиза в позиция с повече от 30% от капитала на вашия портфейл.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>поддържаме стабилна печалба</h3>
                                    <p>Статистически, през годините от началото на Invictus до момента поддържаме стабилна печалба от 28% годишно с изключително консервативен дроудаун на загубата, до 4% от целия акаунт.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>Контрол на риска</h3>
                                    <p>Контролът на риска е най-важният аспект на AI и защитата на клиентските средства е от изключително значение за нас.</p>
                                </div>
                            </div>
                        </div>
                        <hr className="purple-line" />
                        <p className="text-center purple-text">
                            <em>
                                ПРАВНА ИНФОРМАЦИЯ
                                <br /><br />
                                Услугите, които предоставя DeFiBot, не се привеждат в изпълнение от BoneX и Ние не носим отговорност за действията на трети лица, както и за потенциално негативни резултати по повод използването на услугите на трети лица. Никаква комуникация или информация, предоставена Ви от Нас, не е предназначена като и няма да се счита за финансов съвет или препоръка. Примерните стратегии, предоставени от BoneX за Ваша справка или употреба, също не могат да се тълкуват като финансов съвет. Настоящото съобщение представлява предупреждение, че всякакви операции с виртуални валути носят финансови рискове и BoneX не предоставя никаква гаранция за печалби или защита срещу загуба.
                                <br /><br />
                                Предоставената информация и резултатите от използването на DeFiBot не са верифицирани от BoneX. Вашето решение да станете потребител на услугите на DeFiBot се формира въз основа на самостоятелна преценка и опит по отношение на търгуване с виртуални активи. При използване на предоставения линк, който препраща към сайта на DeFiBot, Вие излизате от страницата на BoneX и Нашите Общи условия и свързани документи не се прилагат към отношенията Ви с доставчика на услуги, управляващ страницата на DeFiBot. При използването на услугите на трети страни, следва да се осведомите с Общите условия за работа и Политиката за поверителност на доставчиците.
                                <br /><br />
                                <a href="">Съгласен съм {">"}</a>
                            </em>
                        </p>
                        <br />
                    </div>
                </div>
                <div className="article-full visible-box">
                    <div className="transcard text-center">
                        <div className="offset-icons-top">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-1.png" alt="" />
                                        </figure>
                                        <h3>Алгоритъм създаден за вас</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-2.png" alt="" />
                                        </figure>
                                        <h3>поддържаме стабилна печалба</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-3.png" alt="" />
                                        </figure>
                                        <h3>Контрол на риска</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lines-box text-center">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <a className="link-btn" href="https://defibot.com/register/?ref=bonexx" target="_blank">ЗАПОЧНИ СЕГА</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <h2>Защо изкуствен интелект?</h2>
                        <h4><strong>Крипто пазарът предоставя безкрайни възможности за печалба, но истината е че над 80% от потребителите са на загуба, поради своите емоции и липсата на разбиране за пазарните движения и цикли. Точно заради това, изкуственият интелект е най-устойчивото решение в динамичната крипто среда. Неговите решения са базирани само на факти и точни изчисления. Нещо повече – нашият бот има способността да анализира евентуални грешни трейдове и да предотвратява бъдещи загуби при подобни ситуации. </strong></h4>
                        <h2>Сигурност на инвестицията</h2>
                        <h4><strong>DeFiBot използва само нискорискови стратегии за търговия и изпитани технически индикатори, не използва ливъридж и анализира огромен поток от информация в реално време за да вземе максимално добро решение. DeFiBot търгува, използвайки API код, генериран от вас и няма никакъв достъп до вашите средства, не ги държи заключени и вие може по всяко време да разполагате с тях. Това ни прави уникални спрямо конкуренцията и гарантира вашата сигурност. </strong></h4>
                        <br /><br />
                    </div>
                </div>
                <a href="https://defibot.com/register/?ref=bonexx" target="_blank">
                    <img src="/img/defi/Defibot_Offer_BG.png" alt="" />
                </a>
            </div>
        )
    }
}

export default DefiBG;
