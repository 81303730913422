import { FETCH_CURRENCIES, CLEAR_CURRENCIES } from "../actions/types";

let initialState = {
  // BGN: {
  //   type: "BGN",
  //   symbol: "bgn",
  //   rate: 1,
  // },
  // EUR: {
  //   type: "EUR",
  //   symbol: "eur",
  //   rate: 1.9558,
  // },
  // // RON: {
  // //   type: "RON",
  // //   symbol: "ron",
  // //   rate: 0.39558,
  // // },
  // // USD: {
  // //   type: "USD",
  // //   symbol: "usd",
  // //   rate: 1.65558,
  // // },
  // USDT: {
  //   type: "USDT",
  //   symbol: "usdt",
  //   rate: 10,
  // },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES:

      const result = action.payload;

      if (result.hasOwnProperty('USD')) {
        result.USDT = { ...result.USD };

        result.USDT.type = 'USDT';
        result.USDT.title = 'USDT';
        result.USDT.symbol = 'usdt';

        delete result.USD;
      }

      return { ...state, ...result };
    case CLEAR_CURRENCIES:
      return null;
    default:
      return state;
  }
};
