import { SET_CURRENCY } from "../actions/types";

let initialState = {
  type: "BGN",
  symbol: "bgn",
  rate: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
