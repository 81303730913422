import { FETCH_ORDERS, CLEAR_USER_ORDERS } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return { ...state, ...action.payload };
    case CLEAR_USER_ORDERS:
      return null;
    default:
      return state;
  }
}