import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { fetchUser } from '../../actions';
import AuthForm from '../../components/auth/authFrom';

class AuthPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Двустепенна Автентикация - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Двустепенна Автентикация - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="form-cont col-sm-5">
                        <div id="auth" className="auth-tab-container open">
                            <AuthForm />
                        </div>
                    </div>
                    <div className='login-video-wrapper col-sm-7'>
                        <video className='login-video' width="170%" height="100%" autoPlay loop muted>
                            <source src='/videos/login-video.webm' type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, user }) {
    return { auth, user };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(AuthPage),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
