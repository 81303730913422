import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class BuyPropertyRO extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/covers/Bonex_Estate.PNG" alt="" />
          <h1 style={{ marginTop: '20px' }}><Translate id="buyPropertyCrypto" /></h1>
          <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
        </div>
        <div className="article-full"></div>
      </div>
    )
  }
}

export default BuyPropertyRO;