import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { fetchUnseenMessages } from '../../actions';

const pageWrapperStyle = {
  color: '#fff',
};

const rectangleBoxStyle = {
  border: '1px solid #fff',
  borderRadius: '25px',
  padding: '10px',
  margin: '10px 0',
  color: '#fff',
  fontSize: '19.9px',
};

const whiteButtonStyle = {
  backgroundColor: '#fff',
  color: '#000',
  border: 'none',
  borderRadius: '50px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '24px',
  marginTop: '20px',
};

const rowSpacing = {
  marginTop: '80px!important'
};

function FAQSection() {
  const faqs = [
    {
      question: "Кой може да кандидатства за Bonex картата?",
      answer: "Всеки може да кандидатства, но трябва да завършите KYC проверка, за да използвате картата."
    },
    {
      question: "Какви са таксите, свързани с използването на Bonex картата?",
      answer: `
Само еднократна такса от 7 USD/EUR.
POS / E-commerce транзакция: 0.30 евро / $0.40
Такса за зареждане на картата: 1.5%`
    },
    {
      question: "Мога ли да задам лимит за харчене на моята Bonex карта?",
      answer: `
Да можете да зададете лимити
`.trim()
    },
    {
      question: "Как да добавя средства към моята Bonex карта?",
      answer: `Ще получите уникален адрес за депозит на криптовалута. След като депозирате криптовалута на този адрес, средствата ви автоматично ще бъдат конвертирани в EUR или USD по вашата карта.
      `.trim()
    },
    {
      question: "Кои валути мога да използвам с Bonex картата?",
      answer: "Можете да използвате евро (EUR) или щатски долари (USD)."
    }
  ];
  
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div style={{ marginTop: '20px', padding: '10px' }}>
      {faqs.map((item, idx) => (
        <div key={idx} style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
              alignItems: 'center'
            }}
            onClick={() => toggleFAQ(idx)}
          >
            <h5 style={{ color: '#fff', margin: 0, fontSize: '15px', paddingLeft: '10px' }}>
              {item.question}
            </h5>
          </div>

          <hr style={{ border: '1px solid #fff', margin: '10px 0' }} />
          {openIndex === idx && (
            <div style={{ color: '#ccc', paddingLeft: '10px', whiteSpace: 'pre-wrap' }}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

class CardsBonexBG extends Component {
  constructor(props) {
    super(props);
    this.scrollToDonateSection = this.scrollToDonateSection.bind(this);
  }

  scrollToDonateSection() {
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const autoScroll = queryParams.get("scrollToDonate");

    if (autoScroll === "true") {
      this.scrollToDonateSection();
    }
  }

  render() {
    return (
      <div style={pageWrapperStyle}>
        <div className="intro-text" style={{ marginTop: '0px!important' }}>
          <div className="article-full cardBackground">
            <div className="transcard" >
              <div className="row align-items-center text-left my-5 my-section">
                <div className="col-12 col-md-6 order-1 order-md-1">
                  <h2 className='heading-style '>
                    БЕЗГРАНИЧЕН <br />
                    КРИПТО ЖИВОТ <br />
                    НА ВЪРХА НА ПРЪСТИТЕ ВИ
                  </h2>
                  <br />
                  <div className="col-12 col-md-5 order-2 order-md-2 text-center text-md-right viewImgMob">
                    <img
                      src="/img/bonexCards.jpg"
                      alt="Кредитна карта"
                      style={{ borderRadius: '10px', marginTop: '10px', marginBottom: '20px' }}
                    />
                  </div>

                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards03.png"
                      alt="Икона"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Харчете криптовалута с ниски такси</span>
                  </div>
                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards02.png"
                      alt="Икона"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Без лимити за харчене, завинаги</span>
                  </div>
                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards01.png"
                      alt="Икона"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Моментална карта: готова за минута</span>
                  </div>
                  <br />
                  <a
                    className="white-button"
                    href="https://cards.bonex.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Вземете своята карта
                  </a>
                </div>
                <div className="col-12 col-md-1"> </div>
                <div className="col-12 col-md-5 order-2 order-md-2 text-center text-md-right viewImgPc">
                  <img
                    src="/img/bonexCards.jpg"
                    alt="Кредитна карта"
                    style={{ borderRadius: '10px', marginTop: '100px' }}
                  />
                </div>
              </div>

              <div className="row align-items-center text-left my-5 my-section" style={rowSpacing}>
                <div className="col-12 col-md-9">
                  <h2 className='heading-style '>
                    Заредете картата си мигновено<br />с <b>ВСЯКА КРИПТОВАЛУТА</b><br />от <b>ВСЕКИ ПОРТФЕЙЛ</b>
                  </h2>
                  <div style={{ marginTop: '30px', fontSize: '24px', color: '#fff' }}>
                    Поддържани фиатни валути: USD & EUR
                  </div>
                  <div style={{ marginTop: '10px', fontSize: '24px', color: '#fff' }}>
                    Нулеви скрити разходи, 100% прозрачност.
                  </div>
                  <div style={{ marginTop: '10px', fontSize: '24px', color: '#fff' }}>
                    Само еднократна такса от 7 USD/EUR.<br /><br />
                  </div>
                </div>
              </div>

              <div
                className="row align-items-center text-left my-5 my-section"
                style={rowSpacing}
              >
                <div className="col-12 col-md-12">
                  <h2 className='heading-style ' style={{ textAlign: 'center' }}>
                    ВЗЕМЕТЕ СВОЯТА КРИПТО ДЕБИТНА КАРТА.<br /> ЗАРЕДЕТЕ СЕ ОЩЕ ДНЕС.
                  </h2>
                  <br />
                </div>

                <div className="col-12" >
                  <div className="row" >
                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step1">
                        <p style={{ margin: '0 10px 65px 10px', fontSize: '18px' }}>
                          Потвърдете имейла и<br /> телефонния си номер<br /> на следващата страница.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step2">
                        <p style={{ margin: '0 10px 45px 10px', fontSize: '18px' }}>
                          Инсталирайте приложението Mobilum Pay и влезте със същите данни.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step3">
                        <p style={{ margin: '0 10px 20px 10px', fontSize: '18px' }}>
                          Завършете KYC проверката и започнете да използвате своята виртуална карта незабавно.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12" style={{ marginTop: '40px', textAlign: 'center' }}>
                <a
                  className="white-button"
                  href="https://cards.bonex.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Започнете сега
                </a>
              </div>

              <div className="col-12" style={{ marginTop: '40px', textAlign: 'center' }}>
                <h2
                  className="section-title text-left"
                  style={{
                    margin: 0,
                    marginTop: '50px',
                    color: '#fff',
                    fontWeight: 'bold'
                  }}
                >
                  ЧЗВ
                </h2>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <FAQSection />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    accounts: state.accounts,
    account: state.account,
    currency: state.currency,
    unseenCount: state.messages.unseenCount,
  };
}

export default connect(
  mapStateToProps,
  { fetchUnseenMessages }
)(CardsBonexBG);
