import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import { doCarRequest, fetchCar, fetchRates } from '../../actions';
import { CarsItem } from '../../components/cars/CarsItem';

class CarsRentalDetailsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    head() {
        return (
            <Helmet>
                <title>{`Cars - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Cars - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.setState({
                lang: this.props.activeLanguage.code,
            });
            // this.executeJQuery();
        }
    }

    componentDidMount() {
        document.body.classList.remove('light-theme');
        
        this.props.fetchCar(this.props.match.params.id);
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {this.head()}
                            <CarsItem item={this.props.car} rates={this.props.rates} currency={this.props.currency} lang={this.state.lang} doCarRequest={this.props.doCarRequest} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ car, rates, currency }) {
    return { car, rates, currency };
}

export default {
    component: connect(
        mapStateToProps,
        { fetchCar, fetchRates, doCarRequest }
    )(withLocalize(CarsRentalDetailsPage)),
    loadData: ({ dispatch }, match) => dispatch(fetchCar(match.params.id))
};

