import React from "react";

const AssetLightWidget = props => {
  const frame = () => {
    const source = "BYBIT";
    const queryCurr = "USDT";
    const url = `https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22${source}%3A${props.type}${queryCurr}%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22dateRange%22%3A%221y%22%2C%22colorTheme%22%3A%22dark%22%2C%22trendLineColor%22%3A%22%2337a6ef%22%2C%22underLineColor%22%3A%22rgba(55%2C%20166%2C%20239%2C%200.15)%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Atrue%2C%22largeChartUrl%22%3A%22%22%7D`;

    if (props.type == "USDT") {
      return null;
    }

    return (
      <iframe
        src={url}
        style={{
          boxSizing: "border-box",
          border: "none",
          width: "100%",
          height: "100%"
        }}
      />
    );
  };

  return (
    <div className="tradingview-widget-container" style={{
      marginTop: "45px",
      marginBottom: "25px"
    }}
    >
      <div className="tradingview-widget-container__widget text-center">
        {frame()}
      </div>
    </div>
  );
};

export default AssetLightWidget;
