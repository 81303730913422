import _ from 'lodash';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { CarsListItem } from './CarsListItem';
import { Link } from "react-router-dom";
import { CarsFilters } from './CarsFilters';

export const CarsList = ({ items, type, rates, currency }) => {
  return (
    <React.Fragment>
      {/* <CarsFilters /> */}
      <div className="cars-list mt-5">
        <div className="row">
          {_.map(items, item => (
            <CarsListItem key={item.id} type={type} item={item} rates={rates} currency={currency} />
          ))}
        </div>
      </div>
    </React.Fragment>

  )
}
