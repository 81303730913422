import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import { fetchLocalizedPagesEntry } from '../../actions';
import { fetchNews, clearNews } from '../../actions';
import NewsListItem from './NewsListItem';

class NewsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    UNSAFE_componentWillMount() {
        this.props.fetchNews();
    }

    UNSAFE_componentWillMount() {
        this.load();
    }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.props.clearNews();
            this.setState({
                lang: this.props.activeLanguage.code
            });
            this.load(this.props.activeLanguage.code);
        }
    }

    load(lang) {
        this.props.fetchNews((lang) ? lang : this.state.lang, this.props.slug);
    }

    head() {
        if (this.state.lang == 'en') {
            return (
                <Helmet>
                    <title>{`News - BoneX | Crypto Exchange`}</title>
                    <meta property="og:title" content={`News - BoneX | Crypto Exchange`} />
                </Helmet>
            );
        } else {
            return (
                <Helmet>
                    <title>{`Новини - BoneX | Crypto Exchange`}</title>
                    <meta property="og:title" content={`Новини - BoneX | Crypto Exchange`} />
                </Helmet>
            );
        }

    }

    render() {
        if (!_.isEmpty(this.props.news)) {
            return (
                <div>
                    {this.head()}
                    <div className="news-list">
                        {_.map(this.props.news, (entry) => {
                            return <NewsListItem item={entry} key={entry.id} />
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-md-12">
                        {this.head()}
                    </div>
                </div>
            );
        }
    }
};


function mapStateToProps({ news }) {
    return { news };
}

export default connect(mapStateToProps, { fetchNews, clearNews })(
    withLocalize(NewsList)
);