import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class OpenBankingGR extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/open_banking/Online_Banking.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">

            <div className="mining-text pt-0">
              <h2 className="text-center">
                NEW FROM BONEX<br />
                <span>MANAGE ALL FINANCES<br /> FROM ONE PLACE - 24/7</span>
              </h2>
              <p>
                BoneX thinks about your comfort and freedom, and so that you can focus on the things you are really good at and grow even faster, we bring you a single solution for 24/7 management of all your funds - quick, easy and fully online.
              </p>
              <p>
                <strong>Monitor and manage your cash flows from anywhere in the world at any time!</strong>
              </p>
              <p>
                You can now easily and quickly track and manage your funds from all your bank accounts and crypto wallets from your personal BoneX account with lightning speed and highest level of security guaranteed by our licensed technology partner Aris Solutions.
              </p>
              <div className="mb-more"></div>
              <br />
              <br />
              <div className="benefits-list no-margin-sides">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/fast.png" alt="" />
                        <span></span>
                      </div>
                      <h3>CONVENIENCE AND SPEED</h3>
                      <p>Make instant transactions from all yours bank accounts and BoneX crypto wallets</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/control.png" alt="" />
                        <span></span>
                      </div>
                      <h3>FULL CONTROL</h3>
                      <p>Track your balance and transaction history in detail</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/security.png" alt="" />
                        <span></span>
                      </div>
                      <h3>HIGH LEVEL OF SECURITY</h3>
                      <p>Keep your accounts and data private with encrypted system with multi-level protection</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-center"><strong>Optimize your financial management and grow even faster with BoneX!</strong></p>
              <div className="text-center">
                <br />
                <a className="btn-link" to={'bank'}>GET STARTED NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OpenBankingGR;
