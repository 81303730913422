import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class AboutUsEN extends Component {
  render() {
    return (
      <div className="intro-box">
        <div className="card-cover card-cover-2">
          <img src="/img/covers/About.PNG" alt="" />
          <h1 style={{ marginTop: '20px' }}><Translate id="weAreBonex" /></h1>
          <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">About us</h2>
            <p><strong>BoneX</strong>&nbsp; is an innovative digital wallet that offers a one stop shop for all your crypto needs.</p>
            <p>The team of professionals behind&nbsp;<strong>BoneX</strong>&nbsp;is committed to ensuring safe and secure trading of digital currencies, fully realizing that you trust your money in their hands.</p>
            <h3 className="mb-more">The main advantages of BoneX  are:</h3>
            <div className="row">
              <div className="col-12 col-lg-10 col-lg-offset-1">
                <div className="benefits-list no-margin-sides">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-user-alt"></i>
                          <span></span>
                        </div>
                        <h3>Quick and easy creation of an account</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-sync"></i>
                          <span></span>
                        </div>
                        <h3>Secure Transactions<br /> (SSL and Cloudfare Protection, Encrypted Source Code, and Database)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-coins"></i>
                          <span></span>
                        </div>
                        <h3>Most competitive transaction fees</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="far fa-magic"></i>
                          <span></span>
                        </div>
                        <h3>24/7 service<br /> (automatic processing of each transaction)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-phone-laptop"></i>
                          <span></span>
                        </div>
                        <h3>Interface suitable for beginners</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-headset"></i>
                          <span></span>
                        </div>
                        <h3>Excellent user support</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <i className="fas fa-check"></i>
                        <span></span>
                      </div>
                      <h3>Multiple crypto related services</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <i className="fas fa-check"></i>
                        <span></span>
                      </div>
                      <h3>Crypto accounting department</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <i className="fas fa-check"></i>
                        <span></span>
                      </div>
                      <h3>Purchase and storage of crypto mining equipment</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <i className="fas fa-check"></i>
                        <span></span>
                      </div>
                      <h3>Purchase of real estate</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transcard">
              <h2 className="text-center mt-0">With <strong>BoneX</strong></h2>
              <p>Each user has the ability to access the world of crypto currency in a very easy, fast, accessible and convenient way, track real-time traffic and price trends, and take advantage of the platform's tools to build valuable for each investor, fundamental and technical analyses.</p>
              <p>Track real-time traffic and price trends, and take advantage of the platform's tools to build valuable for each investor, fundamental and technical analyzes.</p>
              <p>Create your <strong>BoneX</strong> profile for a few seconds and become part of the future of blockchain.</p>
              <br /><br />
              <hr />
              <figure>
                <img alt="" src="/img/Partners.PNG" />
              </figure>
              {/*
              <h3 className="text-center blue-title">Our Partners</h3>
              <div className="logo-list">
                <figure>
                  <a href="https://bmw-bovacar.bg" target="_blank"><img alt="" src="/content/files/2019/09/04/594826c0fbc297ddb0d78709ad72784c.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.bmw.bg/bg/index.html" target="_blank"><img alt="" src="/content/files/2019/09/04/0b35df7b9ba12e857fe81da1f3ea7d53.png" /></a>
                </figure>
                <figure>
                  <a href="https://creditcommerce.bg" target="_blank"><img alt="" src="/content/files/2019/09/04/7366b237f7ce7dcbfde8afe5fae19220.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.easypay.bg/site" target="_blank"><img alt="" src="/content/files/2019/09/04/71b44e1c35be9a8d852cc8aaf14e4333.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.mistertango.com/en/" target="_blank"><img alt="" src="/content/files/2019/09/04/c1dfc05a8275a52c797a41502bae1364.png" /></a>
                </figure>
              </div>
              <p>&nbsp;</p>
              <hr />
              <h3 className="text-center blue-title">Our Media Partners</h3>
              <div className="logo-list">
                <figure>
                  <img alt="" src="/content/files/2021/05/12/1566e8dab6ecb9bac415fd09efe1fca8.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/795071c899d1b1eba56f92da0cc7ff98.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/a02fbd29826548b5ad35f2f243e271be.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/81e6fdb9b2412c1827cb811c49917ef5.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/81e6fdb9b2412c1827cb811c49917ef5.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/c2d1a079ffdbbae21b148a3b1f5443ff.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/0dd80b6759489bbc6a3a9f695bb1efe0.png" />
                </figure>
              </div>
              <br /><br />
              */}
            </div>
          </div>
        </div>
      </div >
    );
  }
}


export default AboutUsEN;
