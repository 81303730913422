import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Translate } from "react-localize-redux";
import AssetsTable from "../components/assets/AssetsTable";

class SpotTradingPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Spot Trading | Crypto Exchange`}</title>
        <meta property="og:title" content={`Spot Trading | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.head()}
        <div className="container">
          <AssetsTable />
        </div>
      </div >
    );
  }
}

export default {
  component: SpotTradingPage
};
