import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class MarginPlatformGR extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/margin/BANNER.png" alt="" />
          <h1 style={{ marginTop: '-30px' }}><Translate id="marginPlatfrom" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more"></h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>Trading</h3>
                    <ul className="text-left">
                      <li>Simplified trading processes for ease of execution, position management, and portfolio monitoring.</li>
                      <li>Dedicated support from our expert team to assist with your inquiries and issues.</li>
                      <li>A range of bonuses and incentives to enhance your trading experience.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/speed-icon.png" alt="" />
                    </figure>
                    <h3>Efficiency</h3>
                    <ul className="text-left">
                      <li>Bonex Margin Platform employs state-of-the-art security measures for your peace of mind.</li>
                      <li>Committed to offering a tax-efficient and cost-effective trading experience.</li>
                      <li>Empowering Affiliate and Broker Programs for our users.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/currency-icon.png" alt="" />
                    </figure>
                    <h3>Possibilities</h3>
                    <ul className="text-left">
                      <li>Experience cutting-edge trading technology with Bonex Margin Platform, suitable for both beginners and experienced traders, offering powerful tools to optimize your strategies.</li>
                      <li>Access a diverse selection of token pairs on our platform, allowing you to explore different trading opportunities and diversify your portfolio.</li>
                      <li>Elevate your trading capabilities with Margin Trading, unlocking new possibilities for traders looking to maximize their strategies.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MarginPlatformGR;
