import React from 'react';

const Loading = () => {
    return (
        <div className="col-md-12">
            <img src="/img/loading.gif" style={{ width: '50px', display:'block', margin: '10px auto'}}/>
        </div>
    );
};

export default Loading;