import React, { Component } from "react";
import { fetchRates } from "../../actions";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { formatMoney } from "../../utilities/Format";
import { amountRegExes } from "../../utilities/RegEx";

class AssetRates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0,
      price: 0,
      rate: 0,
      formErrors: {
        amount: "",
        price: ""
      },
      amountValid: false,
      priceValid: false,
      touched: false
    };
  }

  getPrice(type, amount, action) {
    let price = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate / this.props.currency.rate;
      if (this.props.sign === "+") {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) +
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      } else {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) -
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      }
      if (aRate.type == type && action == 'amount') {
        price = parseFloat(amount * tempRate).toFixed(2);
      } else if (aRate.type == this.props.account.type && action == 'price') {
        price = parseFloat(amount / tempRate).toFixed(this.props.account.decimals);
      }
    });
    return price;
  }

  getRate() {
    let rate = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate / this.props.currency.rate;
      if (this.props.sign === "+") {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) +
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      } else {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) -
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      }

      if (aRate.type == this.props.account.type) {
        rate = parseFloat(1 * tempRate);
      }
    });
    return rate;
  }

  handleAmountChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    const price = this.getPrice(this.props.account.type, value, 'amount');
    const rate = this.getRate();
    this.setState(
      {
        amount: value,
        price,
        rate,
        touched: true
      },
      () => {
        this.validateField(name, value).then(() => {
          this.validateField("price", price).then(() => {
            this.callParentAction(value, price, name);
          });
        });
      }
    );
  }

  validateAmountChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    if (!value.match(amountRegExes.crypto)) {
      this.setState(
        {
          amount: 0,
          price: 0
        },
        () => {
          this.validateField(name, "0").then(() => {
            this.callParentAction(0, 0, name);
          });
        }
      );
    }
  }

  handlePriceChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    const amount = this.getPrice(this.props.currency.type, value, 'price');
    const rate = this.getRate();
    this.setState(
      {
        amount,
        price: value,
        rate,
        touched: true
      },
      () => {
        this.validateField(name, value).then(() => {
          this.validateField("amount", amount).then(() => {
            this.callParentAction(amount, value, name);
          });
        });
      }
    );
  }

  validatePriceChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    if (!value.match(amountRegExes.fiat)) {
      this.setState(
        {
          amount: 0,
          price: 0
        },
        () => {
          this.validateField(name, "0").then(() => {
            this.callParentAction(0, 0, name);
          });
        }
      );
    }
  }

  callParentAction(rate, value, name) {
    if (this.props.action) {
      this.props.action(rate, value, name, this.state.rate);
    }
  }

  validateField(fieldName, value) {
    return new Promise(resolve => {
      let formErrors = this.state.formErrors;
      let amountValid = this.state.amountValid;
      let priceValid = this.state.priceValid;

      switch (fieldName) {
        case "amount":
          amountValid =
            parseFloat(value).toPrecision(9) != "0.00000000" &&
            value.match(amountRegExes.crypto);
          formErrors.amount = amountValid ? "" : "IsInvalid";
          if (
            typeof this.props.minAmount != "undefined" &&
            parseFloat(value) < this.props.minAmount
          ) {
            formErrors.amount = "IsBelowMin";
          }
          if (
            typeof this.props.maxAmount != "undefined" &&
            parseFloat(value) > this.props.maxAmount
          ) {
            formErrors.amount = "IsAboveMax";
          }
          amountValid = !formErrors.amount.length;
          break;
        case "price":
          formErrors.price =
            value && value.match(amountRegExes.fiat) ? "" : "IsInvalid";
          if (value * 1 < this.props.minTotal / this.props.currency.rate) {
            formErrors.price = "IsBelowMin";
          } else if (
            value * 1 >
            (this.props.currency.type == 'USDT' ? this.props.maxTotal / this.props.currency.rate : this.props.maxTotal)
          ) {
            formErrors.price = "IsAboveMax";
          }
          priceValid = !formErrors.price.length;
          break;
        default:
          break;
      }

      this.setState({
        formErrors,
        amountValid,
        priceValid
      });

      return resolve(true);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.type != this.props.currency.type || prevProps.diff != this.props.diff) {
      const price = this.getPrice(this.props.account.type, this.state.amount, 'amount');
      const rate = this.getRate();

      this.setState({ price, rate }, () => {
        this.callParentAction(this.state.amount, this.state.price, "amount");
      });
    } else if (prevProps.maxTotal != this.props.maxTotal) {
      this.validateField("price", this.state.price);
    }
  }

  render() {
    return (
      <div className="bold-forms">
        <header className="element-section-title">
          <h3>
            {this.props.account.title} @{" "}
            {formatMoney(this.getPrice(this.props.account.type, 1, 'amount'))}{" "}
            <Translate id={this.props.currency.symbol} />
          </h3>
        </header>
        <div className="col-md-12">
          <div className="form-info-box">
            <strong>
              <Translate id="insertAmountPrice" />
            </strong>
          </div>
          <div className="row">
            <div
              className={`col-md-6 ${this.state.touched
                ? !this.state.amountValid
                  ? "has-error"
                  : "has-success"
                : ""
                }`}
            >
              <div className="form-group">
                <div className="input-icon">
                  <div className="label-animated has-content">
                    <input
                      type="text"
                      className="form-control placeholder-no-fix"
                      autoComplete="off"
                      value={this.state.amount}
                      onChange={this.handleAmountChange.bind(this)}
                      onBlur={this.validateAmountChange.bind(this)}
                      name="amount"
                    />
                    <label className="control-label visible-ie8 visible-ie9">
                      {this.props.account.type}
                    </label>
                  </div>
                </div>
                {this.state.formErrors.amount != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.amount${this.state.formErrors.amount}`}
                      />
                      {typeof this.props.minAmount != "undefined"
                        ? this.state.formErrors.amount == "IsBelowMin"
                          ? " (" +
                          this.props.minAmount +
                          " " +
                          this.props.account.type +
                          ")"
                          : ""
                        : ""}
                      {typeof this.props.maxAmount != "undefined"
                        ? this.state.formErrors.amount == "IsAboveMax"
                          ? " (" +
                          this.props.maxAmount +
                          " " +
                          this.props.account.type +
                          ")"
                          : ""
                        : ""}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`col-md-6 ${this.state.touched
                ? !this.state.priceValid
                  ? "has-error"
                  : "has-success"
                : ""
                }`}
            >
              <div className="form-group">
                <div className="input-icon">
                  <div className="label-animated has-content">
                    <input
                      type="text"
                      className="form-control placeholder-no-fix touched"
                      autoComplete="off"
                      value={this.state.price}
                      onChange={this.handlePriceChange.bind(this)}
                      onBlur={this.validatePriceChange.bind(this)}
                      name="price"
                    />
                    <label className="control-label visible-ie8 visible-ie9">
                      <Translate id={this.props.currency.symbol} />
                    </label>
                  </div>
                </div>
                {this.state.formErrors.price != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.price${this.state.formErrors.price}`}
                      />
                      {this.state.formErrors.price == "IsBelowMin" ? (
                        <React.Fragment>
                          (
                          {formatMoney(
                            this.props.minTotal / this.props.currency.rate
                          )}{" "}
                          <Translate id={this.props.currency.symbol} />)
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {this.state.formErrors.price == "IsAboveMax" ? (
                        <React.Fragment>
                          (
                          {formatMoney(
                            (this.props.currency.type == 'USDT' ? this.props.maxTotal / this.props.currency.rate : this.props.maxTotal)
                          )}{" "}
                          <Translate id={this.props.currency.symbol} />)
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ rates, currency }) {
  return { rates, currency };
}

export default connect(
  mapStateToProps,
  { fetchRates }
)(AssetRates);
