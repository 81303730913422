import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { fetchUser } from '../../actions';
import SignUpForm from '../../components/auth/signUpForm';


class SignUpPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Регистрация - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Регистрация - BoneX | Crypto Exchange`} />
                <meta
                    property="og:description"
                    content="Покупка и продажба на Биткойн, Етер, Биткойн кеш. Софтуер, гарантиращ най-добрата цена на пазара в България."
                />
                <meta property="og:image" content="https://bonex.org/img/bonex_og.jpg" />
                <meta property="og:url" content="https://bonex.org/signup" />
                <meta property="og:type" content="website" />
            </Helmet>
        );
    }

    onSubmit(event) {
        event.preventDefault();
    }

    referral() {
        let referral = '';
        if (typeof this.props.match.params.referral != 'undefined' && typeof localStorage != 'undefined') {
            localStorage.setItem('referral', this.props.match.params.referral);
        }

        if (typeof localStorage != 'undefined' && localStorage.getItem('referral') && localStorage.getItem('referral') != '') {
            referral = localStorage.getItem('referral');
        }

        return referral;
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="form-cont signup-form-cont col-sm-5">
                        <div id="signup" className="signup-tab-container open row">
                            <SignUpForm referral={this.referral()} />
                        </div>
                    </div>
                    <div className='login-video-wrapper col-sm-7'>
                        <video className='login-video' width="170%" height="100%" autoPlay loop muted>
                            <source src='/videos/login-video.webm' type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(SignUpPage),
    // loadData: ({ dispatch }) => dispatch(fetchUser())
}
