import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { fetchAccount } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
// import UserSidebar from "../../components/users/UserSidebar";
// import Account from "../../components/accounts/Account";
import AccountSettingsForm from "../../components/accounts/AccountSettingsForm";

class AccountSettingsPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Настройки на ${this.props.account.type ? this.props.account.type + " " : ""
          } Акаунт - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Настройки на ${this.props.account.type ? this.props.account.type + " " : ""
            } Акаунт - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12 col-lg-10 col-md-9 order-cols-right">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  {/* <Account
                    account={this.props.account}
                    currency={this.props.currency}
                  /> */}
                  <AccountSettingsForm />
                </div>
              </div>
            </div>
            {/* <UserSidebar current="accounts" /> */}
          </div>
      </div>
    );
  }
}

function mapStateToProps({ account, currency }) {
  return { account, currency };
}

export default {
  component: connect(
    mapStateToProps,
    { fetchAccount }
  )(requireAuth(AccountSettingsPage)),
  loadData: ({ dispatch }) => dispatch(fetchAccount())
};
