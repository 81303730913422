import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Translate } from "react-localize-redux";
import { fetchAccount, updateAccount } from '../../actions';

const FIELDS = {
    bank_holder: {
        type: 'input',
        label: 'Име'
    },
    bank_currency: {
        type: 'input',
        label: 'Валута'
    },
    bank_iban: {
        type: 'input',
        label: 'IBAN'
    },
    bank_name: {
        type: 'input',
        label: 'Име на Банката'
    },
    bank_bic: {
        type: 'input',
        label: 'BIC / SWIFT'
    },
    bank_address: {
        type: 'input',
        label: 'Адрес на Банката'
    },
    id: {
        type: 'hidden'
    },
};

class AccountSettingsForm extends Component {

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;
        const className = `col-md-6 form-group ${touched && error ? 'has-danger' : ''}`;
    
        if(type != 'hidden') {
            return (
                <div className={className}>
                    <div className="input-icon">
                        <div className="label-animated has-content">
                            <input 
                                {...input}
                                type={type}
                                className="form-control placeholder-no-fix" 
                                autoComplete="off"
                            />
                            <label className="control-label visible-ie8 visible-ie9">{label}</label>
                            <div className="text-help">{touched ? error : ''}</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <input 
                    {...input}
                    type={type}
                    className="form-control placeholder-no-fix" 
                    autoComplete="off"
                />
            )
        }
    }

    onSubmit(values) {
        this.props.updateAccount(values, () => {
            this.props.history.push('/');
        });
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="infoFigureBox">
                <div className="col-md-12">
                    <h3><Translate id="settings" /></h3>
                    <br />
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className="row">
                            {_.map(FIELDS, (field, fieldName) => {
                                return <Field name={fieldName} component={this.renderField} { ...field } key={fieldName} />
                            })}
                        </div>
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <div className="col align-self-end">
                                    <button type="submit"><Translate id="save" /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ account }) {
    return {
        initialValues: account 
    };
}

export default connect(mapStateToProps, { fetchAccount, updateAccount })(
    reduxForm({
        form: 'updateAccountForm',
        fields: FIELDS,
        enableReinitialize : true
    })(AccountSettingsForm)
)