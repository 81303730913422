import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import user from "./userReducer";
import auth from "./authReducer";
import rates from "./ratesReducer";
import config from "./configReducer";
import totp from "./totpReducer";
import accounts from "./accountsReducer";
import account from "./accountReducer";
import asset from "./assetReducer";
import transactions from "./transactionsReducer";
import transaction from "./transactionReducer";
import orders from "./ordersReducer";
import news from "./newsReducer";
import newsEntry from "./newsEntryReducer";
import pagesEntry from "./pagesEntryReducer";
import errors from "./errorsReducer";
import currency from "./currencyReducer";
import currencies from "./currenciesReducer";
import affiliate from "./affiliateReducer";
import bonuses from "./bonusesReducer";
import messages from "./messagesReducer";
import properties from "./propertiesReducer";
import airports from "./airportsReducer";
import cars from "./carsReducer";
import car from "./carReducer";
import networkAddress from"./networkAddressReducer"

export default combineReducers({
  form,
  user,
  auth,
  rates,
  config,
  totp,
  accounts,
  account,
  asset,
  transactions,
  transaction,
  affiliate,
  bonuses,
  orders,
  news,
  newsEntry,
  pagesEntry,
  errors,
  currency,
  currencies,
  messages,
  properties,
  airports,
  cars,
  car,
  networkAddress
});
