import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
// import AssetsHomeTableItem from "./AssetsHomeTableItem";
import AssetsHomeTableItemNew from "./AssetsHomeTableItemNew";

class AssetsHomeTable extends Component {
  render() {
    return (
      <div className="col-sm-12">
        {/* <p className="info-sys-box mb-5"><Translate id="bonexFeePromo" /></p> */}
        <table className="table-box-style table-box-new white-border-table">
          {_.map(this.props.rates || [], (rate) => {
            if (rate.featured && !rate.trade_disabled) {
              return (
                <AssetsHomeTableItemNew {...rate} key={rate.id} user={this.props.user} config={this.props.config} />
              )
            }
          })}
        </table>
        {/* <table className="table-box-style">
          <tbody>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/btc.png" alt="BTC" />
                  </figure>
                  <strong> Bitcoin</strong>BTC
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/BTC/sell"
                  style={{ width: 160 }}
                >
                  Prodai
                  <br />
                  109,045.9 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/BTC/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  113,269.9 лв.
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/eth.png" alt="ETH" />
                  </figure>
                  <strong> Ethereum</strong>ETH
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/ETH/sell"
                  style={{ width: 160 }}
                >
                  Продай
                  <br />
                  4,533.26 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/ETH/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  4,708.86 лв.
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/uni.png" alt="UNI" />
                  </figure>
                  <strong> Uniswap</strong>UNI
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/UNI/sell"
                  style={{ width: 160 }}
                >
                  Продай
                  <br />
                  11.915 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/UNI/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  12.376 лв.
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/mkr.png" alt="MKR" />
                  </figure>
                  <strong> Maker</strong>MKR
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/MKR/sell"
                  style={{ width: 160 }}
                >
                  Продай
                  <br />
                  2,749.3 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/MKR/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  2,855.8 лв.
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/sol.png" alt="SOL" />
                  </figure>
                  <strong> Solana</strong>SOL
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/SOL/sell"
                  style={{ width: 160 }}
                >
                  Продай
                  <br />
                  251.44 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/SOL/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  261.18 лв.
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div className="crypto-icon">
                  <figure>
                    <img src="/img/assets/pol.png" alt="POL" />
                  </figure>
                  <strong> Polygon</strong>POL
                </div>
              </td>
              <td>
                <a
                  className="btn-link-box sell-btn"
                  href="/accounts/POL/sell"
                  style={{ width: 160 }}
                >
                  Продай
                  <br />
                  0.6985 лв.
                </a>
                <a
                  className="btn-link-box buy-btn"
                  href="/accounts/POL/buy"
                  style={{ width: 160 }}
                >
                  Купи
                  <br />
                  0.7255 лв.
                </a>
              </td>
            </tr>
          </tbody>
        </table> */}

        <div className="table-box-more">
          <Link to="/spot-trading" className="btn-link-box orange-btn">
            <Translate id="viewAllCrypto" />
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user, rates, config }) {
  return { user, rates, config };
}

export default connect(mapStateToProps)(AssetsHomeTable);
