import _ from 'lodash';
import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import ReactDOM from 'react-dom';
import { Translate } from "react-localize-redux";

class RequestBookModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: this.props.activeLanguage.code,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      adds: '',
      send: false
    };
  }

  componentDidMount() {
    const element = ReactDOM.findDOMNode(this);
    $(element).modal('show');
    $(element).on('hidden.bs.modal', this.props.handleHideModal);
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
    }
  }

  handleFNameOnChange(e) {
    this.setState({ first_name: e.target.value });
  }
  handleLNameOnChange(e) {
    this.setState({ last_name: e.target.value });
  }
  handleEmailOnChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneOnChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleAddsOnChange(e) {
    this.setState({ adds: e.target.value });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.props.onRequest(this.state);
    this.setState({ sent: true });
  }

  render() {
    return (
      <div className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title"><Translate id="request" /></h4>
            </div>
            <div className="modal-body">
              {(!this.state.sent) ? (
                <form onSubmit={this.handleOnSubmit.bind(this)}>
                  <div className='row mt-2'>
                    <div className='col-md-12'>
                      <div className="mb-2">
                        <input type="text" className="form-control" placeholder="First Name" required onChange={this.handleFNameOnChange.bind(this)} value={this.state.first_name} />
                      </div>
                      <div className="mb-2">
                        <input type="text" className="form-control" placeholder="Last Name" required onChange={this.handleLNameOnChange.bind(this)} value={this.state.last_name} />
                      </div>
                      <div className="mb-2">
                        <input type="email" className="form-control" placeholder="Email" required onChange={this.handleEmailOnChange.bind(this)} value={this.state.email} />
                      </div>
                      <div className="mb-2">
                        <input type="phone" className="form-control" placeholder="Phone" required onChange={this.handlePhoneOnChange.bind(this)} value={this.state.phone} />
                      </div>
                      <div className="mb-2">
                        <textarea className="form-control" rows="5" cols="10" placeholder="Any special request or requirements" onChange={this.handleAddsOnChange.bind(this)} value={this.state.adds} />
                      </div>
                      <div className="mb-2 text-center">
                        {this.state.lang == 'bg' ? (
                          <p>Моля, преди да подадете заявката, запознайте се с <a href="https://www.jetfinder.com/terms-of-service/" target='_blank'>Oбщите условия</a> и <a href="https://www.jetfinder.com/privacy-policy/" target='_blank'>Политика за поверителност</a> на JetFinder</p>
                        ) : (
                          <p>Please read the JetFinder <a href="https://www.jetfinder.com/terms-of-service/" target='_blank'>Terms and Conditions</a> & <a href="https://www.jetfinder.com/privacy-policy/" target='_blank'>Privacy Policy</a>  before submitting your request</p>
                        )}
                      </div>
                      <div className="mb-2 text-center">

                        <button type="submit" className='btn btn-rounded'><Translate id="requestBook" /></button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close"><Translate id="cancel" /></button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="text-center" style={{ padding: '30px' }}>
                  <h3><Translate id="requestSuccessFlight1" /><br /><Translate id="requestSuccessFlight2" /></h3>
                  <br /><br />
                  <p><Translate id="requestSuccessFlight3" /></p>
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(RequestBookModal);