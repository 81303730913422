import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import { fetchUser, fetchConfig, fetchRates, fetchCountry, fetchCurrencies, setCurrency } from './actions';
import ChatIcon from './components/ChatIcon';

class App extends Component {
    UNSAFE_componentWillMount() {
        if (this.props.auth.authenticated == true) {
            this.props.fetchUser();
        }
        this.props.fetchConfig();
        this.props.fetchRates();

        if (typeof localStorage != 'undefined' && !localStorage.getItem("languageCode")) {
            this.props.fetchCountry().then(country => {
                if (country && country.length) {
                    const countryData = country[0];
                    this.props.setActiveLanguage('en');
                    localStorage.setItem("languageCode", 'en');
                    this.props.fetchCurrencies().then(currencies => {
                        if (currencies) {
                            if (currencies['EUR']) {
                                this.props.setCurrency(currencies['EUR']);
                            } else if (currencies['USDC']) {
                                this.props.setCurrency(currencies['USDC']);
                            }
                        }
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
        const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;
        const hasLanguageChanged = prevLangCode !== curLangCode;
        if (hasLanguageChanged && localStorage) {
            localStorage.setItem("languageCode", curLangCode);
        }
    }

    renderMessage() {
        return (
            <div id="bottom-msg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text">
                                <a className="btn-link" href="javascript:;" title="">Agree</a>
                                <a className="btn-link reverse" href="javascript:;" title="">Close</a>
                                <a className="close-msg" href="javascript:;" title=""><i className="fal fa-times"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { location, auth, user, route } = this.props;
        const isMarginRoute = location.pathname.startsWith('/margin') || location.pathname.startsWith('/margin-mobile') || (typeof window !== 'undefined' && window.location.hostname === 'home.bonex.net');
       
        return (
            isMarginRoute ? (
                <div id="body-root" className='not-logged'>
                    <main>
                        {renderRoutes(route.routes)}
                    </main>
                </div>
            ) : (
                <div id="body-root" className={`${auth.authenticated === true && user.nickname ? 'is-logged' : 'not-logged'}`}>
                    <Header />
                    <main>
                        {renderRoutes(route.routes)}
                    </main>
                    <Footer />
                    {/* <div id="fb-root" className={`${auth.authenticated === true && user.nickname ? 'msngr-box offset' : 'msngr-box'}`}></div> */}
                    <ChatIcon />
                </div>
            )
        );
    }
}

function mapStateToProps({ auth, user, config, rates }) {
    return { auth, user, config, rates };
}

export default {
    component: connect(mapStateToProps, { fetchUser, fetchConfig, fetchRates, fetchCountry, fetchCurrencies, setCurrency })(
        withLocalize(App)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
};
