import { FETCH_CARS, CLEAR_CARS } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARS:
      return [...action.payload];
    case CLEAR_CARS:
      return [];
    default:
      return state;
  }
}