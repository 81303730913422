import _ from 'lodash';
import ExcelJS from 'exceljs'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchOrders, cancelOrder } from '../../actions';

class OrdersTableNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableType: "ALL",
      filteredOrders: [],
      currentPage: 1,
      rowsPerPage: 10,
    };
    this.exportToExcel = this.exportToExcel.bind(this)
    this.filterData = this.filterData.bind(this)
  }

  async handlePageChange(newPage) {
    this.setState({ currentPage: newPage });
  };

  async filterData(allOrders, ordersType) {
    if (ordersType !== "ALL") {
      this.setState({
        ...this.state,
        tableType: ordersType,
        filteredOrders: allOrders.filter((order) => order.action === ordersType),
        currentPage: 1
      })
    } else {
      this.setState({
        ...this.state,
        tableType: ordersType,
        filteredOrders: allOrders,
        currentPage: 1
      })
    }
    // return filteredOrders = allOrders.filter((order) => order.action === ordersType)
    // filteredOrders = ["qko"]
  }
  async exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Table Data');

    const tableData = Object.values(this.props.orders);

    // Add data rows
    tableData.forEach(row => {
      const rowValues = Object.values(row);
      worksheet.addRow(rowValues);
    });

    const insertedRowInherited = worksheet.insertRow(1, [], 'i');

    worksheet.spliceColumns(18, 9);

    // "id": 1462,
    //     "user_id": 21529,
    //     "account_id": 578,
    //     "reference": "19f1a6c3",
    //     "platform": null,
    //     "action": "SELL",
    //     "state": "REQUEST",
    //     "status": "PENDING",
    //     "amount": "0.00000000",
    //     "price": "0.00",
    //     "fees": "0.00",
    //     "total": "0.00",
    //     "rate": "0.00",
    //     "commission_rate": "2.20",
    //     "commission": "0.00",
    //     "currency": "BGN",
    //     "internal": false,
    //     "data": null,
    //     "phyre_data": null,
    //     "phyre_payment": null,
    //     "phyre_order": null,
    //     "trastra_data": null,
    //     "local_data": null,
    //     "idn": null,
    //     "encoded": null,
    //     "checksum": null,
    //     "is_promo": false,
    //     "promocode": "",
    //     "_created": "2024-05-14T08:46:11.000Z",
    //     "_updated": null,
    //     "account_type": "BTC",
    //     "account_decimals": 8

    // Add columns
    worksheet.columns = [
      { header: this.props.activeLanguage.code === `en` ? `Order ID` : `ID на поръчка`, key: 1, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `User ID` : `ID на потребител`, key: 2, width: 16, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Account ID` : `ID на сметка`, key: 3, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Reference №` : `Референтен номер`, key: 4, width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Platform` : `Платформа`, key: 5, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Action` : `Действие`, key: 6, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `State` : `Състояние`, key: 7, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Status` : `Статус`, key: 8, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Amount` : `Количество`, key: 9, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Price` : `Цена`, key: 10, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Fees` : `Такси`, key: 11, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Total` : `Общо`, key: 12, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Rate` : `Курс`, key: 13, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Commission Rate` : `Комисионен курс`, key: 13, width: 23, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Commision` : `Комисиона`, key: 13, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Currency` : `Валута`, key: 14, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Internal` : `Вътрешен`, key: 15, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Promo` : `Промо`, key: 16, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Promocode` : `Промокод`, key: 17, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Created` : `Създадена`, key: 18, width: 23, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Updated` : `Актуализирана`, key: 19, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: this.props.activeLanguage.code === `en` ? `Account type` : `Сметка`, key: 20, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
    ];

    worksheet.getRow(1).font = { size: 13, bold: true };

    worksheet.spliceColumns(23, 1);


    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'table_data.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  UNSAFE_componentWillMount() {
    this.props.fetchOrders();
  }

  componentDidUpdate() {
    // const dataEvent = document.createEvent('HTMLEvents');
    // dataEvent.initEvent('datatable-data', true, true);
    // document.dispatchEvent(dataEvent);
  }

  async onClickCancel(orderId) {
    let msg = 'Сигурни ли сте, че желаете да откажете поръчката?';
    if (this.props.activeLanguage.code == 'en') {
      msg = 'Are you sure that you wish to cancel the order?';
    } else if (this.props.activeLanguage.code == 'ro') {
      msg = 'Sunteți siguri că doriți să renunțați la comanda dvs.?';
    } else if (this.props.activeLanguage.code == 'gr') {
      msg = 'Είστε σίγουροι, ότι θέλετε να ακυρώσετε την παραγγελία?';
    }
    if (!confirm(msg)) {
      return;
    }

    let action = await this.props.cancelOrder(orderId);
    if (action.success) {
      this.props.fetchOrders();
    }
  }

  rows(currentData) {
    return _.map(currentData, (order, number) => {
      return (
        <tr key={order.id}>
          <td align="center">
            {order.reference}
          </td>
          <td align="center" name="action">{order.action}</td>
          <td>
            <strong className="amount" name="amount">{parseFloat(order.amount).toFixed(order.account_decimals)} {order.account_type}</strong>
          </td>
          <td>
            <strong className="total" name="total" style={{ whiteSpace: 'nowrap' }}>{order.total} {order.currency}</strong>
          </td>
          <td>
            <strong className="action">
              {(order.status == 'PENDING') ? (
                <button className="profile-button red-button btn-action" style={{ backgroundColor: '#fc2121' }} onClick={() => this.onClickCancel(order.id)}><Translate id="cancel" /></button>
              ) : ""}
            </strong>
          </td>
          <td>
            <strong className="rate" name="rate" style={{ whiteSpace: 'nowrap' }}>{order.rate} {order.currency}</strong>
          </td>
          <td>
            <strong className="status" name="status">{order.status}</strong>
          </td>
          <td>
            <time>
              {new Intl.DateTimeFormat(this.props.translate("lang"), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(order._created))}
            </time>
          </td>
        </tr>
      );
    });

  }

  render() {
    const { currentPage, rowsPerPage } = this.state;
    const data = Object.values(this.props.orders);

    const displayedData = this.state.filteredOrders.length > 0 ? this.state.filteredOrders : data
    // Determine which rows to display
    const startIdx = (currentPage - 1) * rowsPerPage;
    const currentData = displayedData.slice(startIdx, startIdx + rowsPerPage);

    // Number of total pages
    const totalPages = Math.ceil(this.state.filteredOrders.length > 0 ? this.state.filteredOrders.length / rowsPerPage : data.length / rowsPerPage);


    return (
      <div className="">
        <div className="">
          <div className="affiliate-box">
            <div className="scroll-box" >
              {/* <button className="profile-button" onClick={this.exportToExcel}>Export to Excel</button> */}
              <button className={`filter-by-type-order-button ${this.state.tableType === "ALL" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "ALL")}>All</button> {" / "}
              <button className={`filter-by-type-order-button ${this.state.tableType === "BUY" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "BUY")}>Buy</button> {" / "}
              <button className={`filter-by-type-order-button ${this.state.tableType === "SELL" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "SELL")}>Sell</button>
              <button className="profile-button export-button" onClick={this.exportToExcel}>Export to Excel</button>

              <table className="custom-table custom-table-new orders-table" style={{ width: '100%', overflow: 'scroll' }}>
                <thead className='cutom-table-head'>
                  <tr className='custom-table-row'>
                    <th align="center">
                      <strong><Translate id="referenceNumber" /></strong>
                    </th>
                    <th align="center">
                      <strong><Translate id="action" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="amount" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="price" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="action" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="rate" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="status" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="date" /></strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.rows(currentData)}
                </tbody>
              </table>
            </div>
            <div>
              <button className="pagination-button-new" type="button" onClick={() => this.handlePageChange(1)}>
                <i className="fal fa-chevron-double-left"></i>
              </button>
              <button className="pagination-button-new" onClick={() => currentPage > 1 ? this.handlePageChange(currentPage - 1) : ""}>
                <i className="fal fa-chevron-left"></i>
              </button>
              {Array.from({ length: totalPages }, (_, idx) => (
                <button
                  key={idx}
                  onClick={() => this.handlePageChange(idx + 1)}
                  disabled={currentPage === idx + 1}
                  className={`pagination-button-new ${currentPage === idx + 1 ? `active-button` : ``}`}
                >
                  {idx + 1}
                </button>
              )).slice(currentPage < 4 ? 0 : currentPage - 3, currentPage < 4 ? 5 : currentPage + 2)
                // .slice(startingPage, currentPage < 5 ? 5 : startingPage + 5)

              }
              <button className="pagination-button-new" onClick={() => currentPage === totalPages ? "" : this.handlePageChange(currentPage + 1)}>
                <i className="fal fa-chevron-right"></i>
              </button>
              {
                <button className="pagination-button-new" onClick={() => this.handlePageChange(totalPages)}>
                  <i className="fal fa-chevron-double-right"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div >
    );
  }
};


function mapStateToProps({ orders, activeLanguage }) {
  return { orders };
}

export default connect(mapStateToProps, { fetchOrders, cancelOrder })(withLocalize(OrdersTableNew));