import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageMargin extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // this.handleMessage = this.handleMessage.bind(this);
  }

  componentDidMount() {
    // Add event listener for window messages
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    // Remove event listener when component is unmounted
    window.removeEventListener("message", this.handleMessage);
  }

  // #region Functions
  handleMessage(event) {
    // Check if the message is from the trusted origin
    // if (event.origin !== "https://bonex.net") {
    //   console.warn("Untrusted message origin:", event.origin);
    //   return;
    // }

    const targetDiv = document.getElementById("margin-price-table");

    if (targetDiv) {
      if (typeof event.data === "object" && event.data.type === "place-table-msg") {
        targetDiv.innerHTML = JSON.stringify(event.data.content, null, 2);
      }
    }
  }
  // #endregion

  componentDidMount() {

    document.body.classList.add("homepage-margin");
    const targetH1 = document.querySelector(".col-sm-12 h1");
    if (targetH1) {
      const observerH1 = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              window.parent.postMessage({ type: "scroll", direction: "up" }, "*");
            } else {
              window.parent.postMessage({ type: "scroll", direction: "down" }, "*");
            }
          });
        },
        {
          root: null,
          threshold: 0.5,
        }
      );

      observerH1.observe(targetH1);
      this.observerH1 = observerH1;
    }

    const targetViewMarket = document.querySelector(".viewmarket");
    if (targetViewMarket) {
      const observerViewMarket = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              window.parent.postMessage({ type: "viewmarket" }, "*");
            }
          });
        },
        {
          root: null,
          threshold: 0.5,
        }
      );

      observerViewMarket.observe(targetViewMarket);
      this.observerViewMarket = observerViewMarket;
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("homepage-margin");
    if (this.observerH1) {
      this.observerH1.disconnect();
    }

    if (this.observerViewMarket) {
      this.observerViewMarket.disconnect();
    }
  }

  render() {

    const glowingStyle = {
      color: '#fff', // Link text color
      textDecoration: 'none', // Remove underline
      textShadow: '0 0 5px #00f, 0 0 10px #00f, 0 0 20px #00f, 0 0 40px #00f', // Glowing effect
      transition: 'text-shadow 0.3s ease-in-out', // Smooth transition for hover
    };

    const hoverStyle = {
      textShadow: '0 0 10px #00f, 0 0 20px #00f, 0 0 40px #00f, 0 0 80px #00f', // Stronger glow on hover
    };

    return (
      <div>
        <style>
          {`
          body.homepage-margin {
            padding: 0 !important;
          }
        `}
        </style>
        <div className="row" style={{ display: "none" }}>
          <div className="col">
            <div className="container index-partner-section">
              <a href="https://www.bonex.net/en_US/cms/fireblocks">
                <img src="/img/banner_fireblocks.png" alt="Fireblocks" />
              </a>
            </div>
          </div>
        </div>
        <div id="page-intro" style={{ padding: "50px 0 50px" }}>
          <div className="container">
            <div className="col-sm-12">
              <h1>
                <span className="white-text">The Industry’s</span>
                <span className="white-text">#1 for</span>
                <span className="white-text">Trading</span>
                <span className="blue-text">
                  <a style={glowingStyle}
                    onMouseEnter={(e) => {
                      e.target.style.textShadow = hoverStyle.textShadow;
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.textShadow = glowingStyle.textShadow;
                    }} target="_blank" href="https://www.bonex.net/en_US/cms/rewards-hub">Rewards</a></span>
              </h1>
            </div>
            <div className="col-sm-12">
              <div id="margin-price-table"></div>
            </div>
          </div>

        </div>
        <div className="assets-box">
          <div className="container">
            <div className="col-sm-12">
              <div className="assets-list-box" style={{ padding: "0 0 50px" }}>
                <div className="row">
                  <div className="col-12 col-md-4 mb-5 text-center text-lg-left">
                    <h2 className="section-title-box">
                      <span>Your Assets</span>
                      <span>are Our</span>
                      <span>Priority</span>
                    </h2>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry" style={{ border: "1px solid #fff" }}>
                      <figure>
                        <img src="/img/menu/icon-4.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/margin/buy-property">
                            Buy Real Estate
                          </Link>
                        </h3>
                        <p>
                          Use your cryptocurrencies to buy any real estate on the market.
                        </p>
                        <Link to="/margin/buy-property">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry" style={{ border: "1px solid #fff" }}>
                      <figure>
                        <img src="/img/menu/icon-2.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/margin/crypto-accounting">
                            Crypto Accounting
                          </Link>
                        </h3>
                        <p>
                          The procedure and effective organization are crucial for any business, as is a team of trusted professionals.
                        </p>
                        <Link to="/margin/crypto-accounting">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry" style={{ border: "1px solid #fff" }}>
                      <figure>
                        <img src="/img/menu/icon-5.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/margin/book-flight">
                            Rent A Jet
                          </Link>
                        </h3>
                        <p>
                          Where private jet travel meets seamless cryptocurrency payments.
                        </p>
                        <Link to="/margin/book-flight">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry" style={{ border: "1px solid #fff" }}>
                      <figure>
                        <img src="/img/menu/icon-6.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/margin/cars/bmw">
                            Buy BMW
                          </Link>
                        </h3>
                        <p>
                          Use your cryptocurrencies to buy your new BMW car.
                        </p>
                        <Link to="/margin/cars/bmw">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry" style={{ border: "1px solid #fff" }}>
                      <figure>
                        <img src="/img/menu/icon-7.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/margin/cars/rental">
                            Rent a Super Car
                          </Link>
                        </h3>
                        <p>
                          Use your crypto gains to rent a supercar and enjoy the luxury ride of your dreams.
                        </p>
                        <Link to="/margin/cars/rental">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-box">
          <div className="container">
            <div className="col-sm-12">
              <h2 className="section-title-box text-center mb-5 style-2">
                <span>Explore</span>
                <span>the power</span>
                <span>of Bonex</span>
              </h2>
              <div className="explore-list-box" style={{ padding: "0 0 50px" }}>
                <div className="row">
                  <div className="col-sm-12 col-lg-4">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-1.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          Purchase & Sale
                        </h3>
                        <p>
                          Free the Money. Free the World
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-2.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          Secure Transactions
                        </h3>
                        <p>
                          SSL encryption, Cloudflare protection, encrypted source code and database, and Fireblocks wallet integration.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 viewmarket">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-3.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          24/7 Service
                        </h3>
                        <p>
                          Automatically process each transaction
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default {
  component: HomePageMargin,
};
