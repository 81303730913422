import _ from 'lodash';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

let displayCount = 3;

export const CarsCarousel = ({ slides, onSelect, btcRate, ethRate }) => {
  const [currentIndex, setSlide] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 780px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1200px)` });
  if (isMobile) {
    displayCount = 1;
  } else if (isTablet) {
    displayCount = 2;
  } else {
    displayCount = 3;
  }

  return (
    <div className="cars-slider">
      <Carousel
        centerMode
        centerSlidePercentage={100 / displayCount}
        selectedItem={currentIndex}
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <Carousel showThumbs={false} showArrows={false} showStatus={false}>
            {_.map(slides, slide => (
              <div key={slide}><img src={slide} /></div>
            ))}
          </Carousel>
        </div>
      </Carousel>
    </div>
  )
}
