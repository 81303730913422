import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withLocalize, Translate } from "react-localize-redux";
import { addProperty, addPropertyImage } from "../../actions";
import * as countries from "../../utilities/Countries";

const FIELDS = {
  title: {
    type: "input",
    label: "title",
    id: "title",
    required: true,
    errorMsg: "titleIsInvalid",
    col: "12"
  },
  description: {
    type: "input",
    label: "description",
    id: "description",
    required: true,
    errorMsg: "descriptionIsInvalid",
    col: "12"
  },
  country: {
    type: "input",
    label: "country",
    id: "country",
    required: true,
    errorMsg: "countryIsInvalid",
    col: "6"
  },
  /*country: {
    type: "select",
    label: "country",
    id: "country",
    required: true,
    translateOptions: false,
    options: {
      bg: countries.bg,
      en: countries.en,
      ro: countries.ro,
      gr: countries.gr
    },
    selector: {
      bg: "Изберете",
      en: "Select",
      ro: "Select",
      gr: "Select",
    },
    errorMsg: "countryIsInvalid",
    col: "6"
  },*/
  town: {
    type: "input",
    label: "town",
    id: "town",
    required: true,
    errorMsg: "townIsInvalid",
    col: "6"
  },
  email: {
    type: "input",
    label: "email",
    id: "email",
    required: true,
    errorMsg: "emailIsInvalid",
    col: "6"
  },
  phone: {
    type: "input",
    label: "phone",
    id: "phone",
    required: true,
    errorMsg: "phoneIsInvalid",
    col: "6"
  },
  price: {
    type: "input",
    label: "price",
    id: "price",
    required: true,
    errorMsg: "pricePropertyIsInvalid",
    col: "6"
  }
};

const FILES = {
  images: {
    name: "images",
    type: "file",
    label: "images",
    id: "images",
    required: false,
    accept: ".jpg, .png, .jpeg",
    errorMsg: "fileIsInvalid",
    repeat: 7
  },
};

class AddPropertyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      errorMessage: false,
      lang: this.props.activeLanguage.code,
      images: [{}, {}, {}, {}, {}, {}, {}]
    };
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
    }
  }

  renderField(field) {
    const {
      input,
      id,
      label,
      type,
      inputClass,
      col,
      meta: { touched, error },
    } = field;
    const className = `form-group col-xs-${col} ${touched && error ? "has-error" : "has-success"
      }`;
    return (
      <div className={className}>
        <div className="label-animated has-content">
          <input
            {...input}
            type={type}
            id={id}
            className={`form-control placeholder-no-fix ${inputClass}`}
            autoComplete="off"
          />
          <label className="control-label visible-ie8 visible-ie9">
            <Translate id={label} />
          </label>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderSelect(field) {
    const {
      input,
      id,
      label,
      options,
      selector,
      translateOptions,
      col,
      meta: { touched, error },
    } = field;
    const className = `col-xs-${col} ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="form-group">
          <div className="custom-style-select label-animated has-content">
            <label className="control-label visible-ie8 visible-ie9">
              <Translate id={label} />
            </label>
            <select {...input} id={id} className="form-control custom-select">
              <option value="">{selector[this.state.lang]}</option>
              {_.map(options[this.state.lang], (name, optionVal) => {
                return (
                  <option value={optionVal} key={optionVal}>
                    {translateOptions === true ? (
                      <Translate id={name} />
                    ) : (
                      `${name}`
                    )}
                  </option>
                );
              })}
            </select>
          </div>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderRadio(field) {
    const {
      input,
      label,
      type,
      id,
      col,
      meta: { touched, error },
    } = field;
    const className = `form-group col-md-${col} ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="custom-check">
          <label>
            <input {...input} type={type} id={id} className="form-control" />
            <i></i>
            <Translate id={label} />
          </label>
        </div>
        {touched && error ? (
          <div
            className="help-block with-errors"
            style={{ marginLeft: "16px" }}
          >
            <span>
              <Translate id={`errors.${error}`} />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderFile(field) {
    const {
      input,
      id,
      label,
      type,
      fileKey,
      fileIteration,
      accept,
      errorMsg,
      uploadFunc,
    } = field;
    let uploaded = {};
    let hasError = false;
    const className = `${fileIteration == 0 ? "col-md-12" : "col-md-4"}`;

    const onChangeUpload = async (file) => {
      uploaded = await uploadFunc(file).then(response => {
        if (response.success) {
          return response.result;
        }
        else {
          hasError = true;
          return {};
        }
      });
      let images = this.state.images;
      images[fileIteration] = uploaded;
      this.setState({ images });
    }

    return (
      <div className={className} >
        <div className="form-group">
          <div className="custom-upload">
            <input
              {...input}
              type={type}
              id={fileKey}
              accept={accept}
              className="form-control"
              value=""
              onChange={(e) => onChangeUpload(e.target.files[0])}
            />
            {fileIteration == 0 ? (
              <span>
                <Translate id={label} />
              </span>
            ) : ""}
            {_.isEmpty(this.state.images[fileIteration]) ? (
              <div className="custom-upload-fake">
                <figure>
                  <i className="sprite upload-icon"></i>
                </figure>
                <span>
                  <Translate id="upload" />
                </span>
              </div>
            ) : ""}
            {_.isEmpty(this.state.images[fileIteration]) && hasError ? (
              <div
                className="help-block with-errors"
                style={{ marginLeft: "16px" }}
              >
                <span>
                  <Translate id={`errors.${errorMsg}`} />
                </span>
              </div>
            ) : ""}
            {!_.isEmpty(this.state.images[fileIteration]) ? (
              <div className="custom-upload-preview">
                <figure>
                  <img src={`/properties/${this.state.images[fileIteration].real_name}`} />
                </figure>
                <span>{this.state.images[fileIteration].orginal_name}</span>
              </div>
            ) : ""}
          </div>
        </div>
      </ div>
    );
  }

  onSubmit(values) {
    this.props.addProperty(values, this.state.images).then((data) => {
      if (data.success) {
        this.setState({ submitted: true });
      } else {
        this.setState({
          errorMessage: data.message,
        });
      }
    });
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.errorMessage != "") {
      return (
        <div className="alert alert-danger">
          <strong>
            <Translate id="oops" />!
          </strong>{" "}
          {this.state.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    if (this.state.submitted) {
      return (
        <div className="infoFigureBox">
          <div className="row">
            <div className="col-12">
              <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="successLabel" />!
                </h3>
              </header>
            </div>
            <div className="col-md-offset-2 col-md-8">
              <div className="success-msg-box">
                <figure>
                  <i className="far fa-check-circle"></i>
                </figure>
                <h5>
                  <Translate id="addPropertySubmitted" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="add-property-form-container"
          className="add-property"
        >
          <div className="article-full overflow-visible">
            <form
              className="add-property-form form"
              id="add-property-form"
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <div className="transcard text-center">
                <div className="company-logo sm animate">
                  <figure>
                    <img src="/img/buy_property/house.png" alt="" />
                  </figure>
                </div>
                <h2 className="text-center"><Translate id="addProperty" /></h2>
                <div className="mb-more text-center">
                  <div className="mrgt-3 mrgb-3">
                    <div className="form-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="row">
                            {_.map(FIELDS, (field, fieldName) => {
                              if (field.type === "select") {
                                return (
                                  <Field
                                    name={fieldName}
                                    component={this.renderSelect.bind(this)}
                                    {...field}
                                    key={fieldName}
                                  />
                                );
                              } else {
                                return (
                                  <Field
                                    name={fieldName}
                                    component={this.renderField.bind(this)}
                                    {...field}
                                    key={fieldName}
                                  />
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="form-group custom-style-input">
                            <div className="inputsContainer">
                              <div className="inputsContBox">
                                <div className="row">
                                  {_.map(FILES, (field, fieldName) => {
                                    if (field.repeat) {
                                      return (
                                        <React.Fragment>
                                          {_.map([...Array(field.repeat)], (e, i) => {
                                            return this.renderFile({
                                              key: `images_${i}`,
                                              fileKey: `images_${i}`,
                                              fileIteration: i,
                                              name: `images[${i}]`,
                                              type: field.type,
                                              label: "images",
                                              id: "images",
                                              required: false,
                                              accept: ".jpg, .png, .jpeg",
                                              errorMsg: "fileIsInvalid",
                                              uploadFunc: this.props.addPropertyImage
                                            })
                                          })}
                                        </React.Fragment>
                                      );
                                    } else {
                                      return (
                                        <Field
                                          component={this.renderFile}
                                          {...field}
                                          key={fieldName}
                                          fileKey={field.name}
                                        />
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                              <div className="inputsContainer-bottom">
                                <p>
                                  <i className="info-symbol">i</i>
                                  <Translate id="documentsFilesInfo" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.renderAlert()}
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <div className="form-actions text-right">
                      <button type="submit" className="btn btn-primary">
                        <Translate id="send" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div >
      );
    }
  }
}

function validate(formProps) {
  const errors = {};

  _.each(FIELDS, (field, fieldName) => {
    if (!formProps[fieldName]) {
      errors[fieldName] = field.errorMsg;
    }

    if (field.options && formProps[fieldName] == "") {
      errors[fieldName] = field.errorMsg;
    }
  });

  return errors;
}

function mapStateToProps({ }) {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps, {
  addProperty,
  addPropertyImage
})(
  reduxForm({
    validate,
    form: "addPropertyForm",
    fields: FIELDS, // , FILES
    enableReinitialize: true
  })(withLocalize(AddPropertyForm))
);
