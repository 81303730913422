import _ from 'lodash';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

let displayCount = 3;

export const JetsCarousel = ({ slides, onSelect, btcRate, ethRate }) => {
  const [currentIndex, setSlide] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 780px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1200px)` });
  if (isMobile) {
    displayCount = 1;
  } else if (isTablet) {
    displayCount = 2;
  } else {
    displayCount = 3;
  }

  return (
    <div id="flight-slider">
      <Carousel
        centerMode
        centerSlidePercentage={100 / displayCount}
        selectedItem={currentIndex}
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {_.map(slides, result => {
          return (
            <div key={result.id} className="news-list-entry">
              <div>
                <article>
                  <div className='text-center'>
                    <h4>{result.category}<br />{result.title} or Similar</h4>
                  </div>
                  <Carousel showThumbs={false} showArrows={false} showStatus={false}>
                    <div><img src={`/img/aircrafts/${result.img}_0.png`} /></div>
                    <div><img src={`/img/aircrafts/${result.img}_1.jpg`} /></div>
                    <div><img src={`/img/aircrafts/${result.img}_2.jpg`} /></div>
                    <div><img src={`/img/aircrafts/${result.img}_3.jpg`} /></div>
                  </Carousel>
                  <div className='mt-3'>
                    {/* <div className='col-md-3 text-center'><img src={`/img/aircrafts/man3.svg`} /></div> */}
                    <div className='col-md-12'>
                      <p>Est. Flight Time: <strong>{result.time}h</strong></p>
                      <p>Passengers up to: <strong>{result.seats}</strong></p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 text-center'><h5>Book with Crypto</h5></div>
                    <div className='col-md-12 text-center'>
                      <button className='btn btn-action btn-rounded' onClick={() => onSelect(result, 'btc')}>{((result.cost * 0.85) / btcRate).toFixed(5)} BTC</button>
                      <button className='btn btn-action btn-rounded' onClick={() => onSelect(result, 'eth')}>{((result.cost * 0.85) / ethRate).toFixed(5)} ETH</button>
                      <button className='btn btn-action btn-rounded' onClick={() => onSelect(result, 'usdt')}>{((result.cost * 0.85)).toFixed(2)} USDT</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 text-center'><p>*Estimated price before taxes & fees</p></div>
                  </div>
                </article>
              </div>
            </div >
          );
        })
        }
      </Carousel>
      <div className='text-center'>
        <img src="/img/prev.png" style={{ width: '30px', cursor: 'pointer', margin: '5px' }} onClick={() => setSlide((currentIndex - displayCount + slides.length) % slides.length)} />
        <img src="/img/next.png" style={{ width: '30px', cursor: 'pointer', margin: '5px' }} onClick={() => setSlide((currentIndex + displayCount) % slides.length)} />
      </div>
    </div >
  )
}
