import _ from "lodash";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import Loading from "../Loading";
import { setMessageSeen } from '../../actions';

class Message extends Component {
  constructor(props) {
    super(props);
  }

  async setMessageSeen(messageID) {
    let result = await this.props.setMessageSeen(messageID);
    if (result.success) {
      this.props.entry.seen = true;
    }
  }

  render() {
    if (!_.isEmpty(this.props.entry)) {
      return (
        <div className="panel-group" id="message-list" role="tablist" aria-multiselectable="true" onClick={() => this.setMessageSeen(this.props.entry.id)}>
          <div className={`panel panel-default ${(this.props.entry.seen) ? '' : 'not-read'}`}>
            <div className="panel-heading" role="tab" id={`msg-h-${this.props.entry.id}`}>
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#message-list" href={`#msg-t-${this.props.entry.id}`} aria-expanded="false" aria-controls={`msg-t-${this.props.entry.id}`}>
                  <span>{new Date(this.props.entry._created).toLocaleDateString()}</span> {this.props.entry.title}
                </a>
              </h4>
            </div>
            <div id={`msg-t-${this.props.entry.id}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`msg-h-${this.props.entry.id}`}>
              <div className="panel-body">
                <div className="text" dangerouslySetInnerHTML={{ __html: this.props.entry.content }}></div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <Loading />;
    }
  }
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { setMessageSeen })(withLocalize(Message));
