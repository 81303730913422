import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';;
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { resetPass, clearAuthErrors } from '../../actions';
import { globalRegExes } from '../../utilities/RegEx';

const FIELDS = {
    token: {
        type: 'text',
        label: 'code',
        required: true,
        errorMsg: 'validationCodeIsInvalid'
    },
    password: {
        type: 'password',
        label: 'password',
        required: true,
        errorMsg: 'passwordIsInvalid'
    },
    passwordConfirm: {
        type: 'password',
        label: 'passwordConfirm',
        required: true,
        errorMsg: 'passwordConfirmIsInvalid'
    }
};

class ResetForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            sent: false
        }
    }

    handleFormSubmit(data) {
        this.props.resetPass(data).then((success) => {
            if(success) {
                this.setState({ sent: true });
            }
        });
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;

        const className = `form-group ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input 
                            className="form-control placeholder-no-fix" 
                            {...input}
                            type={type} 
                        />
                        <label className="control-label visible-ie8 visible-ie9">
                            <Translate id={label} />
                        </label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{marginLeft: '16px'}}>
                                 <span><Translate id={`errors.${error}`} /></span>
                            </div> 
                        ) : ''} 
                    </div>
                </div>
            </div>
        );
    }

    renderAlert() {
        if (this.props.errorMessage && this.props.errorMessage != '') {
            return (
                <div className="alert alert-danger">
                    <strong><Translate id="oops" />!</strong> {this.props.errorMessage}
                </div>
            );
        }
    }

    componentWillUnmount() {
        this.props.clearAuthErrors();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div id="validate-form-container" className="validate-form-container">
                {!this.state.sent ? (
                    <form 
                        className="validate-form default-forms" 
                        id="validate-form" 
                        onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                    >
                        <div className="form-cont-header">
                            <h3 className="form-title"><Translate id="resetPassword" /></h3>
                            <p><Translate id="insertCode" /> <strong>E-mail</strong></p>
                        </div>
                        <div className="form-group-box">
                            {_.map(FIELDS, (field, fieldName) => {
                                return <Field
                                    name={fieldName}
                                    component={this.renderField}
                                    key={fieldName}
                                    {...field}
                                />
                            })}
                            {this.renderAlert()}
                            <div className="form-actions">
                                <button type="submit" className="submit"><Translate id="send" /></button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="text-center">
                        <h3><Translate id="success.resetPassword" /></h3>
                        <div className="form-cont-footer">
                            <p><Translate id="signinToYourProfile" /></p>
                            <Link to="/login"><Translate id="signin" />!</Link>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName]) {
            errors[fieldName] = field.errorMsg;
        } else {
            if(fieldName == 'password') {
                if(formProps[fieldName].length < 8) {
                    errors[fieldName] = 'passwordIsTooShort';
                } else if(formProps[fieldName].length > 36) {
                    errors[fieldName] = 'passwordIsTooLong';
                } else if(!formProps[fieldName].match(globalRegExes.password)) {
                    errors[fieldName] = field.errorMsg;
                }
            }
        }
    });

    if (formProps.password != '' && 
        formProps.passwordConfirm != '' && 
        formProps.password != formProps.passwordConfirm
    ) {
        errors.passwordConfirm = 'passwordConfirmIsInvalid';
    }

    return errors;
}

function mapStateToProps({ auth, user }, { token }) {
    return {
        auth,
        user,
        initialValues: { token },
        errorMessage: auth && auth.error ? auth.error : ''
    };
}

export default connect(mapStateToProps, { resetPass, clearAuthErrors })(
    reduxForm({
        validate,
        form: 'reset',
        fields: FIELDS,
        enableReinitialize : true
    })(ResetForm)
)

