import { FETCH_PROPERTIES, CLEAR_PROPERTIES } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PROPERTIES:
      return { ...state, ...action.payload };
    case CLEAR_PROPERTIES:
      return null;
    default:
      return state;
  }
}