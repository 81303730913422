import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class AboutUsBG extends Component {
  render() {
    return (
      <div className="intro-box">
        <div className="card-cover card-cover-2">
          <img src="/img/covers/About.PNG" alt="" />
          <h1 style={{ marginTop: '20px' }}><Translate id="weAreBonex" /></h1>
          <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">За нас</h2>
            <p><strong>BoneX</strong>&nbsp;е иновативен дигитален портфейл, който предлага лесен, бърз и сигурен начин за купуване и продаване на криптовалути като се стараем винаги да предоставяме най-добрите условия на нашите клиенти.</p>
            <p><strong>BoneX също така</strong>&nbsp;предлага и крипто счетовдство, продажба и подръжка на майиннг техника така както и покупка на недвижими имоти.</p>
            <p>Екипът от професионалисти зад&nbsp;<strong>BoneX</strong>&nbsp; се ангажира с осигуряването на безопасна и сигурна търговия на дигитални валути, осъзнавайки напълно доверието и отгорността която носим.</p>
            <h3 className="mb-more">Основните предимства на BoneX пред останалите платформи за криптообмен са:</h3>
            <div className="row">
              <div className="col-12 col-lg-10 col-lg-offset-1">
                <div className="benefits-list no-margin-sides">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-user-alt"></i>
                          <span></span>
                        </div>
                        <h3>Бързо и улеснено създаване на акаунт</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-sync"></i>
                          <span></span>
                        </div>
                        <h3>Сигурни транзакции <br /> ( SSL и Cloudfare защита, криптиран Source code и база данни )</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-coins"></i>
                          <span></span>
                        </div>
                        <h3>Най-конкурентните такси за транзакциите</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="far fa-magic"></i>
                          <span></span>
                        </div>
                        <h3>24/7 обслужване<br /> ( автоматично обработване на всяка една транзакция )</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-phone-laptop"></i>
                          <span></span>
                        </div>
                        <h3>Интерфейс подходящ за начинаещи</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-headset"></i>
                          <span></span>
                        </div>
                        <h3>Отлична потребителска поддръжка</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transcard">
              <h2 className="text-center mt-0">С&nbsp;<strong>BoneX</strong>&nbsp;</h2>
              <p>Всеки един потребител има възможността да навлезе по изключително лесен, бърз, достъпен и удобен начин в света на криптовалутите, да следи в реално време движението и тенденциите на техните цени, както и да се възползва от предлаганите от платформата инструменти за изграждането на ценните за всеки инвеститор фундаментални и технически анализи.</p>
              <p>Създайте за броени секунди своя&nbsp;<strong>BoneX</strong>&nbsp;профил и станете част от бъдещето на блокчейн.</p>
              <br /><br />
              <hr />
              <figure>
                <img alt="" src="/img/Partners.PNG" />
              </figure>
              {/*  
              <h3 className="text-center blue-title">Нашите партьори</h3>
              <div className="logo-list">
                <figure>
                  <a href="https://bmw-bovacar.bg" target="_blank"><img alt="" src="/content/files/2019/09/04/594826c0fbc297ddb0d78709ad72784c.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.bmw.bg/bg/index.html" target="_blank"><img alt="" src="/content/files/2019/09/04/0b35df7b9ba12e857fe81da1f3ea7d53.png" /></a>
                </figure>
                <figure>
                  <a href="https://creditcommerce.bg" target="_blank"><img alt="" src="/content/files/2019/09/04/7366b237f7ce7dcbfde8afe5fae19220.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.easypay.bg/site" target="_blank"><img alt="" src="/content/files/2019/09/04/71b44e1c35be9a8d852cc8aaf14e4333.png" /></a>
                </figure>
                <figure>
                  <a href="https://www.mistertango.com/en/" target="_blank"><img alt="" src="/content/files/2019/09/04/c1dfc05a8275a52c797a41502bae1364.png" /></a>
                </figure>
              </div>
              <p>&nbsp;</p>
              <hr />
              <h3 className="text-center blue-title">Нашите медийни партьори</h3>
              <div className="logo-list">
                <figure>
                  <img alt="" src="/content/files/2021/05/12/1566e8dab6ecb9bac415fd09efe1fca8.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/795071c899d1b1eba56f92da0cc7ff98.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/a02fbd29826548b5ad35f2f243e271be.jpg" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/81e6fdb9b2412c1827cb811c49917ef5.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/81e6fdb9b2412c1827cb811c49917ef5.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/c2d1a079ffdbbae21b148a3b1f5443ff.png" />
                </figure>
                <figure>
                  <img alt="" src="/content/files/2021/05/12/0dd80b6759489bbc6a3a9f695bb1efe0.png" />
                </figure>
              </div>
              <br /><br />
              */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AboutUsBG;
