import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { fetchUser, signOut } from '../../actions';

class SignOutPage extends Component {

    componentDidMount() {
        this.props.signOut();
        setTimeout(() => {
            window.location = "/"
        }, 3000);
    }

    head() {
        return (
            <Helmet>
                <title>{`Изход - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Изход - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="" style={{height: "500px"}}>
                        <div id="logout" className="login-tab-container open" style={{marginTop: "300px"}}>
                            <h3 style={{color: "white", textAlign: "center"}}><Translate id="sorryToGo" /></h3>
                            {/* <div className="form-cont-footer">
                                <p style={{color: "white"}}><Translate id="forgotSomething" />?</p>
                                <Link to="/login"><Translate id="signin" /> <Translate id="again" />!</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser, signOut })(SignOutPage),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
