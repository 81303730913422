import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import requireAuth from "../../components/hocs/requireAuth";
import AddPropertyForm from '../../components/buy-property/AddPropertyForm';

class AddProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: this.props.activeLanguage.code
    };
  }

  head() {
    return (
      <Helmet>
        <title>{`Добави обява - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Добави обява - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
    }
  }


  renderPage() {
    return
  }

  render() {
    return (
      <div>
        {this.head()}
        <div className="mt-5">
          <AddPropertyForm />
        </div>
      </div>
    );
  }
};

function mapStateToProps({ }) {
  return {};
}

export default {
  component: connect(mapStateToProps)(
    withLocalize(requireAuth(AddProperty))
  )
}
