import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class CryptoAccountingEN extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/crypto-cover-1.png" alt="" />
          <h1><Translate id="cryptoAccounting" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more">
              <span>THE PROCEDURE AND THE GOOD ORGANIZATION OF THE WORK PROCESS ARE CRUCIAL FOR EACH BUSINESS AS A TEAM OF TRUSTED PROFESSIONALS IS.</span>
            </h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>THE BENEFITS OF CRYPTO ACCOUNTING</h3>
                    <ul className="text-left">
                      <li>A professional team takes care of your business </li>
                      <li>We know crypto, it will be easier with us </li>
                      <li>Your activity and income are arranged and statutory</li>
                      <li>You have the ease to think of your growth</li>
                      <li>We are digital, we communicate where you do, work with digital documents and will build an organization that matches your business</li>
                      <li>You focus on crypto and the documents are for us</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/card-icon.png" alt="" />
                    </figure>
                    <h3>FOR WHOLESOME ONLINE SERVICES</h3>
                    <ul className="text-left">
                      <li>Fast and easy communication online that saves time</li>
                      <li>Strong tax and accounting expertise</li>
                      <li>Optimization for operative processes and budget for an accounting service</li>
                      <li>Plastic service according to the customer’s objectives and priorities</li>
                      <li>Professional accountants with experience of long standing</li>
                      <li>You pay only what you really use</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-center">
              All services and consultations performed by cryptoaccounting are paid. Prices start from BGN 100 incl. VAT. Amounts can be paid upon initial contact with the accounting department by bank or crypto.
            </p>
            <p className="text-center bottom-text">
              <strong>
                For more information, <br /> you maywrite at {" "}
                <a
                  href="mailto:cryptoaccounting@bonex.org"
                  title=""
                >cryptoaccounting@bonex.org</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CryptoAccountingEN;
