const getTotalAccountBalancePrice = (currencyType, rates, accounts) => {
    const ratesArr = Object.values(rates);
    if (ratesArr.length === 0) {
        return null
    }
    const currency = ratesArr.find((rate) => {
        return rate.type === currencyType
    })
    const currencyRate = currency.rate

    if (accounts === null) {
        return null
    }
    const accountsArr = Object.values(accounts)
    if (accountsArr.length === 0) {
        return null
    }
    const balancesSum = accountsArr
        .filter((account) => {
            return account.balance > 0
        })
        .map((account) => {
            const balance = account.balance;
            const accountRate = ratesArr.find((rate) => {
                return rate.type === account.type
            })
            return balance * accountRate.rate
        })
        .reduce((balance, el) => {
            return balance + el
        }, 0)

    return parseFloat(balancesSum / currencyRate).toFixed(2)
}

export default getTotalAccountBalancePrice