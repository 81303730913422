import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import PagesItem from '../components/pages/PagesItem';

class TermsReferralPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Условия за Реферална програма - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Условия за Реферална програма - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div>
                {this.head()}
                <div className="intro-box">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="intro-text">
                                <h2><span><Translate id="termsReferral" /></span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="infoFigureBox p20" style={{display: "block"}}>
                    <PagesItem slug="terms-referral" />
                </div>
            </div>
        );
    }
};


export default {
    component: TermsReferralPage
};