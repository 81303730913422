import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { CarsCarousel } from './CarsCarousel';
import { Link } from "react-router-dom";

export const CarsListItem = ({ item, type, rates, currency }) => {
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    if (item.image) {
      const img = new Image();
      img.onload = () => setImageExists(true);
      img.onerror = () => setImageExists(false);
      img.src = item.image;
    }
  }, [item]);

  if (!item) return <div></div>;

  let btc, eth, usdt;
  let btcRate, ethRate, usdtRate;
  if (rates) {
    _.map(rates, rate => {
      if (rate.type == 'BTC') {
        btc = rate;
      }

      if (rate.type == 'ETH') {
        eth = rate;
      }

      if (rate.type == "USDT") {
        usdt = rate;
      }
    });

    if (btc) {
      btcRate = btc.rate;
    }

    if (eth) {
      ethRate = eth.rate;
    }

    if (usdt) {
      usdtRate = usdt.rate;
    }
  }

  return (
    imageExists && (
      <div className="car-entry col-md-4">
        <CarsCarousel slides={[item.image]} />
        <div className="text">
          <header>
            <div className='row mb-4'>
              <div className='col-md-6'>
                <p>
                  {item.daily != 0 ? (
                    <React.Fragment>
                      <img src={`/img/assets/btc.png`} style={{ width: '20px' }} /> {parseFloat((item.daily * currency.rate) / btcRate).toFixed(4)} BTC / Day
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img src={`/img/assets/btc.png`} style={{ width: '20px' }} /> {parseFloat((item.price * currency.rate) / btcRate).toFixed(4)} BTC
                    </React.Fragment>
                  )}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  {item.daily != 0 ? (
                    <React.Fragment>
                      <img src={`/img/assets/usdt.png`} style={{ width: '20px' }} /> {parseFloat((item.daily * currency.rate) / usdtRate).toFixed(2)} USDT / Day
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img src={`/img/assets/usdt.png`} style={{ width: '20px' }} /> {parseFloat((item.price * currency.rate) / usdtRate).toFixed(2)} USDT
                    </React.Fragment>
                  )}
                </p>
              </div>
            </div>
            <h3><Link to={`/cars/${type}/${item.id}`}>{item.maker} {item.model}</Link></h3>
          </header>
          <ul className="rent-info">
            <li><i className="fal fa-calendar-alt"></i> {item.year}</li>
            <li><i className="far fa-tachometer"></i> {item.horse_power} HP</li>
            <li><i className="fal fa-user-friends"></i> {item.seats}</li>
          </ul>
        </div>
        <span className="car-label-rent">Sport</span>
      </div>
    )
  )
}
