import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class CryptoAccountingRO extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/crypto-cover-1.png" alt="" />
          <h1><Translate id="cryptoAccounting" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more">
              <span>ORDINEA ȘI BUNA ORGANIZARE A FLUXULUI DE LUCRU SUNT ESENȚIALE PENTRU ORICE AFACERE, LA FEL CA ȘI O ECHIPĂ DE PROFESIONIȘTI DE ÎNCREDERE.</span>
            </h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>BENEFICIILE CONTABILITĂȚII CRIPTO</h3>
                    <ul className="text-left">
                      <li>O echipă de profesioniști are grijă de afacerea dvs.</li>
                      <li>Noi știm ce este cripto, cu noi vă va fi ușor</li>
                      <li>Activitățile și veniturile dvs. sunt ordonate și corecte din punct de vedere legal</li>
                      <li>Vei obține iniștea necesară ca să vă gândiți cum să vă dezvoltați</li>
                      <li>Suntem digitali, comunicăm acolo unde comunicați și dvs., lucrăm cu documente digitale și vă vom construi o organizație care se potrivește afacerii dvs.</li>
                      <li>Vă concentrați la cripto, iar documentele dvs. sunt în grija noastră</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/card-icon.png" alt="" />
                    </figure>
                    <h3>PENTRU UN SERVICIU ONLINE COMPLET</h3>
                    <ul className="text-left">
                      <li>Comunicare online rapidă și ușoară care economisește timp</li>
                      <li>Expertiza fiscală și contabilă solidă</li>
                      <li>Optimizarea proceselor operaționale și a bugetului pentru serviciul de contabilitate</li>
                      <li>Serviciu flexibil în funcție de obiectivele și prioritățile clientului</li>
                      <li>Contabili profesioniști cu ani de experiență</li>
                      <li>Plătiți doar pentru ceea ce folosiți cu adevărat</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-center bottom-text">
              <strong>
                Pentru mai multe informații,<br /> ne puteți scrie la {" "}
                <a
                  href="mailto:cryptoaccounting@bonex.org"
                  title=""
                >cryptoaccounting@bonex.org</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CryptoAccountingRO;
