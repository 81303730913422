import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class OpenBankingBG extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/open_banking/Online_Banking.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">

            <div className="mining-text pt-0">
              <h2 className="text-center">
                НОВО ОТ BONEX<br />
                <span>УПРАВЛЯВАЙ ВСИЧКИ ФИНАНСИ<br /> ОТ ЕДНО МЯСТО - 24/7</span>
              </h2>
              <p>
                BoneX мисли за твоя комфорт и свобода, и за да можеш да се фокусираш върху нещата, в които си наистина добър и да растеш, създадохме за теб единно решение за 24/7 управление на всички финансови средства - бързо, удобно и изцяло онлайн.
              </p>
              <p>
                <strong>Следи и управлявай паричните си потоци от всяка точка на света и по всяко време!</strong>
              </p>
              <p>
                Вече лесно и бързо можеш да проследяваш и управляваш средствата си от всички свои банкови сметки и крипто портфейли от своя персонален BoneX акаунт със светкавична скорост и високо ниво на сигурност, гарантирано от лицензирания ни технологичен партньор Aris Solutions.
              </p>
              <div className="mb-more"></div>
              <br />
              <br />
              <div className="benefits-list no-margin-sides">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/fast.png" alt="" />
                        <span></span>
                      </div>
                      <h3>УДОБСТВО И БЪРЗИНА</h3>
                      <p>Разплащай на момента от всички твои банкови сметки и BoneX крипто портфейли</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/control.png" alt="" />
                        <span></span>
                      </div>
                      <h3>ПЪЛЕН КОНТРОЛ</h3>
                      <p>Проследявай подробно баланса си и историята на транзакциите</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/security.png" alt="" />
                        <span></span>
                      </div>
                      <h3>ВИСОКО НИВО НА СИГУРНОСТ</h3>
                      <p>Запази поверителността на своите сметки с криптирана система с многостепенна защита</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-center"><strong>Оптимизирай управлението на финансовите си средства с BoneX!</strong></p>
              <div className="text-center">
                <br />
                <Link className="btn-link" to={'bank'}>ЗАПОЧНИ СЕГА</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OpenBankingBG;
