import { FETCH_NEWS, CLEAR_NEWS } from '../actions/types';

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_NEWS:
            return { ...state, ...action.payload };
        case CLEAR_NEWS:
            return null;
        default:
            return state;
    }
}