export const bg = {
  1: "Афганистан",
  2: "Албания",
  3: "Алжир",
  4: "Американска Самоа",
  5: "Андора",
  6: "Ангола",
  7: "Ангила",
  8: "Антарктика",
  9: "Антигуа и Барбуда",
  10: "Аржентина",
  11: "Армения",
  12: "Аруба",
  13: "Австралия",
  14: "Австрия",
  15: "Азербaйджан",
  16: "Бахамски острови",
  17: "Бахрейн",
  18: "Бангладеш",
  19: "Барбадос",
  20: "Беларус",
  21: "Белгия",
  22: "Белиз",
  23: "Бенин",
  24: "Бермудски острови",
  25: "Бутан",
  26: "Боливия",
  27: "Босна и Херцеговина",
  28: "Ботсвана",
  29: "Остров Буве",
  30: "Бразилия",
  31: "Британска територия в Индийския океан",
  32: "Бруней",
  33: "България",
  34: "Буркина Фасо",
  35: "Бурунди",
  36: "Камбоджа",
  37: "Камерун",
  38: "Канада",
  39: "Кабо Верде",
  40: "Кайманови острови",
  41: "Централна Африканска Република",
  42: "Чад",
  43: "Чили",
  44: "Китай",
  45: "Рождество",
  46: "Кокосови острови (Кийлинг) острови",
  47: "Колумбия",
  48: "Коморски острови",
  49: "Конго",
  50: "Кук острови",
  51: "Коста Рика",
  52: "Кот д'Ивоар",
  53: "Хърватска",
  54: "Куба",
  55: "Кипър",
  56: "Чехия",
  57: "Дания",
  58: "Джибути",
  59: "Доминика",
  60: "Доминиканска република",
  61: "Източен Тимор",
  62: "Еквадор",
  63: "Египет",
  64: "Ел Салвадор",
  65: "Екваториална Гвинея",
  66: "Еритрея",
  67: "Естония",
  68: "Етиопия",
  69: "Фолклендски острови",
  70: "Фарьорски острови",
  71: "Фиджи",
  72: "Финландия",
  73: "Франция",
  74: "Франция: Metropolitan",
  75: "Френска Гвиана",
  76: "Френска Полинезия",
  77: "Френски южни територии",
  78: "Габон",
  79: "Гамбия",
  80: "Грузия",
  81: "Германия",
  82: "Гана",
  83: "Гибралтар",
  84: "Гърция",
  85: "Гренландия",
  86: "Гренада",
  87: "Гваделупа",
  88: "Гуам",
  89: "Гватемала",
  90: "Гвинея",
  91: "Гвинея Бисау",
  92: "Гвиана",
  93: "Хаити",
  94: "Остров Хърд и острови Макдоналд",
  95: "Хондурас",
  96: "Хонг Конг",
  97: "Унгария",
  98: "Исландия",
  99: "Индия",
  100: "Индонезия",
  101: "Иран",
  102: "Ирак",
  103: "Ирландия",
  104: "Израел",
  105: "Италия",
  106: "Ямайка",
  107: "Япония",
  108: "Йордания",
  109: "Казахстан",
  110: "Кения",
  111: "Кирибати",
  112: "Северна Корея",
  113: "Южна Корея",
  114: "Кувейт",
  115: "Киргизстан",
  116: "Лаос",
  117: "Латвия",
  118: "Ливан",
  119: "Лесото",
  120: "Либерия",
  121: "Либия",
  122: "Лихтенщайн",
  123: "Литва",
  124: "Люксембург",
  125: "Макаo",
  126: "Македония",
  127: "Мадагаскар",
  128: "Малави",
  129: "Малайзия",
  130: "Малдивските острови",
  131: "Мали",
  132: "Малта",
  133: "Маршалови острови",
  134: "Мартиника",
  135: "Мавритания",
  136: "Мавриций",
  137: "Майот",
  138: "Мексико",
  139: "Микронезия",
  140: "Молдова",
  141: "Монако",
  142: "Монголия",
  143: "Монсерат",
  144: "Мароко",
  145: "Мозамбик",
  146: "Мианмар (Бирма)",
  147: "Намибия",
  148: "Науру",
  149: "Непал",
  150: "Холандия",
  151: "Холандски Антили",
  152: "Нова Каледония",
  153: "Нова Зеландия",
  154: "Никарагуа",
  155: "Нигер",
  156: "Нигерия",
  157: "Ниуе",
  158: "Норфолк",
  159: "Северни Мариански острови",
  160: "Норвегия",
  161: "Оман",
  162: "Пакистан",
  163: "Палау",
  164: "Панама",
  165: "Папуа Нова Гвинея",
  166: "Парагвай",
  167: "Перу",
  168: "Филипини",
  169: "Питкерн",
  170: "Полша",
  171: "Португалия",
  172: "Пуерто Рико",
  173: "Катар",
  174: "Реюнион",
  175: "Румъния",
  176: "Русия",
  177: "Руанда",
  178: "Сейнт Китс и Невис",
  179: "Санта Лучия",
  180: "Сейнт Винсент и Гренадини",
  181: "Самоа",
  182: "Сан Марино",
  183: "Сао Томе и Принсипи",
  184: "Саудитска Арабия",
  185: "Сенегал",
  186: "Сейшелски острови",
  187: "Сиера Леоне",
  188: "Сингапур",
  189: "Словакия",
  190: "Словения",
  191: "Соломонови острови",
  192: "Сомалия",
  193: "Южна Африка",
  194: "Южна Джорджия",
  195: "Испания",
  196: "Шри Ланка",
  197: "Света Елена",
  198: "Сен Пиер и Микелон",
  199: "Судан",
  200: "Суринам",
  201: "Свалбард и Ян Майен острови",
  202: "Свазиленд",
  203: "Швеция",
  204: "Швейцария",
  205: "Сирия",
  206: "Тайван",
  207: "Таджикистан",
  208: "Танзания",
  209: "Тайланд",
  210: "Того",
  211: "Токелау",
  212: "Тонга",
  213: "Тринидад и Тобаго",
  214: "Тунис",
  215: "Турция",
  216: "Туркменистан",
  217: "Търкс и Кейкос о-ви",
  218: "Тувалу",
  219: "Уганда",
  220: "Украйна",
  221: "Обединени Арабски Емирства",
  222: "Великобритания",
  223: "САЩ",
  224: "САЩ Малки далечни острови",
  225: "Уругвай",
  226: "Узбекистан",
  227: "Вануату",
  228: "Светия престол (Ватикана)",
  229: "Венецуела",
  230: "Виетнам",
  231: "Вирджински острови (Великобритания)",
  232: "Вирджински острови (САЩ)",
  233: "Уолис и Футуна",
  234: "Западна Сахара",
  235: "Йемен",
  236: "Сърбия",
  237: "Конго",
  238: "Замбия",
  239: "Зимбабве",
  240: "Кот д''Ивоар",
  241: "Канарски острови",
};

export const en = {
  1: "Afghanistan",
  2: "Albania",
  3: "Algeria",
  4: "American Samoa",
  5: "Andorra",
  6: "Angola",
  7: "Anguilla",
  8: "Antarctica",
  9: "Antigua and Barbuda",
  10: "Argentina",
  11: "Armenia",
  12: "Aruba",
  13: "Australia",
  14: "Austria",
  15: "Azerbaijan",
  16: "Bahamas",
  17: "Bahrain",
  18: "Bangladesh",
  19: "Barbados",
  20: "Belarus",
  21: "Belgium",
  22: "Belize",
  23: "Benin",
  24: "Bermuda",
  25: "Bhutan",
  26: "Bolivia",
  27: "Bosnia and Herzegowina",
  28: "Botswana",
  29: "Bouvet Island",
  30: "Brazil",
  31: "British Indian Ocean Territory",
  32: "Brunei Darussalam",
  33: "Bulgaria",
  34: "Burkina Faso",
  35: "Burundi",
  36: "Cambodia",
  37: "Cameroon",
  38: "Canada",
  39: "Cape Verde",
  40: "Cayman Islands",
  41: "Central African Republic",
  42: "Chad",
  43: "Chile",
  44: "China",
  45: "Christmas Island",
  46: "Cocos Keeling) Islands",
  47: "Colombia",
  48: "Comoros",
  49: "Congo",
  50: "Cook Islands",
  51: "Costa Rica",
  52: 'Cote D"Ivoire',
  53: "Croatia",
  54: "Cuba",
  55: "Cyprus",
  56: "Czech Republic",
  57: "Denmark",
  58: "Djibouti",
  59: "Dominica",
  60: "Dominican Republic",
  61: "East Timor",
  62: "Ecuador",
  63: "Egypt",
  64: "El Salvador",
  65: "Equatorial Guinea",
  66: "Eritrea",
  67: "Estonia",
  68: "Ethiopia",
  69: "Falkland Islands Malvinas)",
  70: "Faroe Islands",
  71: "Fiji",
  72: "Finland",
  73: "France",
  74: "France,Metropolitan",
  75: "French Guiana",
  76: "French Polynesia",
  77: "French Southern Territories",
  78: "Gabon",
  79: "Gambia",
  80: "Georgia",
  81: "Germany",
  82: "Ghana",
  83: "Gibraltar",
  84: "Greece",
  85: "Greenland",
  86: "Grenada",
  87: "Guadeloupe",
  88: "Guam",
  89: "Guatemala",
  90: "Guinea",
  91: "Guinea-bissau",
  92: "Guyana",
  93: "Haiti",
  94: "Heard and Mc Donald Islands",
  95: "Honduras",
  96: "Hong Kong",
  97: "Hungary",
  98: "Iceland",
  99: "India",
  100: "Indonesia",
  101: "Iran Islamic Republic of)",
  102: "Iraq",
  103: "Ireland",
  104: "Israel",
  105: "Italy",
  106: "Jamaica",
  107: "Japan",
  108: "Jordan",
  109: "Kazakhstan",
  110: "Kenya",
  111: "Kiribati",
  112: "North Korea",
  113: "Korea,Republic of",
  114: "Kuwait",
  115: "Kyrgyzstan",
  116: 'Lao People"s Democratic Republic',
  117: "Latvia",
  118: "Lebanon",
  119: "Lesotho",
  120: "Liberia",
  121: "Libya",
  122: "Liechtenstein",
  123: "Lithuania",
  124: "Luxembourg",
  125: "Macau",
  126: "Macedonia",
  127: "Madagascar",
  128: "Malawi",
  129: "Malaysia",
  130: "Maldives",
  131: "Mali",
  132: "Malta",
  133: "Marshall Islands",
  134: "Martinique",
  135: "Mauritania",
  136: "Mauritius",
  137: "Mayotte",
  138: "Mexico",
  139: "Micronesia,Federated States of",
  140: "Moldova",
  141: "Monaco",
  142: "Mongolia",
  143: "Montserrat",
  144: "Morocco",
  145: "Mozambique",
  146: "Myanmar",
  147: "Namibia",
  148: "Nauru",
  149: "Nepal",
  150: "Netherlands",
  151: "Netherlands Antilles",
  152: "New Caledonia",
  153: "New Zealand",
  154: "Nicaragua",
  155: "Niger",
  156: "Nigeria",
  157: "Niue",
  158: "Norfolk Island",
  159: "Northern Mariana Islands",
  160: "Norway",
  161: "Oman",
  162: "Pakistan",
  163: "Palau",
  164: "Panama",
  165: "Papua New Guinea",
  166: "Paraguay",
  167: "Peru",
  168: "Philippines",
  169: "Pitcairn",
  170: "Poland",
  171: "Portugal",
  172: "Puerto Rico",
  173: "Qatar",
  174: "Reunion",
  175: "Romania",
  176: "Russian Federation",
  177: "Rwanda",
  178: "Saint Kitts and Nevis",
  179: "Saint Lucia",
  180: "Saint Vincent and the Grenadines",
  181: "Samoa",
  182: "San Marino",
  183: "Sao Tome and Principe",
  184: "Saudi Arabia",
  185: "Senegal",
  186: "Seychelles",
  187: "Sierra Leone",
  188: "Singapore",
  189: "Slovakia",
  190: "Slovenia",
  191: "Solomon Islands",
  192: "Somalia",
  193: "South Africa",
  194: "South Georgia &amp; South Sandwich Islands",
  195: "Spain",
  196: "Sri Lanka",
  197: "St. Helena",
  198: "St. Pierre and Miquelon",
  199: "Sudan",
  200: "Suriname",
  201: "Svalbard and Jan Mayen Islands",
  202: "Swaziland",
  203: "Sweden",
  204: "Switzerland",
  205: "Syrian Arab Republic",
  206: "Taiwan",
  207: "Tajikistan",
  208: "Tanzania,United Republic of",
  209: "Thailand",
  210: "Togo",
  211: "Tokelau",
  212: "Tonga",
  213: "Trinidad and Tobago",
  214: "Tunisia",
  215: "Turkey",
  216: "Turkmenistan",
  217: "Turks and Caicos Islands",
  218: "Tuvalu",
  219: "Uganda",
  220: "Ukraine",
  221: "United Arab Emirates",
  222: "United Kingdom",
  223: "United States",
  224: "United States Minor Outlying Islands",
  225: "Uruguay",
  226: "Uzbekistan",
  227: "Vanuatu",
  228: "Vatican City State Holy See)",
  229: "Venezuela",
  230: "Viet Nam",
  231: "Virgin Islands British)",
  232: "Virgin Islands U.S.)",
  233: "Wallis and Futuna Islands",
  234: "Western Sahara",
  235: "Yemen",
  236: "Serbia",
  237: "Democratic Republic of Congo",
  238: "Zambia",
  239: "Zimbabwe",
  240: "Channel Islands",
  241: "Canary Islands",
};

export const ro = {
  1: "Afghanistan",
  2: "Albania",
  3: "Algeria",
  4: "American Samoa",
  5: "Andorra",
  6: "Angola",
  7: "Anguilla",
  8: "Antarctica",
  9: "Antigua and Barbuda",
  10: "Argentina",
  11: "Armenia",
  12: "Aruba",
  13: "Australia",
  14: "Austria",
  15: "Azerbaijan",
  16: "Bahamas",
  17: "Bahrain",
  18: "Bangladesh",
  19: "Barbados",
  20: "Belarus",
  21: "Belgium",
  22: "Belize",
  23: "Benin",
  24: "Bermuda",
  25: "Bhutan",
  26: "Bolivia",
  27: "Bosnia and Herzegowina",
  28: "Botswana",
  29: "Bouvet Island",
  30: "Brazil",
  31: "British Indian Ocean Territory",
  32: "Brunei Darussalam",
  33: "Bulgaria",
  34: "Burkina Faso",
  35: "Burundi",
  36: "Cambodia",
  37: "Cameroon",
  38: "Canada",
  39: "Cape Verde",
  40: "Cayman Islands",
  41: "Central African Republic",
  42: "Chad",
  43: "Chile",
  44: "China",
  45: "Christmas Island",
  46: "Cocos Keeling) Islands",
  47: "Colombia",
  48: "Comoros",
  49: "Congo",
  50: "Cook Islands",
  51: "Costa Rica",
  52: 'Cote D"Ivoire',
  53: "Croatia",
  54: "Cuba",
  55: "Cyprus",
  56: "Czech Republic",
  57: "Denmark",
  58: "Djibouti",
  59: "Dominica",
  60: "Dominican Republic",
  61: "East Timor",
  62: "Ecuador",
  63: "Egypt",
  64: "El Salvador",
  65: "Equatorial Guinea",
  66: "Eritrea",
  67: "Estonia",
  68: "Ethiopia",
  69: "Falkland Islands Malvinas)",
  70: "Faroe Islands",
  71: "Fiji",
  72: "Finland",
  73: "France",
  74: "France,Metropolitan",
  75: "French Guiana",
  76: "French Polynesia",
  77: "French Southern Territories",
  78: "Gabon",
  79: "Gambia",
  80: "Georgia",
  81: "Germany",
  82: "Ghana",
  83: "Gibraltar",
  84: "Greece",
  85: "Greenland",
  86: "Grenada",
  87: "Guadeloupe",
  88: "Guam",
  89: "Guatemala",
  90: "Guinea",
  91: "Guinea-bissau",
  92: "Guyana",
  93: "Haiti",
  94: "Heard and Mc Donald Islands",
  95: "Honduras",
  96: "Hong Kong",
  97: "Hungary",
  98: "Iceland",
  99: "India",
  100: "Indonesia",
  101: "Iran Islamic Republic of)",
  102: "Iraq",
  103: "Ireland",
  104: "Israel",
  105: "Italy",
  106: "Jamaica",
  107: "Japan",
  108: "Jordan",
  109: "Kazakhstan",
  110: "Kenya",
  111: "Kiribati",
  112: "North Korea",
  113: "Korea,Republic of",
  114: "Kuwait",
  115: "Kyrgyzstan",
  116: 'Lao People"s Democratic Republic',
  117: "Latvia",
  118: "Lebanon",
  119: "Lesotho",
  120: "Liberia",
  121: "Libya",
  122: "Liechtenstein",
  123: "Lithuania",
  124: "Luxembourg",
  125: "Macau",
  126: "Macedonia",
  127: "Madagascar",
  128: "Malawi",
  129: "Malaysia",
  130: "Maldives",
  131: "Mali",
  132: "Malta",
  133: "Marshall Islands",
  134: "Martinique",
  135: "Mauritania",
  136: "Mauritius",
  137: "Mayotte",
  138: "Mexico",
  139: "Micronesia,Federated States of",
  140: "Moldova",
  141: "Monaco",
  142: "Mongolia",
  143: "Montserrat",
  144: "Morocco",
  145: "Mozambique",
  146: "Myanmar",
  147: "Namibia",
  148: "Nauru",
  149: "Nepal",
  150: "Netherlands",
  151: "Netherlands Antilles",
  152: "New Caledonia",
  153: "New Zealand",
  154: "Nicaragua",
  155: "Niger",
  156: "Nigeria",
  157: "Niue",
  158: "Norfolk Island",
  159: "Northern Mariana Islands",
  160: "Norway",
  161: "Oman",
  162: "Pakistan",
  163: "Palau",
  164: "Panama",
  165: "Papua New Guinea",
  166: "Paraguay",
  167: "Peru",
  168: "Philippines",
  169: "Pitcairn",
  170: "Poland",
  171: "Portugal",
  172: "Puerto Rico",
  173: "Qatar",
  174: "Reunion",
  175: "Romania",
  176: "Russian Federation",
  177: "Rwanda",
  178: "Saint Kitts and Nevis",
  179: "Saint Lucia",
  180: "Saint Vincent and the Grenadines",
  181: "Samoa",
  182: "San Marino",
  183: "Sao Tome and Principe",
  184: "Saudi Arabia",
  185: "Senegal",
  186: "Seychelles",
  187: "Sierra Leone",
  188: "Singapore",
  189: "Slovakia",
  190: "Slovenia",
  191: "Solomon Islands",
  192: "Somalia",
  193: "South Africa",
  194: "South Georgia &amp; South Sandwich Islands",
  195: "Spain",
  196: "Sri Lanka",
  197: "St. Helena",
  198: "St. Pierre and Miquelon",
  199: "Sudan",
  200: "Suriname",
  201: "Svalbard and Jan Mayen Islands",
  202: "Swaziland",
  203: "Sweden",
  204: "Switzerland",
  205: "Syrian Arab Republic",
  206: "Taiwan",
  207: "Tajikistan",
  208: "Tanzania,United Republic of",
  209: "Thailand",
  210: "Togo",
  211: "Tokelau",
  212: "Tonga",
  213: "Trinidad and Tobago",
  214: "Tunisia",
  215: "Turkey",
  216: "Turkmenistan",
  217: "Turks and Caicos Islands",
  218: "Tuvalu",
  219: "Uganda",
  220: "Ukraine",
  221: "United Arab Emirates",
  222: "United Kingdom",
  223: "United States",
  224: "United States Minor Outlying Islands",
  225: "Uruguay",
  226: "Uzbekistan",
  227: "Vanuatu",
  228: "Vatican City State Holy See)",
  229: "Venezuela",
  230: "Viet Nam",
  231: "Virgin Islands British)",
  232: "Virgin Islands U.S.)",
  233: "Wallis and Futuna Islands",
  234: "Western Sahara",
  235: "Yemen",
  236: "Serbia",
  237: "Democratic Republic of Congo",
  238: "Zambia",
  239: "Zimbabwe",
  240: "Channel Islands",
  241: "Canary Islands",
};

export const gr = {
  1: "Afghanistan",
  2: "Albania",
  3: "Algeria",
  4: "American Samoa",
  5: "Andorra",
  6: "Angola",
  7: "Anguilla",
  8: "Antarctica",
  9: "Antigua and Barbuda",
  10: "Argentina",
  11: "Armenia",
  12: "Aruba",
  13: "Australia",
  14: "Austria",
  15: "Azerbaijan",
  16: "Bahamas",
  17: "Bahrain",
  18: "Bangladesh",
  19: "Barbados",
  20: "Belarus",
  21: "Belgium",
  22: "Belize",
  23: "Benin",
  24: "Bermuda",
  25: "Bhutan",
  26: "Bolivia",
  27: "Bosnia and Herzegowina",
  28: "Botswana",
  29: "Bouvet Island",
  30: "Brazil",
  31: "British Indian Ocean Territory",
  32: "Brunei Darussalam",
  33: "Bulgaria",
  34: "Burkina Faso",
  35: "Burundi",
  36: "Cambodia",
  37: "Cameroon",
  38: "Canada",
  39: "Cape Verde",
  40: "Cayman Islands",
  41: "Central African Republic",
  42: "Chad",
  43: "Chile",
  44: "China",
  45: "Christmas Island",
  46: "Cocos Keeling) Islands",
  47: "Colombia",
  48: "Comoros",
  49: "Congo",
  50: "Cook Islands",
  51: "Costa Rica",
  52: 'Cote D"Ivoire',
  53: "Croatia",
  54: "Cuba",
  55: "Cyprus",
  56: "Czech Republic",
  57: "Denmark",
  58: "Djibouti",
  59: "Dominica",
  60: "Dominican Republic",
  61: "East Timor",
  62: "Ecuador",
  63: "Egypt",
  64: "El Salvador",
  65: "Equatorial Guinea",
  66: "Eritrea",
  67: "Estonia",
  68: "Ethiopia",
  69: "Falkland Islands Malvinas)",
  70: "Faroe Islands",
  71: "Fiji",
  72: "Finland",
  73: "France",
  74: "France,Metropolitan",
  75: "French Guiana",
  76: "French Polynesia",
  77: "French Southern Territories",
  78: "Gabon",
  79: "Gambia",
  80: "Georgia",
  81: "Germany",
  82: "Ghana",
  83: "Gibraltar",
  84: "Greece",
  85: "Greenland",
  86: "Grenada",
  87: "Guadeloupe",
  88: "Guam",
  89: "Guatemala",
  90: "Guinea",
  91: "Guinea-bissau",
  92: "Guyana",
  93: "Haiti",
  94: "Heard and Mc Donald Islands",
  95: "Honduras",
  96: "Hong Kong",
  97: "Hungary",
  98: "Iceland",
  99: "India",
  100: "Indonesia",
  101: "Iran Islamic Republic of)",
  102: "Iraq",
  103: "Ireland",
  104: "Israel",
  105: "Italy",
  106: "Jamaica",
  107: "Japan",
  108: "Jordan",
  109: "Kazakhstan",
  110: "Kenya",
  111: "Kiribati",
  112: "North Korea",
  113: "Korea,Republic of",
  114: "Kuwait",
  115: "Kyrgyzstan",
  116: 'Lao People"s Democratic Republic',
  117: "Latvia",
  118: "Lebanon",
  119: "Lesotho",
  120: "Liberia",
  121: "Libya",
  122: "Liechtenstein",
  123: "Lithuania",
  124: "Luxembourg",
  125: "Macau",
  126: "Macedonia",
  127: "Madagascar",
  128: "Malawi",
  129: "Malaysia",
  130: "Maldives",
  131: "Mali",
  132: "Malta",
  133: "Marshall Islands",
  134: "Martinique",
  135: "Mauritania",
  136: "Mauritius",
  137: "Mayotte",
  138: "Mexico",
  139: "Micronesia,Federated States of",
  140: "Moldova",
  141: "Monaco",
  142: "Mongolia",
  143: "Montserrat",
  144: "Morocco",
  145: "Mozambique",
  146: "Myanmar",
  147: "Namibia",
  148: "Nauru",
  149: "Nepal",
  150: "Netherlands",
  151: "Netherlands Antilles",
  152: "New Caledonia",
  153: "New Zealand",
  154: "Nicaragua",
  155: "Niger",
  156: "Nigeria",
  157: "Niue",
  158: "Norfolk Island",
  159: "Northern Mariana Islands",
  160: "Norway",
  161: "Oman",
  162: "Pakistan",
  163: "Palau",
  164: "Panama",
  165: "Papua New Guinea",
  166: "Paraguay",
  167: "Peru",
  168: "Philippines",
  169: "Pitcairn",
  170: "Poland",
  171: "Portugal",
  172: "Puerto Rico",
  173: "Qatar",
  174: "Reunion",
  175: "Romania",
  176: "Russian Federation",
  177: "Rwanda",
  178: "Saint Kitts and Nevis",
  179: "Saint Lucia",
  180: "Saint Vincent and the Grenadines",
  181: "Samoa",
  182: "San Marino",
  183: "Sao Tome and Principe",
  184: "Saudi Arabia",
  185: "Senegal",
  186: "Seychelles",
  187: "Sierra Leone",
  188: "Singapore",
  189: "Slovakia",
  190: "Slovenia",
  191: "Solomon Islands",
  192: "Somalia",
  193: "South Africa",
  194: "South Georgia &amp; South Sandwich Islands",
  195: "Spain",
  196: "Sri Lanka",
  197: "St. Helena",
  198: "St. Pierre and Miquelon",
  199: "Sudan",
  200: "Suriname",
  201: "Svalbard and Jan Mayen Islands",
  202: "Swaziland",
  203: "Sweden",
  204: "Switzerland",
  205: "Syrian Arab Republic",
  206: "Taiwan",
  207: "Tajikistan",
  208: "Tanzania,United Republic of",
  209: "Thailand",
  210: "Togo",
  211: "Tokelau",
  212: "Tonga",
  213: "Trinidad and Tobago",
  214: "Tunisia",
  215: "Turkey",
  216: "Turkmenistan",
  217: "Turks and Caicos Islands",
  218: "Tuvalu",
  219: "Uganda",
  220: "Ukraine",
  221: "United Arab Emirates",
  222: "United Kingdom",
  223: "United States",
  224: "United States Minor Outlying Islands",
  225: "Uruguay",
  226: "Uzbekistan",
  227: "Vanuatu",
  228: "Vatican City State Holy See)",
  229: "Venezuela",
  230: "Viet Nam",
  231: "Virgin Islands British)",
  232: "Virgin Islands U.S.)",
  233: "Wallis and Futuna Islands",
  234: "Western Sahara",
  235: "Yemen",
  236: "Serbia",
  237: "Democratic Republic of Congo",
  238: "Zambia",
  239: "Zimbabwe",
  240: "Channel Islands",
  241: "Canary Islands",
};
