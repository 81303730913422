import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Translate, withLocalize } from "react-localize-redux";
import CryptoAccountingBG from '../components/crypto-accounting/CryptoAccountingBG';
import CryptoAccountingEN from '../components/crypto-accounting/CryptoAccountingEN';
import CryptoAccountingRO from '../components/crypto-accounting/CryptoAccountingRO';
import CryptoAccountingGR from '../components/crypto-accounting/CryptoAccountingGR';

class CryptoAccounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: this.props.activeLanguage.code
    };
  }

  head() {
    return (
      <Helmet>
        <title>{`Крипто Счетоводство - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Крипто Счетоводство - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
      // this.executeJQuery();
    }
  }


  renderPage() {
    if (this.state.lang == 'bg') {
      return <CryptoAccountingBG />;
    } else if (this.state.lang == 'en') {
      return <CryptoAccountingEN />;
    } else if (this.state.lang == 'ro') {
      return <CryptoAccountingRO />;
    } else if (this.state.lang == 'gr') {
      return <CryptoAccountingGR />;
    } else {
      return <CryptoAccountingBG />;
    }
  }

  render() {
    return (
      <div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      {this.head()}
                      {this.renderPage()}
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default {
  component: withLocalize(CryptoAccounting)
};
