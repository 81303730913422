import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Translate } from "react-localize-redux";
import { fetchAsset } from "../../actions";
import AssetSellForm from "../../components/assets/AssetSellForm";
import AssetLightWidget from "../../components/assets/AssetLightWidget";

class AssetSellPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receivingMethod: "bank",
      txReceivingMethod: false
    };

    this.onReceivingMethodChange = this.onReceivingMethodChange.bind(this);
  }

  onReceivingMethodChange(receivingMethod) {
    this.setState({ receivingMethod });
  }

  setReceivingMethod(receivingMethod) {
    this.setState({ receivingMethod });
  }

  head() {
    return (
      <Helmet>
        <title>{`Продай ${this.props.asset.type ? this.props.asset.type + " " : ""
          } - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Продай ${this.props.asset.type ? this.props.asset.type + " " : ""
            } - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAsset(this.props.match.params.type);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.transaction)) {
      let txData = JSON.parse(props.transaction.data);
      this.setState({
        txReceivingMethod: txData.receivingMethod
      });
    }
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  <div className="row">
                    <div className="col-md-12">
                      <AssetSellForm
                        asset={this.props.asset}
                        action={this.onReceivingMethodChange}
                        receivingMethod={this.state.receivingMethod}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        className="white-box no-pad new-style-tabs"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="portlet-title tabbable-line">
                          <span>Select</span>
                          <ul className="nav nav-tabs" id="form-tabs">
                            {this.state.txReceivingMethod == "bank" ||
                              !this.state.txReceivingMethod ? (
                              <li
                                className={`${this.state.receivingMethod == "bank"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={this.setReceivingMethod.bind(this, "bank")}
                              >
                                <a href="#bank" data-toggle="tab">
                                  <img src="/img/bank.png" />
                                  <Translate id="bankTransfer" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {/* (this.state.txReceivingMethod == 'epay' || !this.state.txReceivingMethod) ? (
                            <li className={`${this.state.receivingMethod == 'epay' ? 'active' : ''}`} onClick={this.setReceivingMethod.bind(this, 'epay')}>
                                <a href="#epay" data-toggle="tab">
                                    <img src="/img/epay.png" />
                                    ePay
                                </a>
                            </li>
                            ) : '' */}
                            {(this.state.txReceivingMethod == "easypay" ||
                              !this.state.txReceivingMethod) &&
                              this.props.currency.type != "EUR" ? (
                              <li
                                className={`${this.state.receivingMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={this.setReceivingMethod.bind(
                                  this,
                                  "easypay"
                                )}
                              >
                                <a href="#easypay" data-toggle="tab">
                                  <img src="/img/easy-pay.png" />
                                  EasyPay
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                        <div className="portlet-body">
                          <div className="tab-content">
                            {this.state.txReceivingMethod == "bank" ||
                              !this.state.txReceivingMethod ? (
                              <div
                                className={`tab-pane fade in ${this.state.receivingMethod == "bank"
                                  ? "active"
                                  : ""
                                  }`}
                                id="bank"
                              >
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toRecieveBankTransfer" />{" "}
                                        <strong>
                                          <Translate id="bankAccount" />
                                        </strong>{" "}
                                        <Translate id="whenSelling" />{" "}
                                        <strong>{this.props.asset.type}</strong>,{" "}
                                        <Translate id="correctBank" />
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div className="col-lg-4">
                                      <div className="ref-number-box">
                                        <figure>
                                          <i className="far fa-university"></i>
                                        </figure>
                                        <h5>
                                          <Translate id="referenceNumber" />:
                                        </h5>
                                        <strong>
                                          {this.props.transaction.reference}
                                        </strong>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.asset.title}{" "}
                                      <Translate id="txFees2" /> 0.001{" "}
                                      {this.props.asset.type},{" "}
                                      <Translate id="txFees3" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="taxFor" />{" "}
                                      <strong>
                                        <Translate id="bank.bank" />
                                      </strong>{" "}
                                      <Translate id="taxFor2" />{" "}
                                      {this.props.asset.type}{" "}
                                      <Translate id="taxFor3" />{" "}
                                      {this.props.asset.type},{" "}
                                      <Translate id="taxFor4" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* (this.state.txReceivingMethod == 'epay' || !this.state.txReceivingMethod) ? (
                                <div className={`tab-pane fade in ${this.state.receivingMethod == 'epay' ? 'active' : ''}`} id="epay">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="payment-text">
                                                <p><Translate id="toRecieveMoney" /> <strong>{this.props.asset.type}</strong>, <Translate id="correctEpayData" /></p>
                                            </div>
                                        </div>
                                        {(!_.isEmpty(this.props.transaction)) ? (
                                            <div className="col-lg-4">
                                                <div className="ref-number-box">
                                                    <figure>
                                                        <p><img src="/img/epay_big.png" style={{width: '70px' }}/></p>
                                                    </figure>
                                                    <h5><Translate id="referenceNumber" />:</h5>
                                                    <strong>{this.props.transaction.reference}</strong>
                                                </div>
                                            </div>
                                        ) : ''}
                                        <div className="col-lg-12">
                                            <p><Translate id="thePriceIsIndicative" /> <Translate id="txFees1" /> {this.props.asset.title} <Translate id="txFees2" /> 0.001 {this.props.asset.type}, <Translate id="txFees3" /></p>
                                            <p><Translate id="moreInfoAfterAction" /></p>
                                            <p><Translate id="taxFor" /> <strong>ePay</strong> <Translate id="taxFor2" /> {this.props.asset.type} <Translate id="taxFor3" /> {this.props.asset.type}, <Translate id="taxFor4" /></p>
                                        </div>
                                    </div>
                                </div>
                                ) : '' */}
                            {(this.state.txReceivingMethod == "easypay" ||
                              !this.state.txReceivingMethod) &&
                              this.props.currency.type != "EUR" ? (
                              <div
                                className={`tab-pane fade in ${this.state.receivingMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                                id="easypay"
                              >
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toRecieveDesk" />{" "}
                                        <strong>EasyPay</strong>{" "}
                                        <Translate id="whenSelling" />{" "}
                                        <strong>{this.props.asset.type}</strong>,{" "}
                                        <Translate id="correctData" />
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div className="col-lg-4">
                                      <div className="ref-number-box">
                                        <figure>
                                          <p>
                                            <img
                                              src="/img/easypay_big.png"
                                              style={{ width: "60px" }}
                                            />
                                          </p>
                                        </figure>
                                        <h5>
                                          <Translate id="referenceNumber" />:
                                        </h5>
                                        <strong>
                                          {this.props.transaction.reference}
                                        </strong>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.asset.title}{" "}
                                      <Translate id="txFees2" /> 0.001{" "}
                                      {this.props.asset.type},{" "}
                                      <Translate id="txFees3" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="taxFor" />{" "}
                                      <strong>EasyPay</strong>{" "}
                                      <Translate id="taxFor2" />{" "}
                                      {this.props.asset.type}{" "}
                                      <Translate id="taxFor3" />{" "}
                                      {this.props.asset.type},{" "}
                                      <Translate id="taxFor4" />
                                    </p>
                                    <p>
                                      <Translate id="forActualEPTax" />{" "}
                                      <a
                                        href="https://www.easypay.bg/site"
                                        target="_blank"
                                      >
                                        <Translate id="here" />
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {_.map(this.props.rates, rate => {
                        if (rate.type == this.props.asset.type) {
                          return <AssetLightWidget {...rate} key={rate.id} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

function mapStateToProps({ asset, rates, transaction, currency }) {
  return { asset, rates, transaction, currency };
}

export default {
  component: connect(
    mapStateToProps,
    { fetchAsset }
  )(AssetSellPage)
};
