import _ from 'lodash';
import React, { useState } from 'react'

export const CarsFiltersExtended = () => {
  return (
    <form className="car-filter-box">
      <div className="row">
        <div className="col-6 col-lg-8">
          <a className="filter-btn" href="javascript:;" title="">
            <span></span>
            Filters
          </a>
          <div className="car-filter-form">
            <h5>Filters</h5>
            <a className="close-filter" href="javascript:;" title=""><i className="far fa-times"></i></a>
            <div className="filter-goup">
              <p>Type</p>
              <ul>
                <li>
                  <label for="new-car">
                    <input type="checkbox" id="new-car" name="new-car" value="" />
                    <span>New Cars</span>
                  </label>
                </li>
                <li>
                  <label for="used-car">
                    <input type="checkbox" id="used-car" name="used-car" value="" />
                    <span>Used Cars</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="filter-goup">
              <p>Model</p>
              <ul>
                <li>
                  <label for="bmw-i">
                    <input type="checkbox" id="bmw-i" name="bmw-i" value="" />
                    <span>BMW i</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-m">
                    <input type="checkbox" id="bmw-m" name="bmw-m" value="" />
                    <span>BMW M</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-x">
                    <input type="checkbox" id="bmw-x" name="bmw-x" value="" />
                    <span>BMW X</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-2">
                    <input type="checkbox" id="bmw-2" name="bmw-2" value="" />
                    <span>BMW 2</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-3">
                    <input type="checkbox" id="bmw-3" name="bmw-3" value="" />
                    <span>BMW 3</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-4">
                    <input type="checkbox" id="bmw-4" name="bmw-4" value="" />
                    <span>BMW 4</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-5">
                    <input type="checkbox" id="bmw-5" name="bmw-5" value="" />
                    <span>BMW 5</span>
                  </label>
                </li>
                <li>
                  <label for="bmw-6">
                    <input type="checkbox" id="bmw-6" name="bmw-6" value="" />
                    <span>BMW 6</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="filter-goup">
              <p>Engine</p>
              <ul>
                <li>
                  <label for="l4-40">
                    <input type="checkbox" id="l4-40" name="" l4-40 value="" />
                    <span>i4 40 (2)</span>
                  </label>
                </li>
                <li>
                  <label for="l6-50">
                    <input type="checkbox" id="l6-50" name="" l6-50 value="" />
                    <span>i6 50 (4)</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="filter-bottom">
              <div className="row">
                <div className="col-sm-6">
                  <input type="reset" value="Clear all" />
                </div>
                <div className="col-sm-6 text-right">
                  <button className="btn-link" type="submit">Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 text-right">
          <div className="select-filter-box">
            <span>Sort by</span>
            <div className="custom-select">
              <select>
                <option value="">Price</option>
                <option value="">Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}


