import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';

export const AutocompleteInput = ({ options, disabled, id, onSelect, placeholder, className }) => {
  const [value, setValue] = useState('');

  const fetchSuggestions = (query) => {
    if (query.length > 2) {
      let filteredSuggestions = options.filter((option) => option.name.toLowerCase().includes(query.toLowerCase()) || option.name_ru.toLowerCase().includes(query.toLowerCase()));
      if (filteredSuggestions.length > 10) {
        filteredSuggestions = filteredSuggestions.slice(0, 10);
      }
      return filteredSuggestions;
    }
    return [];
  }

  const boldString = (str, query) => {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str;
    }
    const l = q.length;
    return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
  }

  return (
    <Autocomplete
      wrapperStyle={{
        display: 'block'
      }}
      className="autocomplete"
      inputProps={{
        style: {
          height: "34px",
          borderRadius: "4px",
          border: '1px solid lightgray'
        },
        placeholder: placeholder,
        className: className,
        id: id,
        disabled: disabled
      }}
      value={value}
      onChange={(event, value) => setValue(value)}
      onSelect={(value, item) => {
        setValue(value)
        onSelect(id, item)
      }}
      getItemValue={(item) => item.name}
      items={fetchSuggestions(value)}
      menuStyle={{
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '50%',
        zIndex: 10
      }}
      renderItem={(item, isHighlighted) => (
        <div
          key={item.id}
          className={`suggestion ${isHighlighted ? 'highlighted' : ''}`}
          style={{ background: isHighlighted ? 'lightgray' : 'white' }}
        >
          <span dangerouslySetInnerHTML={{ __html: boldString(item.name, value) }} />
        </div>
      )}
    />
  );
};
