import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { forgotPass, clearAuthErrors } from '../../actions';
import { globalRegExes } from '../../utilities/RegEx';
import ReCAPTCHA from "react-google-recaptcha";

const FIELDS = {
    email: {
        type: 'email',
        label: 'email',
        required: true,
        errorMsg: 'emailIsInvalid',
        placeHolder: 'mail@website.com'
    }
};

const recaptchaRef = React.createRef();

class ForgotForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sending: false,
            sent: false,
            captcha: false
        };
    }

    async handleFormSubmit({ email }) {
        this.setState({ sending: true });
        await recaptchaRef.current.executeAsync();
        this.props.forgotPass({ email, captcha: this.state.captcha }).then((success) => {
            if (success) {
                this.setState({ sent: true });
            }
            this.setState({ sending: false });
        });
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;

        const className = `form-group form-field-wrapper ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input
                            className="form-control placeholder-no-fix login-field"
                            {...input}
                            type={type}
                            placeholder={field.placeHolder}
                        />
                        <label className="control-label visible-ie8 visible-ie9">
                            <Translate id={label} />
                        </label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{
                                color: '#ff615d'
                            }}>
                                <span><Translate id={`errors.${error}`} /></span>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }

    renderAlert() {
        if (this.props.errorMessage && this.props.errorMessage != '') {
            return (
                <div className="alert alert-danger">
                    <strong><Translate id="errors.emailIsInvalidForgotPass" /></strong> <Translate id="createNewProfile" /> <Link to="/signup"><Translate id="here" /></Link>.
                </div>
            );
        }
    }

    componentWillUnmount() {
        this.props.clearAuthErrors();
    }

    onReCAPTCHAChange(captchaCode) {
        if (!captchaCode) {
            return;
        }
        this.setState({ captcha: captchaCode });
        recaptchaRef.current.reset();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div id="forgot-form-container" className="forgot-form-container">
                {!this.state.sent ? (
                    <form
                        className="forgot-form default-forms"
                        id="forgot-form"
                        onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                    >
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LdsqJkpAAAAAGO3UsV4vJ_V5cGhq5amflTOrSvH"
                            onChange={this.onReCAPTCHAChange.bind(this)}
                        />
                        <div className="form-cont-header">
                            <h3 className="form-title"><Translate id="forgotPassword" /></h3>
                            <p><Translate id="insertEmailForgotData" /></p>
                        </div>
                        <div className="form-group-box">
                            {_.map(FIELDS, (field, fieldName) => {
                                return (
                                    <Field
                                        name={fieldName}
                                        component={this.renderField}
                                        key={fieldName}
                                        {...field}
                                    />
                                );
                            })}
                            {this.renderAlert()}
                            {(!this.state.sending) ? (
                                <div className="form-actions">
                                    <button type="submit" className="submit"><Translate id="send" /></button>
                                </div>
                            ) : ""}
                        </div>
                    </form>
                ) : (
                    <div className="text-center">
                        <h3><Translate id="success.forgotPassword" /></h3>
                    </div>
                )}
            </div>
        );
    }
}

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName]) {
            errors[fieldName] = field.errorMsg;
        } else {
            if (fieldName == 'email' && !formProps[fieldName].match(globalRegExes.email)) {
                errors[fieldName] = field.errorMsg;
            }
        }
    });

    return errors;
}

function mapStateToProps({ auth }) {
    return {
        auth,
        errorMessage: auth && auth.error ? auth.error : ''
    };
}

export default reduxForm({
    validate,
    form: 'forgot'
})(connect(mapStateToProps, { forgotPass, clearAuthErrors })(ForgotForm));

