import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUnseenMessages, sendCharityTransaction } from '../../actions';

import TransactionsTablePublicNew from '../transactions/TransactionsTablePublicNew';

const headingStyle = {
  margin: 0,
  color: '#000',
  'margin-top': '50px',
  fontWeight: 'bold'
};

function paragraphStyle(extraMargin = false) {
  return {
    marginTop: '20px',
    marginBottom: extraMargin ? '40px' : '0'
  };
}

function imageStyle(maxHeight, extraMarginLeft = 0, extraMarginTop = 0, extraMarginBottom = 0) {
  return {
    maxHeight,
    marginLeft: extraMarginLeft,
    marginTop: extraMarginTop,
    marginBottom: extraMarginBottom
  };
}

const rowSpacing = {
  marginTop: '120px'
};

const yellowButtonStyle = {
  backgroundColor: '#FFC107',
  color: '#000',
  borderRadius: '50px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '16px'
};

function OverlappingButtons(props) {
  const { yellowText, blueText, blueWidth = '325px' } = props;
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button className="charity-btn-yellow" style={yellowButtonStyle}>
        {yellowText}
      </button>
      <button className="charity-btn-blue"
        style={{
          backgroundColor: '#163973',
          color: '#fff',
          borderRadius: '50px',
          padding: '10px 20px',
          width: blueWidth,
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
          fontSize: '16px',
          position: 'absolute',
          top: 0,
          left: 'calc(100% - 15px)'
        }}
      >
        {blueText}
      </button>
    </div>
  );
}

function getBalanceFor(accounts, coinType) {
  if (!accounts || typeof accounts !== 'object') return 0;

  const arr = Object.values(accounts);
  const found = arr.find((acc) => acc.type === coinType);
  if (!found) return 0;

  return parseFloat(found.balance) || 0;
}

class CryptoPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '10',
      selectedTab: 'USDC'
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.copyAddress = this.copyAddress.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleSend() {
    if (!this.props.isLoggedIn) {
      alert("Trebuie să fii logat pentru a trimite o tranzacție.");
      return;
    }

    const userConfirmed = window.confirm("Ești sigur că dorești să faci o donație?");
    if (!userConfirmed) {
      return;
    }

    const payload = {
      accountType: this.state.selectedTab,
      amount: this.state.amount
    };

    console.log(this.props);
    console.log(payload);

    this.props.sendCharityTransaction(payload)
      .then((data) => {
        //alert(`Donația a fost trimisă cu succes: ${payload.amount} ${payload.accountType}`);
      })
      .catch((error) => {
        console.error("Eroare la trimiterea tranzacției:", error);
      });
  }

  copyAddress(addr) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(addr)
        .then(() => alert(`Adresă copiată: ${addr}`))
        .catch((err) => alert(`Copiere eșuată: ${err}`));
    } else {
      alert('API-ul clipboard nu este suportat de acest browser.');
    }
  }

  changeTab(tab) {
    const defaultAmounts = {
      USDC: '10',
      BTC: '0.0002',
      ETH: '0.005'
    };
    this.setState({
      selectedTab: tab,
      amount: defaultAmounts[tab]
    });
  }

  render() {
    const { selectedTab, amount } = this.state;
    const { isLoggedIn, balances } = this.props;

    const balance = balances[selectedTab.toLowerCase()] || 0;
    const addresses = {
      USDC: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c',
      BTC: '19Cgm6TGpkvSd7cAW1ZNKZXaZwjfprnrHx',
      ETH: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c'
    };

    const qrCodeSrc = `/img/${selectedTab.toLowerCase()}.png`;

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}>
        <div style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {['USDC', 'BTC', 'ETH'].map((coin) => (
              <button
                key={coin}
                onClick={() => this.changeTab(coin)}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                  margin: '0 5px',
                  backgroundColor: selectedTab === coin ? '#00b4f4' : '#ccc',
                  color: selectedTab === coin ? '#fff' : '#000'
                }}
              >
                {coin}
              </button>
            ))}
          </div>

          {isLoggedIn && (
            <div style={{ marginBottom: '20px' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                <b>Sold curent:</b> {balance.toFixed(4)} {selectedTab}
              </p>
            </div>
          )}

          {isLoggedIn && (
            <React.Fragment>
              <div>
                <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
                  Suma {selectedTab} de donat (Off-Chain):
                </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="number"
                    value={amount}
                    onChange={this.handleAmountChange}
                    style={{
                      flex: 1,
                      marginRight: '10px',
                      width: '100%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc'
                    }}
                    min="0"
                  />
                  <button
                    onClick={this.handleSend}
                    style={{
                      backgroundColor: '#00b4f4',
                      color: '#fff',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px'
                    }}
                  >
                    Trimite
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          <div style={{ marginTop: '15px' }}>
            <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
              Adresa {selectedTab} (On-Chain):
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={addresses[selectedTab]}
                readOnly
                style={{
                  flex: 1,
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '10px'
                }}
              />
              <button
                onClick={() => this.copyAddress(addresses[selectedTab])}
                style={{
                  backgroundColor: '#00b4f4',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '10px',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Copiază
              </button>
            </div>
          </div>
        </div>
        <div style={{
          marginLeft: '20px',
          display: 'flex',
          width: '300px',
          flexDirection: 'column',
          alignItems: 'center'
        }} className="charity-qr">
          <h5>{selectedTab} Cod QR</h5>
          <img
            src={qrCodeSrc}
            alt={`${selectedTab} Cod QR`}
            style={{
              width: '250px',
              border: '1px solid #ccc',
              objectFit: 'cover'
            }}
          />
        </div>
      </div>
    );
  }
}

class SunnyKidsRO extends Component {
  constructor(props) {
    super(props);
    this.scrollToDonateSection = this.scrollToDonateSection.bind(this);
  }

  scrollToDonateSection() {
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const autoScroll = queryParams.get("scrollToDonate");

    if (autoScroll === "true") {
      this.scrollToDonateSection();
    }
  }
  render() {
    const isLoggedIn = this.props.auth && this.props.auth.authenticated;
    const { accounts } = this.props;
    const balances = {
      usdc: getBalanceFor(accounts, 'USDC'),
      btc: getBalanceFor(accounts, 'BTC'),
      eth: getBalanceFor(accounts, 'ETH')
    };
    return (
      <div className="intro-text">
        <div className="article-full">
          <div className="transcard">
            <div className="">
              <div className="">
                <div className="row align-items-center text-left my-5 my-section">
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      BONEX pentru Fundația <br />
                      „Copiii Soarelui 2024”
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle()}
                    >
                      <b>Ajutați un copil</b> cu sindrom Down.<br />
                      <b>Primiți o reducere</b> de la următorul nostru comision.
                    </p>
                    <div className="text-left">
                      <br />
                      <button
                        className="btn-link"
                        onClick={this.scrollToDonateSection}
                        style={{ color: '#000', backgroundColor: '#ffc543', fontWeight: 500 }}
                      >
                        Donează crypto
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-7 text-center text-md-right">
                    <img
                      src="/img/width_1194.webp"
                      alt="Prima secțiune"
                      className="img-fluid charity-img-fluid"
                      style={imageStyle('300px')}
                    />
                  </div>
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-9">
                    <h2 className="section-title text-left" style={headingStyle}>
                      BONEX Donează Crypto și Primește Reducere
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle(true)}
                    >
                      Cu sprijinul tău, poți schimba viața unui copil aflat în nevoie.
                    </p>

                    <div>
                      La BoneX credem că gesturile mici pot schimba lumea. Cu fundația <b>"Copiii Soarelui 2024"</b> ne propunem să inspirăm mai mulți oameni să își deschidă inimile și să sprijine copiii aflați în dificultate.<br /><br /> 
                      Pentru a încuraja bunătatea ta, BoneX va oferi o reducere <b>între 30% și 100%</b> din comisionul următoarei tale tranzacții, atunci când faci o donație de cel puțin 10 USDC.<br /><br />
                      <b>De ce facem asta?</b><br />
                      Scopul nostru este clar – să stimulăm cât mai mulți oameni să doneze. Credem că, pe măsură ce folosești platforma noastră, nu doar că vei beneficia de condițiile avantajoase, dar vei deveni parte a unei cauze mai mari, care schimbă vieți.<br /><br />
                      <b>Donează. Inspiră. Schimbă.</b><br /> 
                      BoneX este aici pentru a susține bunătatea ta și pentru a construi împreună o lume plină de lumină și speranță.
                    </div>

                    <div className="text-left" style={{ marginTop: '15px'  }}>
                      <OverlappingButtons
                        yellowText="10 până la 99 USDC"
                        blueText="-30% de la următoarea tranzacție"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px' }}>
                      <OverlappingButtons
                        yellowText="100 până la 999 USDC"
                        blueText="-50% de la următoarea tranzacție"
                        blueWidth="325px"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px'  }}>
                      <OverlappingButtons
                        yellowText="1000 USDC și peste"
                        blueText="-100% de la următoarea tranzacție"
                        blueWidth="325px"
                      />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                      Sumele echivalente în ETH și BTC primesc, de asemenea, reducere.<br /><br />
                      <span style={{ display: "none" }}>
                        Donațiile vor rămâne deschise și după 31 ianuarie 2025, iar pentru ele vom continua să oferim reduceri speciale în funcție de valoarea donației – deoarece faptele bune merită sprijin oricând.
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-7 text-center text-md-right"
                    style={{ marginTop: '80px' }}
                  >
                    <img
                      src="/img/w6.png"
                      alt="Prima secțiune"
                      className="img-fluid charity-img-w6 charity-img-w6a"
                      style={imageStyle('200px', 30, 165, 25)}
                    />
                    <br />
                    <div className="text-center" style={{ width: '300px', float: 'right' }}>
                      Scopul nostru este să creăm cel mai mare centru din Balcani pentru copiii cu sindrom Down și alte probleme de sănătate.
                    </div>
                  </div>
                  <div className="col-12 col-md-2" />
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-5">
                    <div id="donate"></div>
                    <h2 className="section-title text-left" style={headingStyle}>
                      Donează Crypto
                    </h2>

                    <p className="section-description text-left">
                      <CryptoPayment
                        isLoggedIn={isLoggedIn}
                        balances={balances}
                        account={this.props.account}
                        currency={this.props.currency}
                        sendCharityTransaction={this.props.sendCharityTransaction}
                      />
                    </p>
                  </div>
                </div>
                <div className="row align-items-center text-left my-5 my-section" style={{ marginTop: '120px' }}>
                  <div className="col-12 col-md-10" style={{ marginTop: '10px', 'font-size': "14px" }}>
                    Clarificări privind donațiile On-Chain și Off-Chain <br /><br />

                    Oferim două metode de donație – On-Chain și Off-Chain, pentru a asigura comoditate și eficiență pentru utilizatorii noștri. Iată cum funcționează:
                    <br /><br />

                    <b>1. Donație On-Chain</b><br />
                    La donațiile On-Chain, criptomoneda este trimisă direct către adresa publică a fundației. Aceste tranzacții sunt înregistrate în blockchain și pot fi verificate de orice utilizator, fiind necesară plata unor taxe de rețea.
                    <br /><br />
                    <b>2. Donație Off-Chain</b><br />
                    Donațiile Off-Chain utilizează balanța internă a platformei. Aceasta este o metodă mai rapidă și convenabilă, care evită taxele de rețea. Tranzacțiile sunt procesate intern în BoneX și sunt complet trasabile pe platformă. Clienții cu balanță internă primesc, de asemenea, o reducere de 30% până la 100% din comisionul pentru următoarea tranzacție.
                    <br /><br />
                    Ambele metode sunt fiabile și transparente în cadrul sistemelor respective – blockchain-ul pentru donațiile On-Chain și platforma BoneX pentru donațiile Off-Chain. Alegeți metoda care se potrivește cel mai bine nevoilor dumneavoastră.
                  </div>
                </div>
                <h2 className="section-title text-left" style={headingStyle}>
                  Istoria donațiilor
                </h2>

                <div style={{ marginTop: '40px' }}>
                  <TransactionsTablePublicNew />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    accounts: state.accounts,
    account: state.account,
    currency: state.currency,
    unseenCount: state.messages.unseenCount,
  };
}

export default connect(
  mapStateToProps,
  { sendCharityTransaction, fetchUnseenMessages }
)(SunnyKidsRO);
