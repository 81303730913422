import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

const AccountActionsNew = (props) => {
    if (!_.isEmpty(props.account)) {
        return (
            <div
                className="wallet-action-buttons-wrapper"
            >
                {(props.account.type == "USDT" && props.currency.type == "USDT") ? "" : (
                    <div className="wallet-action-button">
                        {!props.trade_disabled && (
                            <Link to={`/accounts/${props.account.type}/buy`} title="" className="btn"><Translate id="buy" /></Link>
                        )}
                    </div>
                )}
                {(props.account.type == "USDT" && props.currency.type == "USDT") ? "" : (
                    <div className="wallet-action-button">
                        {!props.trade_disabled && (
                            <Link to={`/accounts/${props.account.type}/sell`} title="" className="btn"><Translate id="sell" /></Link>
                        )}
                    </div>
                )}
                {!props.trade_disabled && (
                    <div className="wallet-action-button">
                        <Link to={`/accounts/${props.account.type}/deposit`} title="" className="btn"><Translate id="deposit" /></Link>
                    </div>
                )}
                <div className="wallet-action-button">
                    <Link to={`/accounts/${props.account.type}/withdraw`} title="" className="btn"><Translate id="withdraw" /></Link>
                </div>

                {(props.account.type == "USDT" && props.currency.type == "USDT") ? (
                    <React.Fragment>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                    </React.Fragment>
                ) : ""}
            </div >
        );
    } else {
        return <div />
    }
};

export default AccountActionsNew;
