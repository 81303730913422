import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import { fetchUser, setGamesAction } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';

class GiveawayCancelPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      send: false
    };
  }

  async setGameAction() {
    let result = await this.props.setGamesAction(1);
    if (result.success) {
      this.setState({ send: true });
    }
  }

  head() {
    return (
      <Helmet>
        <title>{`Giveaway Unsubscribe - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Giveaway Unsubscribe - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    if (this.state.send) {
      return (
        <div>
          {this.head()}
          <div className="intro-box">
            <div className="card-cover card-cover-2 mining-cover mt-0">
              <img className="w-100" src="/img/messages/message-1-cover.png" alt="" />
            </div>
            <div className="article-full">
              <div className="transcard text-center">
                <h2 className="">
                  <span>
                    Рожденикът черпи с 25 USDT всеки месец
                  </span>
                </h2>
                <p>
                  Успешно се отписа от участие в Играта, в която в продължение на 4 месеца BoneX ще раздава всеки месец по 25 USDT на 50 случайно избрани потребители.
                  <hr />
                  You successfully unsubscribed yourself from participation in the Game in which throughout 4 months BoneX will be giving away 25 USDT each month to 50 randomly selected users.
                  <hr />
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.head()}
          <div className="intro-box">
            <div className="card-cover card-cover-2 mining-cover">
              <img className="w-100" src="/img/messages/message-1-cover.png" alt="" />
            </div>
            <div className="article-full">
              <div className="transcard text-center">
                <h2 className="">
                  <span>
                    Рожденикът черпи с 25 USDT всеки месец
                  </span>
                </h2>
                <p>
                  Моля, потвърди, че желаеш да се отпишеш от участие в Играта на BoneX за награда от 25USDT.
                  <hr />
                  Please, confirm that you wish to unsubscribe from participation in BoneX game for a prize of 25USDT.
                  <hr />
                </p>
                <div className="mb-more">
                  <button className="link-btn btn-small mr-2 ml-2" onClick={() => this.setGameAction()}>Да, отписвам се / Yes, I am unsubscribing</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }


  }
};


function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default {
  component: connect(mapStateToProps, { setGamesAction })(
    requireAuth(GiveawayCancelPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
