import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import NewsList from '../../components/news/NewsList';
import { fetchNews } from '../../actions';

class NewsListPage extends Component {

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    head() {
        return (
            <Helmet>
                <title>{`Новини - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Новини - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {this.head()}
                        <div className="intro-box">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="intro-text">
                                        <h2><span><Translate id="news" /></span></h2>
                                        <div className="text">
                                            <NewsList news={this.props.news} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ news }) {
    return { news };
}

export default {
    component: connect(mapStateToProps, { fetchNews })(
        NewsListPage
    ),
    loadData: ({ dispatch }) => dispatch(fetchNews())
}
