import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class AboutUsRO extends Component {
  render() {
    return (
      <div className="intro-box">
        <div className="card-cover card-cover-2">
          <img src="/img/covers/About.PNG" alt="" />
          <h1 style={{ marginTop: '20px' }}><Translate id="weAreBonex" /></h1>
          <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center">Despre noi</h2>
            <p>Susținut de investitori de încredere și folosit de sute de clienți din Bulgaria, <b>BoneX</b> este un portofel digital inovator care oferă o modalitate ușoară, rapidă și sigură de a cumpăra și vinde criptomonede precum Bitcoin, Ethereum și Bitcoin Cash la cele mai mici prețuri de pe piață.</p>
            <p>Echipa de profesioniști din spatele <b>BoneX </b>se angajează să ofere o tranzacționare sigură și securizată a monedei digitale, fiind pe deplin conștienți de faptul că vă puneți banii în mâinile lor.</p>
            <h3 className="mb-more">Principalele avantaje ale BoneX față de alte platforme de schimb criptografic sunt:</h3>
            <div className="row">
              <div className="col-12 col-lg-10 col-lg-offset-1">
                <div className="benefits-list no-margin-sides">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-user-alt"></i>
                          <span></span>
                        </div>
                        <h3>Creare rapidă și ușoară de cont</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-sync"></i>
                          <span></span>
                        </div>
                        <h3>Tranzacții securizate<br /> (protecție SSL și Cloudfare, cod sursă și bază de date criptate)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-coins"></i>
                          <span></span>
                        </div>
                        <h3>Cele mai competitive comisioane de tranzacționare</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="far fa-magic"></i>
                          <span></span>
                        </div>
                        <h3>Serviciu 24/7 (procesarea automată a fiecărei tranzacții)</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-phone-laptop"></i>
                          <span></span>
                        </div>
                        <h3>Interfață potrivită pentru începători</h3>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="benefit-entry-box">
                        <div className="figure">
                          <i className="fas fa-headset"></i>
                          <span></span>
                        </div>
                        <h3>Suport excelent pentru utilizatori</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transcard">
              <h2 className="text-center mt-0">Cu <strong>BoneX</strong>, fiecare utilizator are posibilitatea de a intra în lumea criptomonedelor într-un mod extrem de simplu, rapid, accesibil și convenabil.</h2>
              <p>de a monitoriza în timp real mișcarea și tendințele prețurilor acestora, precum și de a profita de instrumentele oferite de platformă pentru a construi analize fundamentale și tehnice valoroase pentru fiecare investitor.</p>
              <p>Creați-vă contul <strong>BoneX</strong> în câteva secunde și deveniți o parte din viitorul blockchain-urilor. </p>
              <figure>
                <img alt="" src="/img/Partners.PNG" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AboutUsRO;
