import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import AssetRates from "../assets/AssetRates";
import Loading from "../Loading";
import {
  fetchTransaction,
  sendGuestTransaction,
  clearTransaction
} from "../../actions";
import { formatMoney, formatCrypto } from "../../utilities/Format";
import { amountRegExes, globalRegExes } from "../../utilities/RegEx";
import { validateIban } from "../../utilities/Validation";

const paymentMethods = {
  address: "address"
};

const receivingMethods = {
  bank: "bank",
  //epay: 'epay',
  easypay: "easypay"
};

const receivingMethodsFees = {
  bank: 0,
  //epay: 0,
  easypay: [
    ["0.00", "5.00", "0.11"],
    ["5.01", "10.00", "0.17"],
    ["10.01", "15.00", "0.22"],
    ["15.01", "20.00", "0.28"],
    ["20.01", "25.00", "0.33"],
    ["25.01", "30.00", "0.39"],
    ["30.01", "35.00", "0.44"],
    ["35.01", "40.00", "0.50"],
    ["40.01", "45.00", "0.55"],
    ["45.01", "50.00", "0.61"],
    ["50.01", "55.00", "0.66"],
    ["55.01", "60.00", "0.72"],
    ["60.01", "65.00", "0.77"],
    ["65.01", "70.00", "0.83"],
    ["70.01", "75.00", "0.88"],
    ["75.01", "80.00", "0.94"],
    ["80.01", "85.00", "0.99"],
    ["85.01", "90.00", "1.05"],
    ["90.01", "95.00", "1.07"],
    ["95.01", "100.00", "1.10"],
    ["100.01", "110.00", "1.16"],
    ["110.01", "120.00", "1.27"],
    ["120.01", "130.00", "1.43"],
    ["130.01", "140.00", "1.54"],
    ["140.01", "150.00", "1.62"],
    ["150.01", "160.00", "1.76"],
    ["160.01", "170.00", "1.87"],
    ["170.01", "180.00", "1.95"],
    ["180.01", "190.00", "2.06"],
    ["190.01", "200.00", "2.17"],
    ["200.01", "210.00", "2.28"],
    ["210.01", "220.00", "2.39"],
    ["220.01", "230.00", "2.50"],
    ["230.01", "240.00", "2.61"],
    ["240.01", "250.00", "2.72"],
    ["250.01", "260.00", "2.83"],
    ["260.01", "270.00", "2.94"],
    ["270.01", "280.00", "3.05"],
    ["280.01", "290.00", "3.16"],
    ["290.01", "300.00", "3.27"],
    ["300.01", "350.00", "3.44"],
    ["350.01", "400.00", "3.55"],
    ["400.01", "450.00", "3.66"],
    ["450.01", "500.00", "3.77"],
    ["500.01", "550.00", "3.85"],
    ["550.01", "600.00", "3.96"],
    ["600.01", "650.00", "4.07"],
    ["650.01", "700.00", "4.18"],
    ["700.01", "750.00", "4.29"],
    ["750.01", "800.00", "4.40"],
    ["800.01", "850.00", "4.51"],
    ["850.01", "900.00", "4.62"],
    ["900.01", "950.00", "4.73"],
    ["950.01", "1000.00", "4.84"],
    ["1000.01", "1100.00", "5.06"],
    ["1100.01", "1200.00", "5.17"],
    ["1200.01", "1300.00", "5.34"],
    ["1300.01", "1400.00", "5.50"],
    ["1400.01", "1500.00", "5.67"],
    ["1500.01", "1600.00", "5.83"],
    ["1600.01", "1700.00", "6.00"],
    ["1700.01", "1800.00", "6.16"],
    ["1800.01", "1900.00", "6.33"],
    ["1900.01", "2000.00", "6.49"],
    ["2000.01", "2100.00", "6.66"],
    ["2100.01", "2200.00", "6.88"],
    ["2200.01", "2300.00", "7.10"],
    ["2300.01", "2400.00", "7.32"],
    ["2400.01", "2500.00", "7.54"],
    ["2500.01", "2600.00", "7.76"],
    ["2600.01", "2700.00", "7.98"],
    ["2700.01", "2800.00", "8.20"],
    ["2800.01", "2900.00", "8.42"],
    ["2900.01", "3000.00", "8.64"],
    ["3000.01", "3500.00", "9.57"],
    ["3500.01", "4000.00", "10.95"],
    ["4000.01", "4500.00", "12.32"],
    ["4500.01", "5000.00", "13.70"]
  ]
};

const receivingMethodsIcons = {
  bank: "/img/bank.png",
  //epay: '/img/epay.png',
  easypay: "/img/easy-pay.png"
};

class AssetSellForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0.0,
      price: 0.0,
      fees: 0.0,
      total: 0.0,
      rate: 0.0,
      paymentMethod: "address",
      receivingMethod: "bank",
      receiver: "",
      email: "",
      bank_holder: "",
      bank_iban: "",
      epay_kin: "",
      easypay_names: "",
      easypay_egn: "",
      easypay_phone: "",
      formValid: false,
      formErrors: {
        amount: "",
        price: "",
        paymentMethod: "",
        receiver: "",
        receivingMethod: "",
        receivingInfo: "",
        bankHolder: "",
        bankIban: "",
        epayKin: "",
        easyPayNames: "",
        easyPayEgn: "",
        easyPayPhone: "",
        email: ""
      },
      amountValid: false,
      priceValid: false,
      paymentMethodValid: true,
      receiverValid: true,
      receivingMethodValid: true,
      receivingInfoValid: false,
      bankHolderValid: false,
      bankIbanValid: false,
      epayKinValid: false,
      easyPayNamesValid: false,
      easyPayEgnValid: false,
      easyPayPhoneValid: false,
      emailValid: false,
      touched: false,
      copied: false
    };

    this.onCalculatorChange = this.onCalculatorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  onCalculatorChange(amount, price, field, rate) {
    this.setState({ amount, price, rate }, async () => {
      return this.validateField(
        field,
        field === "amount" ? amount : price
      ).then(resp => {
        return this.validateField(
          field === "amount" ? "price" : "amount",
          field === "amount" ? price : amount
        ).then(resp => {
          this.recalculateTotal();
        });
      });
    });
  }

  onPaymentChange(event) {
    this.setState({ paymentMethod: event.target.value });
  }

  onReceivingMethodChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ receivingMethod: value }, () => {
      this.validateField(name, value).then(() => {
        this.validateField("price", this.state.price);
        this.recalculateTotal();
      });
    });
    this.callParentAction(value);
  }

  onFieldChange(event) {
    const { name, value } = event.target;
    let diff = {};
    diff[name] = value;
    this.setState(
      {
        ...diff
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  onSubmit(event) {
    event.preventDefault();
    this.props.sendGuestTransaction({
      ...this.state,
      account: this.props.asset,
      type: "SELL",
      currency: this.props.currency
    });
  }

  recalculateTotal() {
    let total = 0.0;
    let price = parseFloat(this.state.price);
    let fees = this.getReceivingMethodFees();

    total = price - fees;
    this.setState({
      fees,
      total
    });
  }

  getReceivingMethodFees() {
    if (this.state.receivingMethod == "easypay") {
      let price = parseFloat(this.state.price);
      let fees = 0;
      receivingMethodsFees.easypay.forEach(function (range) {
        if (parseFloat(range[0]) <= price && parseFloat(range[1]) >= price) {
          fees = parseFloat(range[2]);
          return fees;
        }
      });
      return fees;
    } else {
      return receivingMethodsFees[this.state.receivingMethod];
    }
  }

  callParentAction(value) {
    if (this.props.action) {
      this.props.action(value);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ receivingMethod: props.receivingMethod }, () => {
      this.validateField("receivingMethod", props.receivingMethod);
    });
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  validateField(fieldName, value) {
    return new Promise(resolve => {
      let formErrors = this.state.formErrors;
      let amountValid = this.state.amountValid;
      let priceValid = this.state.priceValid;
      let paymentMethodValid = this.state.paymentMethodValid;
      let receiverValid = this.state.receiverValid;
      let receivingMethodValid = this.state.receivingMethodValid;
      let emailValid = this.state.emailValid;
      let receivingInfoValid = this.state.receivingInfoValid;
      let bankHolderValid = this.state.bankHolderValid;
      let bankIbanValid = this.state.bankIbanValid;
      let epayKinValid = this.state.epayKinValid;
      let easyPayNamesValid = this.state.easyPayNamesValid;
      let easyPayEgnValid = this.state.easyPayEgnValid;
      let easyPayPhoneValid = this.state.easyPayPhoneValid;

      switch (fieldName) {
        case "amount":
          amountValid =
            parseFloat(value).toPrecision(9) != "0.00000000" &&
            value.match(amountRegExes.crypto);
          formErrors.amount = amountValid ? "" : "IsInvalid";
          break;
        case "price":
          formErrors.price =
            value && value.match(amountRegExes.fiat) ? "" : "IsInvalid";
          if (value * 1 < this.props.config.sell_min) {
            formErrors.price = "IsBelowMin";
          } else if (
            value * 1 > this.props.config.sell_max ||
            (this.state.receivingMethod == "easypay" && value * 1 > 5000)
          ) {
            formErrors.price = "IsAboveMax";
          }
          priceValid = !formErrors.price.length;
          break;
        case "paymentMethod":
          paymentMethodValid = paymentMethods[value].length > -1;
          formErrors.paymentMethod = paymentMethodValid ? "" : "IsInvalid";
          break;
        case "receivingMethod":
          receivingMethodValid = receivingMethods[value].length > -1;
          formErrors.receivingMethod = receivingMethodValid ? "" : "IsInvalid";
          break;
        case "bank_holder":
          bankHolderValid = value != "";
          formErrors.bankHolder = bankHolderValid ? "" : "IsInvalid";
          break;
        case "bank_iban":
          bankIbanValid = value != "";
          bankIbanValid = validateIban(value);
          formErrors.bankIban = bankIbanValid ? "" : "IsInvalid";
          break;
        case "epay_kin":
          epayKinValid = value != "";
          epayKinValid = value.match(globalRegExes.epay_kin);
          formErrors.epayKin = epayKinValid ? "" : "IsInvalid";
          break;
        case "easypay_names":
          easyPayNamesValid = value != "";
          easyPayNamesValid = value.trim().split(" ").length > 2;
          formErrors.easyPayNames = easyPayNamesValid ? "" : "IsInvalid";
          break;
        case "easypay_egn":
          easyPayEgnValid = value != "";
          formErrors.easyPayEgn = easyPayEgnValid ? "" : "IsInvalid";
          break;
        case "easypay_phone":
          easyPayPhoneValid = value != "";
          formErrors.easyPayPhone = easyPayPhoneValid ? "" : "IsInvalid";
          break;
        case "email":
          emailValid = value.match(globalRegExes.email);
          formErrors.email = emailValid ? "" : "IsInvalid";
          break;
        default:
          break;
      }

      if (
        this.state.receivingMethod == "bank" &&
        bankHolderValid &&
        bankIbanValid
      ) {
        receivingInfoValid = true;
      } else if (this.state.receivingMethod == "epay" && epayKinValid) {
        receivingInfoValid = true;
      } else if (
        this.state.receivingMethod == "easypay" &&
        easyPayNamesValid &&
        easyPayEgnValid &&
        easyPayPhoneValid
      ) {
        receivingInfoValid = true;
      } else {
        receivingInfoValid = false;
      }

      return this.setState(
        {
          formErrors,
          amountValid,
          priceValid,
          paymentMethodValid,
          receiverValid,
          receivingMethodValid,
          receivingInfoValid,
          bankHolderValid,
          bankIbanValid,
          epayKinValid,
          easyPayNamesValid,
          easyPayEgnValid,
          easyPayPhoneValid,
          emailValid,
          formValid:
            amountValid &&
            priceValid &&
            paymentMethodValid &&
            receiverValid &&
            receivingMethodValid &&
            receivingInfoValid &&
            emailValid
        },
        () => {
          return resolve(true);
        }
      );
    });
  }

  copyToClipboard(e) {
    this.cryptoAddress.select();
    document.execCommand("copy");
    this.setState({
      copied: true
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.type != this.props.currency.type) {
      if (this.state.receivingMethod == "easypay") {
        const receivingMethod = "bank";
        this.setState({ receivingMethod }, () => {
          this.validateField("receivingMethod", receivingMethod).then(() => {
            this.recalculateTotal();
          });
        });
        this.callParentAction(receivingMethod);
      }
    }
  }

  additionalFields() {
    if (this.state.receivingMethod == "bank") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content">
              <div className="form-info-box sm-margin">
                <strong>
                  <Translate id="bankData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.bankHolderValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content">
                <label className="control-label visible-ie8 visible-ie9">
                  <Translate id="names" />
                </label>
                <input
                  name="bank_holder"
                  type="text"
                  className="form-control placeholder-no-fix"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.bank_holder}
                />
                {this.state.formErrors.bankHolder != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.bankHolder${this.state.formErrors.bankHolder}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.bankIbanValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content">
                <label className="control-label visible-ie8 visible-ie9">
                  IBAN
                </label>
                <input
                  name="bank_iban"
                  type="text"
                  className="form-control placeholder-no-fix"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.bank_iban}
                />
                {this.state.formErrors.bankIban != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.bankIban${this.state.formErrors.bankIban}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.receivingMethod == "easypay") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content">
              <div className="form-info-box sm-margin">
                <strong>
                  <Translate id="easypayData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayNamesValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content">
                <label className="control-label visible-ie8 visible-ie9">
                  <Translate id="threeNames" />
                </label>
                <input
                  name="easypay_names"
                  type="text"
                  className="form-control placeholder-no-fix"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_names}
                />
                {this.state.formErrors.easyPayNames != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.names${this.state.formErrors.easyPayNames}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayEgnValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content">
                <label className="control-label visible-ie8 visible-ie9">
                  <Translate id="egn" />
                </label>
                <input
                  name="easypay_egn"
                  type="text"
                  className="form-control placeholder-no-fix"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_egn}
                />
                {this.state.formErrors.easyPayEgn != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.egn${this.state.formErrors.easyPayEgn}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayPhoneValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content">
                <label className="control-label visible-ie8 visible-ie9">
                  <Translate id="phone" />
                </label>
                <input
                  name="easypay_phone"
                  type="text"
                  className="form-control placeholder-no-fix"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_phone}
                />

                {this.state.formErrors.easyPayPhone != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.phone${this.state.formErrors.easyPayPhone}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.receivingMethod == "epay") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content">
              <div className="form-info-box sm-margin">
                <strong>
                  <Translate id="epayData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.epayKinValid ? "has-error" : "has-success"
              }`}
          >
            <div className="label-animated has-content">
              <label className="control-label visible-ie8 visible-ie9">
                <Translate id="epayKIN" />
              </label>
              <input
                name="epay_kin"
                type="text"
                className="form-control placeholder-no-fix"
                autoComplete="off"
                onChange={this.onFieldChange.bind(this)}
                value={this.state.epay_kin}
              />
              {this.state.formErrors.epayKin != "" ? (
                <div
                  className="help-block with-errors"
                  style={{ marginLeft: "16px" }}
                >
                  <span>
                    <Translate
                      id={`errors.epayKin${this.state.formErrors.epayKin}`}
                    />
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (!_.isEmpty(this.props.transaction)) {
      if (this.state.receivingMethod == "bank") {
        return (
          <div className="infoFigureBox">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3>Продаване на {this.props.asset.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.asset.title}!
                  </h5>
                  <p>Моля, изпратете</p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p>на {this.props.asset.title} уолет адрес:</p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <p>
                    След като получим потвърждение за транзакцията, ще изпратим
                    съответната сума в{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} чрез
                  </p>
                  <span>Банков превод</span>
                  <p>на посочената банкова сметка:</p>
                  <p>
                    <span>
                      {JSON.parse(this.props.transaction.data).bank_holder}
                    </span>
                  </p>
                  <span>
                    {JSON.parse(this.props.transaction.data).bank_iban}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.receivingMethod == "epay") {
        return (
          <div className="infoFigureBox">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3>Продаване на {this.props.asset.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.asset.title}!
                  </h5>
                  <p>Моля, изпратете</p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p>на {this.props.asset.title} уолет адрес:</p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <p>
                    След като получим потвърждение за транзакцията, ще изпратим
                    съответната сума в{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} в
                  </p>
                  <span>еPay</span>
                  <p>на посочения КИН:</p>
                  <span>
                    {JSON.parse(this.props.transaction.data).epay_kin}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.receivingMethod == "easypay") {
        return (
          <div className="infoFigureBox">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3>Продаване на {this.props.asset.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.asset.title}!
                  </h5>
                  <p>Моля, изпратете</p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p>на {this.props.asset.title} уолет адрес:</p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <p>
                    След като получим потвърждение за транзакцията, ще можете да
                    получите съответната сума в{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} на
                    каса на
                  </p>
                  <span>EasyPay</span>
                  <p>като предоставите посоченото ЕГН:</p>
                  <span>
                    {JSON.parse(
                      this.props.transaction.data
                    ).easypay_egn.substring(
                      0,
                      JSON.parse(this.props.transaction.data).easypay_egn
                        .length - 4
                    )}
                    ****
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div />;
      }
    } else if (!_.isEmpty(this.props.asset)) {
      return (
        <div className="infoFigureBox">
          <div className="col-md-7">
            <form onSubmit={this.onSubmit}>
              <AssetRates
                account={this.props.asset}
                action={this.onCalculatorChange}
                diff={`${this.props.asset.type != "USDT"
                  ? this.props.config.sell_percent_guest
                  : 0
                  }`}
                sign="-"
                minTotal={this.props.config.sell_min_guest}
                maxTotal={
                  this.state.receivingMethod == "easypay"
                    ? 5000
                    : this.props.config.buy_max_guest
                }
              />
              <div className="col-md-12 form-group">
                <div className="form-info-box sm-margin">
                  <strong>
                    <Translate id="selectPaymentReceiveOption" />
                  </strong>
                </div>
                {_.map(receivingMethods, (title, method) => {
                  if (
                    (method == "easypay" && this.props.currency.type == "EUR") ||
                    (method == "easypay" && this.props.activeLanguage.code == 'ro')
                  ) {
                    return;
                  } else {
                    return (
                      <label className="custom-check-element" key={method}>
                        <input
                          name="receivingMethod"
                          type="radio"
                          onChange={this.onReceivingMethodChange.bind(this)}
                          value={method}
                          checked={this.state.receivingMethod === method}
                        />
                        <i></i>
                        <img src={receivingMethodsIcons[method]} />
                        <Translate id={`receivers.${title}`} />
                      </label>
                    );
                  }
                })}
                {this.state.formErrors.receivingMethod != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate id="errors.receivingMethodIsInvalid" />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.additionalFields()}
              <div
                className={`col-md-12 form-group ${!this.state.emailValid ? "has-error" : "has-success"
                  }`}
              >
                <div className="input-icon">
                  <div className="label-animated has-content">
                    <label className="control-label visible-ie8 visible-ie9">
                      E-mail
                    </label>
                    <input
                      type="text"
                      className="form-control placeholder-no-fix"
                      autoComplete="off"
                      onChange={this.onFieldChange.bind(this)}
                      value={this.state.email}
                      name="email"
                    />
                  </div>
                  {this.state.formErrors.email != "" ? (
                    <div
                      className="help-block with-errors"
                      style={{ marginLeft: "16px" }}
                    >
                      <span>
                        <Translate
                          id={`errors.email${this.state.formErrors.email}`}
                        />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="btn btn-action btn-rounded crypto-button crypto-buy"
                  disabled={!this.state.formValid}
                >
                  <Translate id="sell" /> <Translate id="for" />{" "}
                  {formatMoney(this.state.total)}{" "}
                  <Translate id={this.props.currency.symbol} />
                </button>
              </div>
              <div className="clearfix"></div>
            </form>
            <br />
          </div>
          <div className="col-md-5">
            <header className="element-section-title has-bgr-element text-center crypto-sell-color">
              <h3>
                <Translate id="you.sell" />
              </h3>
            </header>
            <div className="payment-info-box">
              <div className="payment-info-section">
                <figure>
                  <img
                    className="fig-orig"
                    src={`/img/assets/${this.props.asset.type.toLowerCase()}.svg`}
                    title=""
                  />
                  <img
                    className="fig-fade"
                    src={`/img/assets/${this.props.asset.type.toLowerCase()}.svg`}
                    title=""
                  />
                </figure>
                <strong>
                  {formatCrypto(this.state.amount)}{" "}
                  <span>{this.props.asset.type}</span>
                </strong>
                <p>
                  <Translate id="for" />{" "}
                  <span>
                    {formatMoney(this.state.total)}{" "}
                    <Translate id={this.props.currency.symbol} />
                  </span>
                </p>
                <p>
                  <Link to="/signup">
                    <Translate id="forRegisteredUsers" />{" "}
                    {(
                      parseFloat(this.state.total) +
                      (parseFloat(this.state.total) * 0.5) / 100
                    ).toFixed(2)}{" "}
                    <Translate id={this.props.currency.symbol} />
                  </Link>
                  <br />
                  <Link to="/signup">
                    <Translate id="registerHere" />
                  </Link>
                </p>
              </div>
              <div className="payment-info-section">
                <p>
                  <strong>
                    <Translate id="you.pay" /> <Translate id="through" />
                  </strong>{" "}
                  <em>
                    {this.props.asset.title}{" "}
                    <Translate id={`payments.${this.state.paymentMethod}`} />
                  </em>
                </p>
                <i
                  className={`fab fa-${this.props.asset.title.toLowerCase()}`}
                ></i>
              </div>
              <div className="payment-info-section">
                <p>
                  <strong>
                    <Translate id="you.get" /> <Translate id="in" />
                  </strong>{" "}
                  <em>
                    {" "}
                    <Translate id={`receivers.${this.state.receivingMethod}`} />
                  </em>
                </p>
                <img src={receivingMethodsIcons[this.state.receivingMethod]} />
              </div>
              {this.state.fees > 0 && (
                <div className="payment-info-section">
                  <p>
                    <strong>
                      <Translate id="taxFees" />
                    </strong>
                  </p>
                  <p>
                    {" "}
                    {formatMoney(this.state.fees)}{" "}
                    <Translate id={this.props.currency.symbol} />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ transaction, config, currency }) {
  return { transaction, config, currency };
}

export default connect(mapStateToProps, {
  fetchTransaction,
  sendGuestTransaction,
  clearTransaction
})(AssetSellForm);
