import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import PagesItem from '../../components/pages/PagesItem';

class GiveawayTermsPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Giveaway Terms - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Giveaway Terms - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.head()}
        <div className="intro-box">
          <div className="article-full">
            <div className="transcard">
              <h2 className="text-center">ОБЩИ УСЛОВИЯ ЗА УЧАСТИЕ В ИГРАТА<br/><strong>„Рожденикът черпи с 25 USDT всеки месец“</strong></h2>
              <br />
                <p><strong>РАЗДЕЛ 1. ОРГАНИЗАТОР НА ИГРАТА</strong></p>
                <p>1.1. Настоящите общи условия определят начина на провеждане на Играта „Рожденикът черпи с 25 USDT всеки месец“ (за краткост „Играта“) и уреждат взаимоотношенията на Организатора с физическите лица („Участници“), включили се в нея.</p>
                <p>1.2. Организатор на Играта е "БОНЕККС" ЕООД, с ЕИК 040448997, със седалище и адрес на управление: гр. Перник, п.к. 2300, ул. „Райко Даскалов” № 1, ет. 2, уебсайт: https://bonex.org/ (по-долу за краткост Организатор и/или BoneX);</p>
                <p><strong>РАЗДЕЛ 2. МЯСТО НА ПРОВЕЖДАНЕ НА ИГРАТА.</strong></p>
                <p>2.1. Играта се организира и провежда само на територията на Република България. Играта се организира и се провежда на страницата на Организатора на адрес https://bonex.org/.</p>
                <p>2.2. Официалните правила са достъпни на посочената интернет страница. Допълнителна информация може да бъде изискана и получена при запитване по имейл на адрес: support@bonex.org</p>
                <p><strong>РАЗДЕЛ 3. СРОК.</strong></p>
                <p>3.1. Играта се провежда в периода от 01.12.2022 г. до 31.03.2023 г., включително. </p>
                <p>3.2. Организаторът си запазва правото да удължава периода на провеждане на Играта, за което участниците ще бъдат уведомени своевременно чрез съобщение във вътрешната поща на всеки участник, достъпна през профила му в платформата на Организатора. </p>
                <p><strong>РАЗДЕЛ 4. ИЗИСКВАНИЯ ЗА УЧАСТИЕ В ИГРАТА.</strong></p>
                <p>4.1. В Играта не могат да участват лица, работещи на трудов или граждански договор за Организатора, както и членове на техните семейства (деца, родители и съпрузи). В Играта не могат да участват лица, работещи на трудов или граждански договор за подизпълнители на Организатора, пряко ангажирани с провеждане на Играта.</p>
                <p>4.2. Участник в Играта може да бъде всяко пълнолетно, дееспособно физическо лице с местоживеене в Република България, с изключение на лицата посочени в 4.1, което има регистриран профил в платформата на BoneX, който е бил верифициран и идентифициран.</p>
                <p>4.3. Участието в Играта не е обвързано с покупка на стока или услуга, предлагана от Организатора или трето лице.</p>
                <p>4.4. С факта на включването си в Играта Участниците декларират, че имат навършени 18 години и че имат право валидно и неограничено да извършват всички действия във връзка с участието си в нея.</p>
                <p>4.5. Не се допуска регистрация с фалшиви данни и посредством всякакви други методи, с цел увеличаване на шанса за спечелване на награда.</p>
                <p>4.6. Организаторът има право, без каквито и да било неблагоприятни последици за него, да изключи от участие в Играта, както и да не предостави награда на Участник, за който има данни, че е извършил злоупотреба с технически, програмни или други средства и устройства с цел неправомерен достъп до системи на Организатора и/или участие в Играта и спечелване на награда, и/или е действал недобросъвестно и/или по друг начин е нарушил настоящите Правила.</p>
                <p><strong>РАЗДЕЛ 5. УСЛОВИЯ ЗА УЧАСТИЕ В ИГРАТА. МЕХАНИЗЪМ НА ИГРАТА.</strong></p>
                <p>5.1. Участник в Играта може да бъде всяко лице, което отговаря на изискванията в настоящите правила и което в периода на провеждане на Играта отговаря на условията по чл. 4.2 по-горе. </p>
                <p>5.2. В Играта участват всички лица, които отговарят на условията на Раздел 4.</p>
                <p>5.3. Всеки месец през периода на провеждане на играта, указан в чл. 3.1, на случаен принцип съгласно чл. 7.2 по-долу ще бъдат определяни по 50 от регистрираните потребители, които ще имат възможност да получат по една награда.  </p>
                <p>5.4. Всеки от участниците, избран да получи награда, между първо и трето число на съответния месец, в който е бил избран, ще получи специален код на вътрешната си поща, достъпна през профила му в платформата на Организатора. </p>
                <p>5.5. Валидността на кодовете, предоставени съгласно чл. 5.4 по-горе, изтича в края на двадесет и четвъртия час на петия ден от съответния месец, в който са били предоставени. </p>
                <p>5.6. Наградата от 25USDT ще получи всеки участник, който е успял да активира получения от него код в срока на валидността му по чл. 5.5. по-горе, като активацията на кода става чрез въвеждането му на следния линк:  </p>
                <p><strong>РАЗДЕЛ 6. НАГРАДИ ОТ ИГРАТА.</strong></p>
                <p>6.1. Наградите, които ще бъдат раздадени в Играта са общо 200 броя, като всяка една награда представлява определена сума (25 единици) от определена виртуална валута (тетър), т.е. всяка една награда възлиза на 25 USDT.</p>
                <p>6.2. Наградите се осигуряват от Организатора.</p>
                <p>6.3. Не се допуска замяна на спечелена награда с паричната й равностойност във фиатна валута или замяната й с равностойността й в друга криптовалута.</p>
                <p><strong>РАЗДЕЛ 7. ОПРЕДЕЛЯНЕ НА ПОБЕДИТЕЛ.</strong></p>
                <p>7.1. След изтичане на срока по т. 3.1, в срок до пет дни се изчислява общият брой участници, които са спечелили награди от Играта. Награди, които не са получени поради пропуск на участник да активира получения от него код в срока му на валидност, остават нераздадени. </p>
                <p>7.2. Изборът на потребители, които получават код за участие в Играта за обявената награда от 25USDT, се извършва на случаен принцип, чрез томбола от две лица – представители, определени от Организатора. Лицата, които са изпълнили всички условия за участие и са спечелили съответната награда, се вписват в протокол, който се подписва от двамата представители.</p>
                <p>С цел избягване на всякакво съмнение, един участник има право да бъде определен за победител еднократно и да получи само една награда. </p>
                <p>7.3. Имената на Участниците, избрани за Победители съобразно условията на настоящия раздел се обявяват в срока по т.7.1. в сайта на Организатора.</p>
                <p>7.4. Освен обявяването на победителите по посочения в предходната точка начин, Организаторът се свързва и чрез съобщение във вътрешната поща, достъпна през профила в платформата на съответния участник. </p>
                <p><strong>РАЗДЕЛ 8. ПОЛУЧАВАНЕ НА НАГРАДИТЕ ОТ ИГРАТА.</strong></p>
                <p>8. Всеки победител получава в своя портфейл към профила си в платформата наградата от 25 USDT в срок от един работен ден, считано от деня, в който е бил активиран кодът съгласно т. 5.6 по-горе.</p>
                <p><strong>РАЗДЕЛ 9. ПРЕКРАТЯВАНЕ НА ИГРАТА.</strong></p>
                <p>9.1. Организаторът си запазва правото по всяко време и по собствена преценка да прекрати провеждането на Играта, в случай на невъзможност за провеждане на Играта поради причини, за които той не отговаря, като информира за това участниците чрез страницата по т.2.1. или чрез съобщение във вътрешната поща, достъпна през профила в платформата на съответния участник. В тези случаи на участниците не се дължи компенсация или неустойка за участие в Играта.</p>
                <p>9.2. Организаторът си запазва правото да допълва или променя настоящите Правила, като промените влизат в сила след публикуването им на страницата по т.2.1., където първоначално са били публикувани.</p>
                <p><strong>РАЗДЕЛ 10. ЛИЧНИ ДАННИ</strong></p>
                <p>10.1. Включвайки се в настоящата Игра, участниците предоставят доброволно и информирано съгласие личните им данни, предоставени при регистрация и определяне на победител, да бъдат обработвани (събирани и администрирани) от Организатора, както и негови подизпълнители за цели свързани с участието им в Играта (администриране на провеждането на Играта и осъществяване на комуникация с участниците).</p>
                <p>10.2. ОРГАНИЗАТОРЪТ обработва събраните лични данни на участниците съгласно политиката за поверителност, достъпна на следния линк: https://bonex.org/privacy.</p>
                <p>10.3. Всеки участник/победител носи отговорност в случай че е използвал чужди лични данни. В такъв случай, участникът носи наказателна, административно-наказателна и пълна гражданска (имуществена) отговорност за използването на лични данни, които не е имал право да използва. С участието си в Играта, участникът декларира, че предоставените от него лични данни са действително негови.</p>
                <p>10.4. С участието в Играта участниците се съгласяват, че в случай на спечелване на награда, техните имена биха могли да бъдат използвани от Организатора в аудио, видео, печатни и интернет материали, за което той не им дължи заплащане. </p>
                <p>10.5. Всеки участник/победител има право да подаде жалба пред Комисията за защита на личните данни на адрес гр. София 1592, бул. „Проф. Цветан Лазаров” №2 или по елекронен път чрез интернет страницата на Комисията за защита на личните данни, а именно https://www.cpdp.bg/.</p>
                <p><strong>РАЗДЕЛ 11. ОТГОВОРНОСТ</strong></p>
                <p>11.1. С участието си в играта участниците дават съгласието си да бъдат обвързани от настоящите Правила и да се съобразяват с тях, като спазват техните клаузи и регулации.</p>
                <p>11.2. Организаторът не носи отговорност за вреди, причинени на участниците при или във връзка с провеждането на Играта, или за грешки и/или недостатъци при провеждането й поради извънредни обстоятелства.</p>
                <p>11.3. Организаторът не носи отговорност за неизпълнение на условията и задълженията си по настоящите Правила или за грешки при провеждане на Играта поради форсмажорни обстоятелства по смисъла на българското законодателство и неизправности в обслужващата техника, които се дължат на действия или бездействия на трети лица, или не са възникнали по вина на Организатора.</p>
                <p>11.4. Организаторът не носи отговорност спрямо избран за победител участник, предоставил невалидни данни при регистриране на профил в платформата, или с който не може да бъде осъществен контакт или който не изпълни други приложими условия съгласно тези Правила.</p>
                <p>11.5. Организаторът не носи отговорност при печатна или друга грешка, допусната от участник в играта при предоставянето на личните данни при регистриране на профил в платформата, довели до невъзможност за свързване с участника.</p>
                <p><strong>РАЗДЕЛ 12. ОБЩИ РАЗПОРЕДБИ.</strong></p>
                <p>12.1. Настоящите Правила са достъпни на страницата на Организатора за периода на провеждане на Играта.</p>
                <p>12.2. Настоящите Правила съставляват пълните правила, уреждащи провеждането на Играта и участието на участниците в нея. За всички въпроси, неуредени изрично в тях, ще се прилагат правилата на действащото българско законодателство.</p>
                <p>12.3. Всички спорове между Организаторът и участниците, възникнали във връзка с Играта, ще се решават в дух на добра воля чрез преговори между тях, а в случай, че това се окаже невъзможно, ще бъдат отнасяни за разрешаване пред компетентния български съд.</p>
                <p>12.4. Настоящите Правила влизат в сила от момента на публикуването им и важат за целия период на провеждане на Играта и до окончателното уреждане на всички отношения, свързани с нея.</p>
                <br />
                <h2 className="text-center">GENERAL TERMS FOR PARTICIPATIN IN THE GAME<br/><strong>„The Birthday Person Treats with 25 USDT Each Month“</strong></h2>
                <br />
                <p><strong>SECTION 1. ORGANIZER НА THE GAME</strong></p>
                <p>1.1. These General Terms stipulate the manner of conducting the Game &bdquo;The Birthday Person Treats with 25 USDT Each Month&ldquo; (hereinafter &bdquo;The Game&ldquo;) and settle the relations of the Organizer with physical persons (&bdquo;Participants&ldquo;) who entered it.<br/>1.2. The Organizer of the Game is BONEXX EOOD, UIC: 205304240, registered and management address: the town of Pernik, zip code 2300, 1, Rayko Daskalov Str., fl. 2, website: <a data-fr-linked="true" href="https://bonex.org/">https://bonex.org/</a> (hereinafter Organizer and/or BoneX);</p>
                <p><strong>SECTION 2. PLACE OF CONDUCTING OF THE GAME.</strong></p>
                <p>2.1. The Game is organized and conducted only in the territory of the Republic of Bulgaria. The Game is organized and conducted on the page of the Organizer at URl: <a data-fr-linked="true" href="https://bonex.org/">https://bonex.org/</a>.<br />2.2. The official rules are accessible on the specified page. Additional information may be requested and received upon a request to e-mail: <a data-fr-linked="true" href="mailto:support@bonex.org">support@bonex.org</a></p>
                <p><strong>SECTION 3. TERM.</strong></p>
                <p>3.1. The Game is to be conducted in the period from 01.12.2022 until 31.03.2023, including.<br />3.2. The Organizer preserves the right to extend the period of conducting the Game of which the Participants will be notified in due time by a message in the internal mail of each participant accessible through their account in the platform of the Organizer.</p>
                <p><strong>SECTION 4. REQUIREMENTS FOR PARTICIPATION IN THE GAME.</strong></p>
                <p>4.1. No persons working under employment or freelance agreement for the Organizer or members of their families (children, parents and spouses) may participate in the Game. No persons working under employment or freelance agreement for subcontractors of the Organizer &nbsp;directly engaged in the conducting of the Game may participate in the Game.<br />4.2. Each adult, legally capable person with place of residence in the Republic of Bulgaria with the exception of the persons specified in 4.1 who has a registered account in the platform of BoneX that was verified and identified may participate in the Game.<br />4.3. The participation in the Game is not bound with a purchase of merchandise or service offered by the Organizer or a third party.<br />4.4. By the fact of their including in the Game, the Participants declare that they are aged 18 or more and that they have the right to validly and unlimitedly perform all actions regarding their participation in it.<br />4.5. No registration with false details and using any other methods is allowed aiming to increase the chance of winning a prize.<br />4.6. The Organizer has the right, without any unfavourable consequences for them, to exclude from participation in the Game and not to give a prize to a participant of whom there is evidence that hey committed misuse with technical, program or other means aiming to have unauthorized access to the systems of the Organizer and/or participation in the Game and winning a prize, and/or acted unconscientiously and/or breached these Rules in any other way.</p>
                <p><strong>SECTION 5. TERMS OF PARTICIPATION IN THE GAME. MECHANISM OF THE GAME.</strong></p>
                <p>5.1. Each person eligible for participation according to these Rules and who, within the period of conducting the Game complies with the requirements of art. 4.2. above may be a participant in the Game.<br />5.2. All persons complying with the conditions of Section 4 may participate in the Game.<br />5.3. Each month in the period of conducting of the Game, specified in art. 3.1, randomly according to art. 7.2 hereinafter, 50 registered users who will have the chance to receive one price will be nominated.<br />5.4. Each of the Participants chosen to receive a prize, between the first and third day of the relevant month in which they were chose will receive a special code at their internal mail accessible through their account in the platform of the Organizer.<br />5.5. The validity of the codes provided pursuant to art. 5.4 above ends at the end of the twenty-fourth hour on the fifth day of the relevant month in which they were provided.<br />5.6. The prize of 25USDT will be received by each participant who managed to activate code they received within the term of its validity under art. 5.5. above, and the activation is to be done by entering it at the following link: <a data-fr-linked="true" href="https://bonex.org/giveaway">https://bonex.org/giveaway</a></p>
                <p><strong>SECTION 6. PRIZES OF THE GAME.</strong></p>
                <p>6.1. The prizes to be awarded in the Game are a total number of 200 and each prize is a fixed amount (25 units) of a fixed virtual currency (tether), i.e., each prize is to the amount of 25 USDT.<br />6.2. The prizes are provided by the Organizer.<br />6.3. No exchange of the won prize with its equivalent in fiat currency or its exchange with its equivalent in another crypto currency is allowed.</p>
                <p><strong>SECTION 7. NOMINATION OF WINNER.</strong></p>
                <p>7.1. After the end of the term under item 3.1, within five days, the total number of Participants who won prizes from the Game is to be calculated. Prizes that were not received due to the participant&rsquo;s failure to activate the code they received within its term of validity remain unawarded.<br />7.2. The choice of users who receive a code for participation in the Game for the announced prize of 25USDT is done randomly through lottery by two persons &ndash; representative nominated by the Organizer. The persons who fulfilled all terms for participation and won the relevant prize are entered in a record to be signed by the two representatives.<br />For avoidance of doubt, one participant has the right to be nominated for winner once and to receive only one prize.<br />7.3. The names of the Participants chosen for Winners according to the terms of this section are announced within the term under item 7.1. in the site of the Organizer.<br />7.4. Besides the announcing of the winner in the manner specified in the previous item, the Organizer gets in contact also by the internal mail accessible through the account in the platform of the relevant participant.</p>
                <p><strong>SECTION 8. RECEIVING THE PRIZES FROM THE GAME.</strong></p>
                <p>8. Each winner receives in their wallet attached to their account in the platform the prize of 25 USDT within one working day as of the day on which the code was activate according to item 5.6 hereinabove.</p>
                <p><strong>SECTION 9. TERMINATION OF THE GAME.</strong></p>
                <p>9.1. The Organizer preserves their right at any time and at their discretion to terminate the conducting of the Game in case of impossibility to conduct the Game due to reasons they are not liable for and informs thereof the Participants through their page under item 2.1. or by a message in the internal mail accessible through the account in the platform of the relevant participation. In those cases, no compensation or damages shall be owed to the Participants for participation in the Game.<br />9.2. The Organizer preserves their right to supplement or amend these Rules and the changes come into effect after they are published on the page under item 2.1. where they were originally published.</p>
                <p><strong>SECTION 10. PERSONAL DATA</strong></p>
                <p>10.1. Entering this Game, the Participants provide voluntarily also an informed consent for their personal data provided in the course of registration and nomination of a winner, to be processed (collected and administrated) by the Organizer and their subcontractors for purposes related with their participation in the Game (administration of the conducting of the Game and communication with the Participants).<br />10.2. THE ORGANIZER processes the collected personal data of the Participants according to the privacy policy accessible at the following link: <a data-fr-linked="true" href="https://bonex.org/privacy">https://bonex.org/privacy</a>.<br />10.3. Each participant/winner is responsible where they used someone else&rsquo;s personal data. In such case, the participant bears criminal, administrative-penal and complete civil (liability) responsibility for using personal data they were not entitled to use. By their participation in the Game, the participant declares that the personal data provide by them are actually theirs.<br />10.4. С the participation in the Game, the Participants agree that in case of winning a prize, their names could be used by the Organizer in audio, video, printed or internet materials of which they shall not owe them any payment.<br />10.5. Each participant/winner has the right to file a complaint before the Commission for Personal Data Protection at address: the city of Sofia 1592, 2. Prof. Tsvetan Lazarov Blvd. or electronically through the internet site of the Commission for Personal Data Protection, exactly: <a data-fr-linked="true" href="https://www.cpdp.bg/">https://www.cpdp.bg/</a>.</p>
                <p><strong>SECTION 11. RESPONSIBILITY</strong></p>
                <p>11.1. By their participation in the Game, the Participants give their consent to be bound by these Rules and to adhere to them, observing their clauses and regulations.<br />11.2. The Organizer is not responsible for damages caused to the Participants during or in reference to conducting the Game, or for errors and/or faults in the course of conducting it due to extraordinary circumstances.<br />11.3. The Organizer is not responsible for failure to fulfil their terms and obligations under these Rules or for faults in the course of conducting the Game due to force majeure circumstance according to the meaning of Bulgarian legislation, and breakdowns of the servicing equipment that are due to actions or inaction of third parties or did not arise due to the Organizer&rsquo;s fault.<br />11.4. The Organizer is not responsible to a participant chosen for winner who provided invalid data for registration of an account in the platform or who cannot be contacted, or who fails to execute other applicable terms according to these Rules.<br />11.5. The Organizer is not responsible for a printing or other error made by a participant in the Game in the course of providing personal data for registration of an account in the platform that resulted in impossibility to contact the participant.</p>
                <p><strong>SECTION 12. MISCELLANEOUS.</strong></p>
                <p>12.1. These Rules are accessible on the page of the Organizer for the period of conducting the Game.<br />12.2. These Rules comprise the comprehensive rules settling the conducting of the Game and the participation of the Participants in it. The provisions of Bulgarian legislation shall be applied for all matters not settled explicitly in them.<br />12.3. All disputes between the Organizer and the Participants arisen in reference to the Game shall be resolved in a spirit of goodwill by way of negotiations between them and in case this turns out to be impossible, they will be referred to the competent Bulgarian court for resolving.<br />12.4. These Rules come into effect from the time of their publishing and are valid for the whole period of conducting the Game and until the final settlement of all relations related to it.<br /><br /></p>
              </div>
            </div>
          </div>
        </div>
    );
  }
};


export default {
  component: GiveawayTermsPage
};
