import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import { fetchLocalizedPagesEntry } from '../../actions';
import { imageUrl } from '../../utilities/Files';

class PagesItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    UNSAFE_componentWillMount() {
        this.load();
    }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.setState({
                lang: this.props.activeLanguage.code
            });
            this.load(this.props.activeLanguage.code);
        }
    }

    load(lang) {
        this.props.fetchLocalizedPagesEntry((lang) ? lang : this.state.lang, this.props.slug);
    }

    head() {
        return (
            <Helmet>
                <title>{`${this.props.pagesEntry.title} - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`${this.props.pagesEntry.title} - BoneX | Crypto Exchange`} />
                <meta property="og:description" content={this.props.pagesEntry.meta_description} />
                {(this.props.pagesEntry.image) ? <meta property="og:image" content={`https://bonex.org${imageUrl(this.props.pagesEntry.image.file, 'lg')}`} /> : ''}
                <meta property="og:type" content='article' />
            </Helmet>
        );
    }

    image() {
        if (this.props.pagesEntry.image) {
            return <img src={imageUrl(this.props.pagesEntry.image.file, 'lg')} className="card-img-top img-fluid" alt="" />
        } else {
            return;
        }
    }

    render() {
        if (!_.isEmpty(this.props.pagesEntry) && _.isObject(this.props.pagesEntry)) {
            return (
                <div className="col-lg-12">
                    {this.head()}
                    <div
                        className="text-box"
                        dangerouslySetInnerHTML={{ __html: this.props.pagesEntry.content }}
                    />
                </div>
            );
        } else {
            return (
                <div />
            );
        }
    }
};


function mapStateToProps({ pagesEntry }) {
    return { pagesEntry };
}

export default connect(mapStateToProps, { fetchLocalizedPagesEntry })(
    withLocalize(PagesItem)
);
