import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Translate } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import { fetchAccount, fetchRates } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import AssetLightWidget from "../../components/assets/AssetLightWidget";
import UserSidebarNew from "../../components/users/UserSidebarNew";
import AccountDepositFormNew from "../../components/accounts/AccountDepositFormNew"; 

class AccountDepositPage extends Component {
  constructor(props) {
    super(props);
    this.onBackToAccounts = this.onBackToAccounts.bind(this)
  }

  onBackToAccounts() {
    window.location.replace("/accounts");
  }

  head() {
    if (this.props.user && Object.keys(this.props.user).length !== 0 && !this.props.user.verified) {
      return <Redirect to="/verification" />;
    }

    return (
      <Helmet>
        <title>{`Депозирай в ${this.props.account.type ? this.props.account.type + " " : ""
          } Акаунт - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Депозирай в ${this.props.account.type ? this.props.account.type + " " : ""
            } Акаунт - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
    this.props.fetchRates();
  }

  renderError() {
    const { error } = this.props;

    if (error) {
      return <div className="alert alert-danger">Error: {error}</div>;
    }
  }

  isTradeDisabled(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  getRateMinDeposit(assetType) {
    let result = 0;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.min_deposit;
      }
    });

    return result;
  };

  getAdditionalNetworks(assetType) {
    let result = 0;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.additional_networks;
      }
    });

    return result;
  };

  getNumberConfirmations(assetType) {
    let result = 0;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.number_confirmations;
      }
    });

    return result;
  };

  render() {
    return (
      <div className="container">
        <div className="row">

          {this.renderError()}

          {this.head()}
          <UserSidebarNew />
          <div className="container">
            <div className="profile-page-new-wrapper" style={{ borderRadius: "20px" }}>
              <div className="profile-page-box">
                <button type="button" onClick={this.onBackToAccounts} style={{ backgroundColor: "transparent", border: "none" }}>
                  <i className="fal fa-arrow-circle-left" style={{ fontSize: "30px", color: "#257199" }}></i>
                </button>
                <div className="row" style={{ textAlign: "center" }}>

                  <div className="">
                    {!this.isTradeDisabled(this.props.account.type) && (
                      <div>
                        {_.map(this.props.rates, rate => {
                          if (rate.type == this.props.account.type) {
                            return <AssetLightWidget {...rate} key={rate.id} />;
                          }
                        })}
                        <AccountDepositFormNew
                          account={this.props.account}
                          minDeposit={this.getRateMinDeposit(this.props.account.type)}
                          additionalNetworks={this.getAdditionalNetworks(this.props.account.type)}
                          numberConfirmations={this.getNumberConfirmations(this.props.account.type)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user, account, rates, currency, error }) {
  return { user, account, rates, currency, error };
}

export default {
  component: connect(mapStateToProps, { fetchAccount, fetchRates })(
    requireAuth(AccountDepositPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchAccount()),
};
