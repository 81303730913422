import { FETCH_AIRPORTS, CLEAR_AIRPORTS } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_AIRPORTS:
      return [...state, ...action.payload];
    case CLEAR_AIRPORTS:
      return [];
    default:
      return state;
  }
}