import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import AccountActionsNew2 from "./AccountActionsNew2";
import Loading from "../Loading";

class Account2New extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  getRate(assetType, assetAmount) {
    let rate = 0;
    _.map(this.props.rates, (itemRate, i) => {
      let tempRate = parseFloat(itemRate.rate / this.props.currency.rate);
      if (assetType == 'USDT' && this.props.currency.type == 'USDT') {
        rate = parseFloat(assetAmount);
      } else if (itemRate.type == assetType) {
        rate = parseFloat(assetAmount * tempRate);
      }
    });

    return rate.toFixed(2);
  };

  isTradeDisabled(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  getRateTitle(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.title;
        return;
      }
    });

    return result;
  };


  render() {
    if (!_.isEmpty(this.props.account)) {

      const trade_disabled = this.isTradeDisabled(this.props.account.type);

      let color = "";
      // if (this.props.account.type == "BTC" || this.props.account.type == "SHIB" || this.props.account.type == "MANA" || this.props.account.type == "LDO") color = "orange";
      // else if (this.props.account.type == "ETH" || this.props.account.type == "COMP") color = "purple";
      // else if (this.props.account.type == "BCH" || this.props.account.type == "PEPE") color = "dark-green";
      // else if (this.props.account.type == "XRP" || this.props.account.type == "MEME" || this.props.account.type == "MATIC") color = "blue";
      // else if (this.props.account.type == "RVN" || this.props.account.type == "SAND" || this.props.account.type == "MASK" || this.props.account.type == "APE" || this.props.account.type == "CAKE") color = "blue-2";
      // else if (this.props.account.type == "ETC" || this.props.account.type == "MKR") color = "light-green";
      // else if (this.props.account.type == "UNI" || this.props.account.type == "AMP") color = "purple-2";
      // else if (this.props.account.type == "LINK" || this.props.account.type == "USDC" || this.props.account.type == "ARB" || this.props.account.type == "SNX") color = "blue-3";
      // else if (this.props.account.type == "AAVE" || this.props.account.type == "CRV") color = "light-oil";

      if (trade_disabled) {
        color = "disabled";
      }

      const rateTitle = this.getRateTitle(this.props.account.type);

      return (
        <div className="row" >
          <div className="col-lg-12">
            <div className={`info-figure-wrapper info-figure-wrapper-new crypto-info-list-box ${color}-box ${this.state.open ? 'open-btn-holder' : ''}`}>
              <div className="account-card-title">
                <img
                  style={{ width: "40px", marginRight: "16px", paddingBottom: "10px" }}
                  src={`/img/assets/${this.props.account.type.toLowerCase()}.svg`}
                  alt={this.props.account.type}
                />
                <p style={{ fontSize: "32px", color: "white", marginRight: "16px" }}>{rateTitle} {' '}</p>
                <strong className="crypto-list-title">
                  <span>{this.props.account.type}</span>
                </strong>
              </div>
              <div className="account-balance-numbers">
                <h5><Translate id="myBalance" /></h5>
                <div>
                  {this.props.account.type}
                  <span style={{ fontSize: "58px" }}>
                    {parseFloat(this.props.account.balance).toFixed(this.props.account.decimals)}
                  </span>
                </div>
                <div>
                  {this.getRate(this.props.account.type, this.props.account.balance)}
                  <Translate id={this.props.currency.symbol} />
                </div>
              </div>
              <div className="account-actions-spot-trading">
                <AccountActionsNew2 account={this.props.account} currency={this.props.currency} trade_disabled={trade_disabled} />
              </div>
              {/* <div className="text">
                <div className="row">
                  <div className="col-sm-3 col-xs-6">
                    <p style={{ margin: "0px 0px 25px", paddingLeft: "14%" }}>
                      {this.state.windowWidth < 955 ? (
                        <figure style={{ display: "inline-block", marginRight: "5px", width: "32px" }}>
                          <Link to={`/accounts/${this.props.account.type}-new`}>
                            <img
                              style={{ width: "32px" }}
                              src={`/img/assets/${this.props.account.type.toLowerCase()}.svg`}
                              alt={this.props.account.type}
                            />
                          </Link>
                        </figure>
                      ) :
                        rateTitle} {' '}
                      <strong className="crypto-list-title">
                        <span>{this.props.account.type}</span>
                      </strong>
                    </p>
                  </div>
                  <div className="col-sm-3 col-xs-6 row">
                    <strong className="col-xs-12" style={{ whiteSpace: "nowrap" }}>
                      {parseFloat(this.props.account.balance).toFixed(this.props.account.decimals)}{" "}
                      <span>{this.props.account.type}</span>
                    </strong>
                    <span className="col-xs-12">
                      ≈ {this.getRate(this.props.account.type, this.props.account.balance)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </span>
                  </div>
                  <div className="col-sm-6 col-xs-12 action-buttons-wrapper">
                    <div className="">
                      <AccountActionsNew account={this.props.account} currency={this.props.currency} trade_disabled={trade_disabled} />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
};

export default withLocalize(Account2New);
