import _ from "lodash";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { signUp, clearAuthErrors } from "../../actions";
import { globalRegExes } from "../../utilities/RegEx";
import ReCAPTCHA from "react-google-recaptcha";

const FIELDS = {
  email: {
    type: "email",
    label: "email",
    required: true,
    errorMsg: "emailIsInvalid",
    placeHolder: "mail@website.com",
    additionalOwnClass: "email-registration-field"
  },
  firstName: {
    type: "text",
    label: "firstName",
    required: true,
    errorMsg: "nameIsInvalid",
    placeHolder: "Your First Name",
    additionalOwnClass: "name-registration-field first-name-registration-field col-xs-6 align-self-start"
  },
  lastName: {
    type: "text",
    label: "lastName",
    required: true,
    errorMsg: "lastNameIsInvalid",
    placeHolder: "Your Last Name",
    additionalOwnClass: "name-registration-field last-name-registration-field col-xs-6 align-self-end"
  },
  phone: {
    type: "text",
    label: "phone",
    required: false,
    errorMsg: "phoneIsInvalid",
    placeHolder: "0899 899 899",
    additionalOwnClass: "phone-registration-field"
  },
  password: {
    type: "password",
    label: "password",
    required: true,
    errorMsg: "passwordIsInvalid",
    placeHolder: "Min. 8 characters",
    additionalOwnClass: "password-registration-field"
  },
  passwordConfirm: {
    type: "password",
    label: "passwordConfirm",
    required: true,
    errorMsg: "passwordConfirmIsInvalid",
    placeHolder: "Min. 8 characters",
    additionalOwnClass: "confirm-password-registration-field"
  },
  referral: {
    type: "referral",
    label: "referral",
    errorMsg: "referralIsInvalid",
    placeHolder: "Your referral code",
    additionalOwnClass: "referral-registration-field"
  },
};

const CHECKBOXES = {
  tnc: {
    type: "checkbox",
    label: "tnc",
    name: "tnc",
    value: "1",
    required: true,
    errorMsg: "tncIsRequired",
  },
};

const recaptchaRef = React.createRef();

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.isSubmitting = false;

    this.state = {
      captcha: false
    }
  }

  async handleFormSubmit(formProps) {
    await recaptchaRef.current.executeAsync();

    this.isSubmitting = true;
    this.props.signUp({ ...formProps, captcha: this.state.captcha }).then((resp) => {
      this.isSubmitting = false;
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>
            <Translate id="oops" />!
          </strong>{" "}
          <Translate id={`errors.${this.props.errorMessage}`} />
        </div>
      );
    } else if (this.props.auth && this.props.auth.authenticated) {
      return <Redirect to="/profile" />;
    }
  }

  renderField(field) {
    const {
      input,
      label,
      type,
      meta: { touched, error },
    } = field;

    const className = `form-group form-field-wrapper ${field.additionalOwnClass} ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className={`input-icon`}>
          <div className={`label-animated has-content `}>
            <input
              {...input}
              type={type}
              className={`form-control placeholder-no-fix registration-field`}
              autoComplete="off"
              placeholder={field.placeHolder}
            />
            <label
              className="control-label visible-ie8 visible-ie9"
              style={{ color: touched && error ? '#ff615d' : '' }}
            >
              <Translate id={label} />
            </label>
            {touched && error ? (
              <div
                className="help-block with-errors"
                style={{
                  color: '#ff615d'
                }}
              >
                <span>
                  <Translate id={`errors.${error}`} />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  renderCheckbox(field) {
    const {
      input,
      label,
      type,
      id,
      meta: { touched, error },
    } = field;
    const className = `form-group ${touched && error ? "has-error" : "has-success"}`;
    return (
      <div className={className}>
        <div className="custom-check-holder">
          <label className="custom-check" htmlFor={label}>
            <input {...input} type={type} id={label} />
            <i></i>
            <Translate id="iAgreeWith" />{" "}
            <Link to="/terms" target="_blank">
              <Translate id="termsOfUse" />
            </Link>{" "}
            <Translate id="and" />{" "}
            <Link to="/privacy" target="_blank">
              <Translate id="privacyPolicy" />
            </Link>
          </label>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{
                color: '#ff615d'
              }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearAuthErrors();
  }

  onReCAPTCHAChange(captchaCode) {
    if (!captchaCode) {
      return;
    }
    this.setState({ captcha: captchaCode });
    recaptchaRef.current.reset();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div id="signup-form-container" className="signup-form-container">
        <form
          className="signup-form default-forms"
          id="signup-form"
          onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdsqJkpAAAAAGO3UsV4vJ_V5cGhq5amflTOrSvH"
            onChange={this.onReCAPTCHAChange.bind(this)}
          />
          <div className="form-cont-header">
            <h3>
              <Link to="/login"><Translate id="signin" /></Link> | <Translate id="signup" />
            </h3>
          </div>
          {_.map(FIELDS, (field, fieldName) => {
            return (
              <Field
                name={fieldName}
                component={this.renderField}
                key={fieldName}
                {...field}
              />
            );
          })}
          {_.map(CHECKBOXES, (field, fieldName) => {
            return (
              <Field
                name={fieldName}
                component={this.renderCheckbox}
                key={fieldName}
                {...field}
              />
            );
          })}
          {this.renderAlert()}
          <div className="form-actions">
            <button
              type="submit"
              className="submit"
              disabled={this.isSubmitting}
            >
              <Translate id="signup" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function validate(formProps) {
  const errors = {};

  _.each(FIELDS, (field, fieldName) => {
    if (!formProps[fieldName] && field.required) {
      errors[fieldName] = field.errorMsg;
    } else {
      if (
        fieldName == "email" &&
        !formProps[fieldName].match(globalRegExes.email)
      ) {
        errors[fieldName] = field.errorMsg;
      }

      if (
        fieldName == "phone" &&
        formProps[fieldName] &&
        formProps[fieldName] == ""
      ) {
        errors[fieldName] = field.errorMsg;
      }

      if (fieldName == "password") {
        if (formProps[fieldName].length < 8) {
          errors[fieldName] = "passwordIsTooShort";
        } else if (formProps[fieldName].length > 36) {
          errors[fieldName] = "passwordIsTooLong";
        } else if (!formProps[fieldName].match(globalRegExes.password)) {
          errors[fieldName] = field.errorMsg;
        }
      }
    }
  });

  if (
    formProps.password != "" &&
    formProps.passwordConfirm != "" &&
    formProps.password != formProps.passwordConfirm
  ) {
    errors.passwordConfirm = "passwordConfirmIsInvalid";
  }

  let requiredSelected = true;
  let requiredName = false;
  _.each(CHECKBOXES, (field, fieldName) => {
    if (!formProps[field.name] && field.required) {
      requiredSelected = false;
      requiredName = field.name;
    }
  });
  if (!requiredSelected) {
    errors[requiredName] = CHECKBOXES[requiredName].errorMsg;
  }

  return errors;
}

function mapStateToProps({ auth }, { referral }) {
  return {
    auth,
    initialValues: { referral },
    errorMessage: auth && auth.error ? auth.error : "",
  };
}

export default connect(mapStateToProps, { signUp, clearAuthErrors })(
  reduxForm({
    validate,
    form: "signup",
    fields: _.merge({}, FIELDS, CHECKBOXES),
    enableReinitialize: true,
  })(SignUpForm)
);
