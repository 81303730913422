import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Translate } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import { fetchAccount, fetchRates } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import AssetLightWidget from "../../components/assets/AssetLightWidget";
import UserSidebarNew from "../../components/users/UserSidebarNew";
import AccountWithdrawFormNew from "../../components/accounts/AccountWithdrawFormNew";

class AccountWithdrawPage extends Component {
  constructor(props) {
    super(props);

    this.onBackToAccounts = this.onBackToAccounts.bind(this)
  }

  onBackToAccounts() {
    window.location.replace("/accounts");
  }

  head() {
    if (this.props.user && Object.keys(this.props.user).length !== 0 && !this.props.user.verified) {
      return <Redirect to="/verification" />;
    }

    return (
      <Helmet>
        <title>{`Изтегляне от ${this.props.account.type ? this.props.account.type + " " : ""
          } Акаунт - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Изтегляне от ${this.props.account.type ? this.props.account.type + " " : ""
            } Акаунт - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
    this.props.fetchRates();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          {this.head()}
          <UserSidebarNew />
          <div className="container">
            <div className="profile-page-new-wrapper" style={{ borderRadius: "20px" }}>
              <div className="profile-page-box">
                <button type="button" onClick={this.onBackToAccounts} style={{ backgroundColor: "transparent", border: "none" }}>
                  <i className="fal fa-arrow-circle-left" style={{ fontSize: "30px", color: "#257199" }}></i>
                </button>
                <div className="row">

                  <div className="col-md-12">
                    {_.map(this.props.rates, rate => {
                      if (rate.type == this.props.account.type) {
                        return <AssetLightWidget {...rate} key={rate.id} />;
                      }
                    })}
                    <AccountWithdrawFormNew
                      account={this.props.account}
                      user={this.props.user}
                    />
                  </div>
                  <div className="col-md-12">
                    <div
                      className="white-box transparent-box-new no-pad new-style-tabs"
                      style={{ marginTop: "0px" }}
                    >
                      <div className="portlet-title tabbable-line">
                        <span>Select</span>
                        <ul className="nav nav-tabs" id="form-tabs">
                          <li className="active">
                            <a href="#account" data-toggle="tab">
                              <Translate id="withdrawFromAddress" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="portlet-body">
                        <div className="tab-content">
                          <div className="tab-pane fade in active" id="account">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="payment-text">
                                  <p>
                                    <Translate id="toWithdraw" />{" "}
                                    <strong>{this.props.account.type}</strong>,{" "}
                                    <Translate id="pleaseInsert" />{" "}
                                    <strong>{this.props.account.type}</strong>{" "}
                                    <Translate id="withdrawReceiver" />{" "}
                                    <Translate id="withdrawTax" />
                                    <strong>
                                      {this.props.account.type}
                                    </strong>{" "}
                                    <Translate id="is" />{" "}
                                    <strong>
                                      {this.props.config['tx_tax_' + this.props.account.type]}{" "}
                                      {this.props.account.type}
                                    </strong>{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="payment-text"></div>
                              </div>
                              <div className="col-lg-6">
                                <div className="payment-text"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ account, user, rates, config, currency }) {
  return { account, user, rates, config, currency };
}

export default {
  component: connect(mapStateToProps, { fetchAccount, fetchRates })(
    requireAuth(AccountWithdrawPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchAccount()),
};
