import { FETCH_TRANSACTION, SEND_TRANSACTION, CLEAR_TRANSACTION } from '../actions/types';

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_TRANSACTION:
            return { ...state, ...action.payload };
        case SEND_TRANSACTION:
            return { ...state, ...action.payload };
        case CLEAR_TRANSACTION:
            return null;
        default:
            return state;
    }
}