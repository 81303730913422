import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import { CarsList } from '../../components/cars/CarsList';
import { fetchCars, fetchRates } from '../../actions';

class CarsRentalPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    head() {
        return (
            <Helmet>
                <title>{`Rent a Super Car - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Rent a Super Car - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.setState({
                lang: this.props.activeLanguage.code,
            });
            // this.executeJQuery();
        }
    }

    componentDidMount() {
        this.props.fetchCars('Rent', 'Dubai');
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {this.head()}
                            <div className="row">
                                <div className="col-sm-12 col-lg-8">
                                    <h1 className="page-title-box">
                                        <span>Rent a Super Car</span>
                                        <span>with <span className="orange-text">crypto</span></span>
                                    </h1>
                                </div>
                                <div className="col-sm-12 col-lg-4 text-lg-right">
                                    <div className="brand-logo">
                                        <p>
                                            Powered by:
                                        </p>
                                        <figure>
                                            <img src="/img/rent-cars-logo.png" alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <CarsList items={this.props.cars} type="rental" rates={this.props.rates} currency={this.props.currency} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


function mapStateToProps({ cars, rates, currency }) {
    return { cars, rates, currency };
}

export default {
    component: connect(
        mapStateToProps,
        { fetchCars, fetchRates }
    )(withLocalize(CarsRentalPage)),
    loadData: ({ dispatch }) => dispatch(fetchCars())
};
