import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withLocalize, Translate } from "react-localize-redux";
import { fetchUser, updatePersonalVerification } from "../../actions";
import { globalRegExes } from "../../utilities/RegEx";
import * as countries from "../../utilities/Countries";

const FIELDS = {
  first_name: {
    type: "input",
    label: "firstName",
    id: "first_name",
    required: true,
    errorMsg: "nameIsInvalid",
  },
  middle_name: {
    type: "input",
    label: "middleName",
    id: "middle_name",
    required: true,
    errorMsg: "middleNameIsInvalid",
  },
  last_name: {
    type: "input",
    label: "lastName",
    id: "last_name",
    required: true,
    errorMsg: "lastNameIsInvalid",
  },
  birth_date: {
    type: "date",
    label: "birthDate",
    id: "birth_date",
    required: true,
    inputClass: "date",
    errorMsg: "birthDateIsInvalid",
  },
  nationality: {
    type: "select",
    label: "nationality",
    id: "nationality",
    required: true,
    translateOptions: false,
    options: {
      bg: countries.bg,
      en: countries.en,
      ro: countries.ro,
    },
    selector: {
      bg: "Изберете",
      en: "Select",
      ro: "Selectati"
    },
    errorMsg: "nationalityIsInvalid",
  },
  gender: {
    type: "select",
    label: "gender",
    id: "gender",
    required: true,
    translateOptions: false,
    options: {
      bg: { Male: "Мъж", Female: "Жена" },
      en: { Male: "Male", Female: "Female" },
      ro: { Male: "Masculin", Female: "Femeie" },
    },
    selector: {
      bg: "Изберете",
      en: "Select",
      ro: "Selectati"
    },
    errorMsg: "genderIsInvalid",
  },
  residence: {
    type: "select",
    label: "residence",
    id: "residence",
    required: true,
    translateOptions: false,
    options: {
      bg: countries.bg,
      en: countries.en,
      ro: countries.ro,
    },
    selector: {
      bg: "Изберете",
      en: "Select",
      ro: "Selectati"
    },
    errorMsg: "residenceIsInvalid",
  },
  address: {
    type: "input",
    label: "address",
    id: "address",
    required: true,
    errorMsg: "addressIsInvalid",
  },
  phone: {
    type: "input",
    label: "phone",
    id: "phone",
    required: true,
    errorMsg: "phoneIsInvalid",
  },
};

const DOCUMENTS = {
  id_card: {
    name: "doc_type",
    type: "radio",
    label: "idCard",
    value: "id_card",
    errorMsg: "documentIsInvalid",
  },
  // driver_license: {
  //     name: 'doc_type',
  //     type: 'radio',
  //     label: 'driverLicense',
  //     value: 'driver_license',
  // },
  passport: {
    name: "doc_type",
    type: "radio",
    label: "passport",
    value: "passport",
  },
};

const FILES = {
  identity_card_front: {
    type: "file",
    label: "documentFront",
    id: "identity_card_front",
    accept: ".jpg, .png, .jpeg",
    errorMsg: "fileIsInvalid",
  },
  identity_card_back: {
    type: "file",
    label: "documentBack",
    id: "identity_card_back",
    accept: ".jpg, .png, .jpeg",
    errorMsg: "fileIsInvalid",
  },
  identity_card_selfie: {
    type: "file",
    label: "documentSelfie",
    id: "identity_card_selfie",
    accept: ".jpg, .png, .jpeg",
    errorMsg: "fileIsInvalid",
  },
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

class PersonalVerificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      errorMessage: false,
      lang: this.props.activeLanguage.code,
    };
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
    }
  }

  renderField(field) {
    const {
      input,
      id,
      label,
      type,
      inputClass,
      meta: { touched, error },
    } = field;
    const className = `form-group col-md-4 col-sm-6 col-xs-12 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="label-animated has-content">
          <input
            {...input}
            type={type}
            id={id}
            className={`form-control placeholder-no-fix ${inputClass}`}
            autoComplete="off"
          />
          <label className="control-label visible-ie8 visible-ie9">
            <Translate id={label} />
          </label>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderSelect(field) {
    const {
      input,
      id,
      label,
      options,
      selector,
      translateOptions,
      meta: { touched, error },
    } = field;
    const className = `col-md-4 col-sm-6 col-xs-12 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="form-group">
          <div className="custom-style-select label-animated has-content">
            <label className="control-label visible-ie8 visible-ie9">
              <Translate id={label} />
            </label>
            <select {...input} id={id} className="form-control custom-select">
              <option value="">{selector[this.state.lang]}</option>
              {_.map(options[this.state.lang], (name, optionVal) => {
                return (
                  <option value={optionVal} key={optionVal}>
                    {translateOptions === true ? (
                      <Translate id={name} />
                    ) : (
                      `${name}`
                    )}
                  </option>
                );
              })}
            </select>
          </div>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderRadio(field) {
    const {
      input,
      label,
      type,
      id,
      meta: { touched, error },
    } = field;
    const className = `form-group col-md-4 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="custom-check">
          <label>
            <input {...input} type={type} id={id} className="form-control" />
            <i></i>
            <Translate id={label} />
          </label>
        </div>
        {touched && error ? (
          <div
            className="help-block with-errors"
            style={{ marginLeft: "16px" }}
          >
            <span>
              <Translate id={`errors.${error}`} />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderFile(field) {
    const {
      input,
      id,
      label,
      type,
      accept,
      meta: { touched, error },
    } = field;
    const className = `col-md-6 ${touched && error ? "has-error" : "has-success"
      }`;
    return (
      <div className={className}>
        <div className="form-group">
          <div className="custom-upload">
            <input
              {...input}
              type={type}
              id={id}
              accept={accept}
              className="form-control"
              value=""
              onChange={adaptFileEventToValue(input.onChange)}
              onBlur={adaptFileEventToValue(input.onBlur)}
            />
            <span>
              <Translate id={label} />
            </span>
            <div className="custom-upload-fake">
              <figure>
                <i className="sprite upload-icon"></i>
              </figure>
              {input.value && _.isEmpty(input.value) ? (
                <span>{input.value.name}</span>
              ) : (
                <span>
                  <Translate id="upload" />
                </span>
              )}
            </div>
            {touched && error ? (
              <div
                className="help-block with-errors"
                style={{ marginLeft: "16px" }}
              >
                <span>
                  <Translate id={`errors.${error}`} />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  onSubmit(values) {
    this.props.updatePersonalVerification(values).then((data) => {
      if (data.success) {
        this.setState({ submitted: true });
      } else {
        this.setState({
          errorMessage: data.message,
        });
      }
    });
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.errorMessage != "") {
      return (
        <div className="alert alert-danger">
          <strong>
            <Translate id="oops" />!
          </strong>{" "}
          {this.state.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    if (
      !_.isEmpty(this.props.user) &&
      ["All", "Personal"].indexOf(this.props.user.verification) > -1
    ) {
      return (
        <div className="infoFigureBox">
          <div className="row">
            <div className="col-12">
              <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="inProcessLabel" />!
                </h3>
              </header>
            </div>
            <div className="col-md-offset-2 col-md-8">
              <div className="success-msg-box">
                <figure>
                  <i className="far fa-check-circle"></i>
                </figure>
                <h5>
                  <Translate id="alreadyRequestedPersonalVerification" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.submitted) {
      return (
        <div className="infoFigureBox">
          <div className="row">
            <div className="col-12">
              <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="successLabel" />!
                </h3>
              </header>
            </div>
            <div className="col-md-offset-2 col-md-8">
              <div className="success-msg-box">
                <figure>
                  <i className="far fa-check-circle"></i>
                </figure>
                <h5>
                  <Translate id="success.personalVerification" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="varification-personal-form-container"
          className="verification-personal"
        >
          <form
            className="varification-personal-form form"
            id="varification-personal-form"
            onSubmit={handleSubmit(this.onSubmit.bind(this))}
          >
            <h3 className="tab-title-offset">
              <Translate id="personalData" />
            </h3>
            <div className="form-body">
              <div className="row form-flex">
                {_.map(FIELDS, (field, fieldName) => {
                  if (field.type === "select") {
                    return (
                      <Field
                        name={fieldName}
                        component={this.renderSelect.bind(this)}
                        {...field}
                        key={fieldName}
                      />
                    );
                  } else {
                    return (
                      <Field
                        name={fieldName}
                        component={this.renderField.bind(this)}
                        {...field}
                        key={fieldName}
                      />
                    );
                  }
                })}
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group custom-style-input">
                    <div className="inputsContainer">
                      <div className="blue-bgr">
                        <div className="row">
                          {_.map(DOCUMENTS, (field, fieldName) => {
                            return (
                              <Field
                                name={fieldName}
                                component={this.renderRadio}
                                {...field}
                                key={fieldName}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="inputsContBox">
                        <div className="row">
                          {_.map(FILES, (field, fieldName) => {
                            return (
                              <Field
                                name={fieldName}
                                component={this.renderFile}
                                {...field}
                                key={fieldName}
                              />
                            );
                          })}
                          <div className="col-md-6">
                            <p>
                              <Translate id="documentSelfieInfo" />:
                            </p>
                            <ul>
                              <li>
                                <Translate id="yourFace" />
                              </li>
                              <li>
                                <Translate id="idDocument" />
                              </li>
                              <li>
                                <Translate id="todaysDate" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="inputsContainer-bottom">
                        <p>
                          <i className="info-symbol">i</i>
                          <Translate id="documentsFilesInfo" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderAlert()}
            </div>
            <div className="clearfix">&nbsp;</div>
            <div className="form-actions text-right">
              <button type="submit" className="btn btn-primary">
                <Translate id="send" />
              </button>
            </div>
          </form>
        </div>
      );
    }
  }
}

function validate(formProps) {
  const errors = {};

  _.each(FIELDS, (field, fieldName) => {
    if (!formProps[fieldName]) {
      errors[fieldName] = field.errorMsg;
    } else if (
      fieldName == "birth_date" &&
      !formProps[fieldName].match(globalRegExes.date) &&
      !formProps[fieldName].match(globalRegExes.dateDB)
    ) {
      errors[fieldName] = field.errorMsg;
    } else if (
      fieldName == "phone" &&
      formProps[fieldName] &&
      formProps[fieldName] == ""
    ) {
      errors[fieldName] = field.errorMsg;
    }

    if (field.options && formProps[fieldName] == "") {
      errors[fieldName] = field.errorMsg;
    }
  });

  let documentSelected = false;
  let documentName = false;
  _.each(DOCUMENTS, (field, fieldName) => {
    if (formProps[field.name]) {
      documentSelected = true;
    } else {
      documentName = field.name;
    }
  });
  if (!documentSelected) {
    errors[documentName] = DOCUMENTS.id_card.errorMsg;
  }

  _.each(FILES, (field, fieldName) => {
    if (!formProps[fieldName] && fieldName != "identity_card_back") {
      errors[fieldName] = field.errorMsg;
    } else if (
      formProps["doc_type"] != "passport" &&
      fieldName == "identity_card_back" &&
      !formProps[fieldName]
    ) {
      errors[fieldName] = field.errorMsg;
    }
  });

  return errors;
}

function mapStateToProps({ auth, user }) {
  return {
    auth,
    user,
    initialValues: user,
  };
}

export default connect(mapStateToProps, {
  fetchUser,
  updatePersonalVerification,
})(
  reduxForm({
    validate,
    form: "updatePersonalVerificationForm",
    fields: _.merge({}, FIELDS, DOCUMENTS, FILES),
    enableReinitialize: true,
  })(withLocalize(PersonalVerificationForm))
);
