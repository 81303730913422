import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withLocalize, Translate } from "react-localize-redux";
import { resetTotp } from "../../actions";

const FIELDS = {
  code: {
    type: "input",
    label: "code",
    id: "code",
    required: true,
    errorMsg: "codeIsInvalid",
  },
  first_name: {
    type: "input",
    label: "firstName",
    id: "first_name",
    required: true,
    errorMsg: "nameIsInvalid",
  },
  middle_name: {
    type: "input",
    label: "middleName",
    id: "middle_name",
    required: true,
    errorMsg: "middleNameIsInvalid",
  },
  last_name: {
    type: "input",
    label: "lastName",
    id: "last_name",
    required: true,
    errorMsg: "lastNameIsInvalid",
  },
  egn: {
    type: "input",
    label: "egn",
    id: "egn",
    required: true,
    errorMsg: "egnIsInvalid",
  }
};

const FILES = {
  selfie: {
    type: "file",
    label: "selfie",
    id: "selfie",
    required: true,
    accept: ".jpg, .png, .jpeg",
    errorMsg: "fileIsInvalid",
  },
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

class ResetTotpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      errorMessage: false,
      lang: this.props.activeLanguage.code,
    };
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
    }
  }

  renderField(field) {
    const {
      input,
      id,
      label,
      type,
      inputClass,
      meta: { touched, error },
    } = field;
    const className = `form-group col-xs-12 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="label-animated has-content">
          <input
            {...input}
            type={type}
            id={id}
            className={`form-control placeholder-no-fix ${inputClass}`}
            autoComplete="off"
          />
          <label className="control-label visible-ie8 visible-ie9">
            <Translate id={label} />
          </label>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderSelect(field) {
    const {
      input,
      id,
      label,
      options,
      selector,
      translateOptions,
      meta: { touched, error },
    } = field;
    const className = `col-xs-12 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="form-group">
          <div className="custom-style-select label-animated has-content">
            <label className="control-label visible-ie8 visible-ie9">
              <Translate id={label} />
            </label>
            <select {...input} id={id} className="form-control custom-select">
              <option value="">{selector[this.state.lang]}</option>
              {_.map(options[this.state.lang], (name, optionVal) => {
                return (
                  <option value={optionVal} key={optionVal}>
                    {translateOptions === true ? (
                      <Translate id={name} />
                    ) : (
                      `${name}`
                    )}
                  </option>
                );
              })}
            </select>
          </div>
          {touched && error ? (
            <div
              className="help-block with-errors"
              style={{ marginLeft: "16px" }}
            >
              <span>
                <Translate id={`errors.${error}`} />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderRadio(field) {
    const {
      input,
      label,
      type,
      id,
      meta: { touched, error },
    } = field;
    const className = `form-group col-md-12 ${touched && error ? "has-error" : "has-success"
      }`;

    return (
      <div className={className}>
        <div className="custom-check">
          <label>
            <input {...input} type={type} id={id} className="form-control" />
            <i></i>
            <Translate id={label} />
          </label>
        </div>
        {touched && error ? (
          <div
            className="help-block with-errors"
            style={{ marginLeft: "16px" }}
          >
            <span>
              <Translate id={`errors.${error}`} />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderFile(field) {
    const {
      input,
      id,
      label,
      type,
      accept,
      meta: { touched, error },
    } = field;
    const className = `col-md-12 ${touched && error ? "has-error" : "has-success"
      }`;
    return (
      <div className={className}>
        <div className="form-group">
          <div className="custom-upload">
            <input
              {...input}
              type={type}
              id={id}
              accept={accept}
              className="form-control"
              value=""
              onChange={adaptFileEventToValue(input.onChange)}
              onBlur={adaptFileEventToValue(input.onBlur)}
            />
            <span>
              <Translate id={label} />
            </span>
            <div className="custom-upload-fake">
              <figure>
                <i className="sprite upload-icon"></i>
              </figure>
              {input.value && _.isEmpty(input.value) ? (
                <span>{input.value.name}</span>
              ) : (
                <span>
                  <Translate id="upload" />
                </span>
              )}
            </div>
            {touched && error ? (
              <div
                className="help-block with-errors"
                style={{ marginLeft: "16px" }}
              >
                <span>
                  <Translate id={`errors.${error}`} />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  onSubmit(values) {
    this.props.resetTotp(values).then((data) => {
      if (data.success) {
        this.setState({ submitted: true });
      } else {
        this.setState({
          errorMessage: data.message,
        });
      }
    });
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.errorMessage != "") {
      return (
        <div className="alert alert-danger">
          <strong>
            <Translate id="oops" />!
          </strong>{" "}
          {this.state.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    if (this.state.submitted) {
      return (
        <div className="infoFigureBox">
          <div className="row">
            <div className="col-12">
              <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="successLabel" />!
                </h3>
              </header>
            </div>
            <div className="col-md-offset-2 col-md-8">
              <div className="success-msg-box">
                <figure>
                  <i className="far fa-check-circle"></i>
                </figure>
                <h5>
                  <Translate id="resetTotpSubmitted" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="varification-personal-form-container"
          className="verification-personal"
        >
          <form
            className="varification-personal-form form"
            id="varification-personal-form"
            onSubmit={handleSubmit(this.onSubmit.bind(this))}
          >
            <h3 className="tab-title-offset">
              <Translate id="personalData" />
            </h3>
            <div className="form-body">
              <div className="row form-flex">
                <div className="col-xs-6">
                  <div className="row">
                    {_.map(FIELDS, (field, fieldName) => {
                      if (field.type === "select") {
                        return (
                          <Field
                            name={fieldName}
                            component={this.renderSelect.bind(this)}
                            {...field}
                            key={fieldName}
                          />
                        );
                      } else {
                        return (
                          <Field
                            name={fieldName}
                            component={this.renderField.bind(this)}
                            {...field}
                            key={fieldName}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="form-group custom-style-input">
                    <div className="inputsContainer">
                      <div className="inputsContBox">
                        <div className="row">
                          {_.map(FILES, (field, fieldName) => {
                            return (
                              <Field
                                name={fieldName}
                                component={this.renderFile}
                                {...field}
                                key={fieldName}
                              />
                            );
                          })}
                          <div className="col-md-6">
                            <p>
                              <Translate id="documentSelfieInfo" />:
                            </p>
                            <ul>
                              <li>
                                <Translate id="yourFace" />
                              </li>
                              <li>
                                <Translate id="idDocument" />
                              </li>
                              <li>
                                <Translate id="todaysDate" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="inputsContainer-bottom">
                        <p>
                          <i className="info-symbol">i</i>
                          <Translate id="documentsFilesInfo" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderAlert()}
            </div>
            <div className="clearfix">&nbsp;</div>
            <div className="form-actions text-right">
              <button type="submit" className="btn btn-primary">
                <Translate id="send" />
              </button>
            </div>
          </form>
        </div>
      );
    }
  }
}

function validate(formProps) {
  const errors = {};

  _.each(FIELDS, (field, fieldName) => {
    if (!formProps[fieldName]) {
      errors[fieldName] = field.errorMsg;
    }

    if (field.options && formProps[fieldName] == "") {
      errors[fieldName] = field.errorMsg;
    }
  });

  _.each(FILES, (field, fieldName) => {
    if (!formProps[fieldName]) {
      errors[fieldName] = field.errorMsg;
    }
  });

  return errors;
}

function mapStateToProps({ }, { token }) {
  return {
    initialValues: { code: token },
  };
}

export default connect(mapStateToProps, {
  resetTotp,
})(
  reduxForm({
    validate,
    form: "resetTotpForm",
    fields: _.merge({}, FIELDS, FILES),
    enableReinitialize: true,
  })(withLocalize(ResetTotpForm))
);
