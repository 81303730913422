export const addressRegExes = {
  BTC: /^\b(bc(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|[13][a-km-zA-HJ-NP-Z1-9]{25,35})\b$/,
  ETH: /^(0x){1}[0-9a-fA-F]{40}$/,
  BCH: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/,
  BCH_2: /^((bitcoincash:)?(q|p)[a-z0-9]{41})$/,
  BCH_3: /^((BITCOINCASH:)?(Q|P)[A-Z0-9]{41})$/
};

export const amountRegExes = {
  crypto: /^[0-9]\d{0,7}(?:\.\d{0,8})?$/,
  fiat: /^\d+(\.\d{0,2})?$/
};

export const globalRegExes = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  date: /^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\d\d$/,
  dateDB: /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
  phone: /^(\+\d{1,3}?)\d{10}$/,
  epay_kin: /^\d{10}$/,
  bank_bic: /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/
};
