import React, { Component } from "react";
import { fetchRates } from "../../actions";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { formatMoney } from "../../utilities/Format";
import { amountRegExes } from "../../utilities/RegEx";

const DISPLAYED_INPUT = {
  cryptoCurrencyAmount: "cryptoCurrencyAmount",
  fiatCurrencyPrice: "fiatCurrencyPrice"
}

class AssetRates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      price: "",
      rate: 0,
      formErrors: {
        amount: "",
        price: ""
      },
      amountValid: false,
      priceValid: false,
      touched: false,
      displayedInput: DISPLAYED_INPUT.cryptoCurrencyAmount
    };

    this.onChangeInput = this.onChangeInput.bind(this)
  }

  onChangeInput(e) {
    e.preventDefault()
    if (this.state.displayedInput === DISPLAYED_INPUT.cryptoCurrencyAmount) {
      this.setState({ displayedInput: DISPLAYED_INPUT.fiatCurrencyPrice })
    } else if (this.state.displayedInput === DISPLAYED_INPUT.fiatCurrencyPrice) {
      this.setState({ displayedInput: DISPLAYED_INPUT.cryptoCurrencyAmount })
    }
  }

  getRateTitle(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.title;
        return;
      }
    });

    return result;
  };

  getPrice(type, amount, action) {
    let price = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate / this.props.currency.rate;
      if (this.props.sign === "+") {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) +
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      } else {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) -
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      }
      if (aRate.type == type && action == 'amount') {
        price = parseFloat(amount * tempRate).toFixed(2);
      } else if (aRate.type == this.props.account.type && action == 'price') {
        price = parseFloat(amount / tempRate).toFixed(this.props.account.decimals);
      }
    });
    return price;
  }

  getRate() {
    let rate = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate / this.props.currency.rate;
      if (this.props.sign === "+") {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) +
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      } else {
        tempRate =
          parseFloat(aRate.rate / this.props.currency.rate) -
          (parseFloat(aRate.rate / this.props.currency.rate) *
            this.props.diff) /
          100;
      }

      if (aRate.type == this.props.account.type) {
        rate = parseFloat(1 * tempRate);
      }
    });
    return rate;
  }

  handleAmountChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    const price = this.getPrice(this.props.account.type, value, 'amount');
    const rate = this.getRate();
    this.setState(
      {
        amount: value,
        price,
        rate,
        touched: true
      },
      () => {
        this.validateField(name, value).then(() => {
          this.validateField("price", price).then(() => {
            this.callParentAction(value, price, name);
          });
        });
      }
    );
  }

  validateAmountChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    if (!value.match(amountRegExes.crypto)) {
      this.setState(
        {
          amount: 0,
          price: 0
        },
        () => {
          this.validateField(name, "0").then(() => {
            this.callParentAction(0, 0, name);
          });
        }
      );
    }
  }

  handlePriceChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    const amount = this.getPrice(this.props.currency.type, value, 'price');
    const rate = this.getRate();
    this.setState(
      {
        amount,
        price: value,
        rate,
        touched: true
      },
      () => {
        this.validateField(name, value).then(() => {
          this.validateField("amount", amount).then(() => {
            this.callParentAction(amount, value, name);
          });
        });
      }
    );
  }

  validatePriceChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    if (!value.match(amountRegExes.fiat)) {
      this.setState(
        {
          amount: 0,
          price: 0
        },
        () => {
          this.validateField(name, "0").then(() => {
            this.callParentAction(0, 0, name);
          });
        }
      );
    }
  }

  callParentAction(rate, value, name) {
    if (this.props.action) {
      this.props.action(rate, value, name, this.state.rate);
    }
  }

  validateField(fieldName, value) {
    return new Promise(resolve => {
      let formErrors = this.state.formErrors;
      let amountValid = this.state.amountValid;
      let priceValid = this.state.priceValid;

      switch (fieldName) {
        case "amount":
          amountValid =
            parseFloat(value).toPrecision(9) != "0.00000000" &&
            value.match(amountRegExes.crypto);
          formErrors.amount = amountValid ? "" : "IsInvalid";
          if (
            typeof this.props.minAmount != "undefined" &&
            parseFloat(value) < this.props.minAmount
          ) {
            formErrors.amount = "IsBelowMin";
          }
          if (
            typeof this.props.maxAmount != "undefined" &&
            parseFloat(value) > this.props.maxAmount
          ) {
            formErrors.amount = "IsAboveMax";
          }
          amountValid = !formErrors.amount.length;
          break;
        case "price":
          formErrors.price =
            value && value.match(amountRegExes.fiat) ? "" : "IsInvalid";
          if (value * 1 < this.props.minTotal / this.props.currency.rate) {
            formErrors.price = "IsBelowMin";
          } else if (
            value * 1 >
            (this.props.currency.type == 'USDT' ? this.props.maxTotal / this.props.currency.rate : this.props.maxTotal)
          ) {
            formErrors.price = "IsAboveMax";
          }
          priceValid = !formErrors.price.length;
          break;
        default:
          break;
      }

      this.setState({
        formErrors,
        amountValid,
        priceValid
      });

      return resolve(true);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.type != this.props.currency.type || prevProps.diff != this.props.diff) {
      const price = this.getPrice(this.props.account.type, this.state.amount, 'amount');
      const rate = this.getRate();

      this.setState({ price, rate }, () => {
        this.callParentAction(this.state.amount, this.state.price, "amount");
      });
    } else if (prevProps.maxTotal != this.props.maxTotal) {
      this.validateField("price", this.state.price);
    }
  }

  render() {
    const rateTitle = this.getRateTitle(this.props.account.type);

    return (
      <div className="bold-forms">
        {/* <div className="account-form-header"> */}
        <div className="account-card-title" style={{ marginBottom: "30px" }}>
          <img
            style={{ width: "40px", marginRight: "16px", paddingBottom: "10px" }}
            src={`/img/assets/${this.props.account.type.toLowerCase()}.svg`}
            alt={this.props.account.type}
          />
          <p style={{ fontSize: "32px", color: "white", marginRight: "16px" }}>{rateTitle}</p>
        </div>
        <div className="" style={{ margin: "10px 25%" }}>
          <div className="" style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
          }}>
            {this.state.displayedInput === DISPLAYED_INPUT.cryptoCurrencyAmount
              ?
              (
                <div
                  className={` ${this.state.touched
                    ? !this.state.priceValid || !this.state.amountValid
                      ? "has-error"
                      : "has-success"
                    : ""
                    }`}
                >
                  <div className="form-group" style={{ marginBottom: "45px" }}>
                    <div className="input-icon">
                      <div className="label-animated has-content" style={{ margin: "0px 0px 20px" }}>
                        <input
                          type="text"
                          className="form-control placeholder-no-fix form-action-input-field reduce-padding"
                          autoComplete="off"
                          value={this.state.amount}
                          onChange={this.handleAmountChange.bind(this)}
                          onBlur={this.validateAmountChange.bind(this)}
                          name="amount"
                          placeholder="0"
                        />
                        <label className="control-label visible-ie8 visible-ie9 coin-title-input" style={{ color: "white", top: "25px", left: "2px" }}>
                          {this.props.account.type}
                        </label>
                      </div>
                      <p style={{ textAlign: "center" }}>{this.state.price} <Translate id={this.props.currency.symbol} /></p>
                    </div>
                    {this.state.formErrors.amount === "" && this.state.formErrors.price === "" && <Translate id="insertAmountPrice" />}
                    {this.state.formErrors.amount != "" ? (
                      <div
                        className="help-block with-errors"
                      >
                        <span>
                          <Translate
                            id={`errors.amount${this.state.formErrors.amount}`}
                          />
                          {typeof this.props.minAmount != "undefined"
                            ? this.state.formErrors.amount == "IsBelowMin"
                              ? " (" +
                              this.props.minAmount +
                              " " +
                              this.props.account.type +
                              ")"
                              : ""
                            : ""}
                          {typeof this.props.maxAmount != "undefined"
                            ? this.state.formErrors.amount == "IsAboveMax"
                              ? " (" +
                              this.props.maxAmount +
                              " " +
                              this.props.account.type +
                              ")"
                              : ""
                            : ""}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.formErrors.price != "" ? (
                      <div
                        className="help-block with-errors"
                      >
                        <span>
                          {this.props.sign === "+" ? "" :

                            this.state.formErrors.price == "IsBelowMin" ? (
                              <React.Fragment>
                                <Translate
                                  id={`errors.price${this.state.formErrors.price}`}
                                />
                                (
                                {formatMoney(
                                  this.props.minTotal / this.props.currency.rate
                                )}{" "}
                                <Translate id={this.props.currency.symbol} />)
                              </React.Fragment>
                            ) : (
                              ""
                            )

                          }
                          {/* {this.state.formErrors.price == "IsBelowMin" ? (
                            <React.Fragment>
                              <Translate
                                id={`errors.price${this.state.formErrors.price}`}
                              />
                              (
                              {formatMoney(
                                this.props.minTotal / this.props.currency.rate
                              )}{" "}
                              <Translate id={this.props.currency.symbol} />)
                            </React.Fragment>
                          ) : (
                            ""
                          )} */}
                          {this.state.formErrors.price == "IsAboveMax" ? (
                            <React.Fragment>
                              <Translate
                                id={`errors.price${this.state.formErrors.price}`}
                              />
                              (
                              {formatMoney(
                                (this.props.currency.type == 'USDT' ? this.props.maxTotal / this.props.currency.rate : this.props.maxTotal)
                              )}{" "}
                              <Translate id={this.props.currency.symbol} />)
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
              :
              (
                <div
                  className={` ${this.state.touched
                    ? !this.state.priceValid
                      ? "has-error"
                      : "has-success"
                    : ""
                    }`}
                >
                  <div className="form-group" style={{ marginBottom: "45px" }}>
                    <div className="input-icon">
                      <div className="label-animated has-content" style={{ margin: "0px 0px 20px" }}>
                        <input
                          type="text"
                          className="form-control placeholder-no-fix form-action-input-field touched reduce-padding "
                          autoComplete="off"
                          value={this.state.price}
                          onChange={this.handlePriceChange.bind(this)}
                          onBlur={this.validatePriceChange.bind(this)}
                          name="price"
                          placeholder="0.00"
                        />
                        <label className="control-label visible-ie8 visible-ie9 coin-title-input" style={{ color: "white", top: "25px", left: "2px" }}>
                          <Translate id={this.props.currency.symbol} />
                        </label>
                      </div>
                      <p style={{ textAlign: "center" }}>{this.state.amount} {this.props.account.type} </p>
                    </div>
                    {this.state.formErrors.amount === "" && this.state.formErrors.price === "" && <Translate id="insertAmountPrice" />}
                    {this.state.formErrors.price != "" ? (
                      <div
                        className="help-block with-errors"
                      >
                        <span>
                          <Translate
                            id={`errors.price${this.state.formErrors.price}`}
                          />
                          {this.state.formErrors.price == "IsBelowMin" ? (
                            <React.Fragment>
                              (
                              {formatMoney(
                                this.props.minTotal / this.props.currency.rate
                              )}{" "}
                              <Translate id={this.props.currency.symbol} />)
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.state.formErrors.price == "IsAboveMax" ? (
                            <React.Fragment>
                              (
                              {formatMoney(
                                (this.props.currency.type == 'USDT' ? this.props.maxTotal / this.props.currency.rate : this.props.maxTotal)
                              )}{" "}
                              <Translate id={this.props.currency.symbol} />)
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
            }
            <button type="button" onClick={this.onChangeInput} className="change-input-button"
            >
              <i className="fad fa-exchange" style={{ fontSize: "20px" }}></i>
              {/* <h5>
                {this.state.displayedInput === DISPLAYED_INPUT.cryptoCurrencyAmount ? <Translate id={this.props.currency.symbol} /> : this.props.account.type}
              </h5> */}
              {/* <Translate id={this.props.currency.symbol} /> */}
            </button>
          </div>
          {/* <div className="form-info-box">
            <strong style={{ textAlign: "center" }}>
              <Translate id="insertAmountPrice" />
            </strong>
          </div> */}
        </div>
        {/* </div> */}
      </div >
    );
  }
}

function mapStateToProps({ rates, currency }) {
  return { rates, currency };
}

export default connect(
  mapStateToProps,
  { fetchRates }
)(AssetRates);
