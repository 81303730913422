import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { fetchNewsEntry } from '../../actions';
import NewsItem from '../../components/news/NewsItem';

class NewsDetailsPage extends Component {

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="intro-box">
                            <div className="row">
                                <NewsItem slug={this.props.match.params.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ newsEntry }) {
    return { newsEntry };
}

export default {
    component: connect(mapStateToProps, { fetchNewsEntry })(
        NewsDetailsPage
    ),
    loadData: ({ dispatch }, match) => dispatch(fetchNewsEntry(match.params.slug))
}
