import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccounts } from '../../actions';
import Loading from '../Loading';

class AccountsHeaderWidget extends Component {

    UNSAFE_componentWillMount() {
        this.props.fetchAccounts();
    }

    render() {
        if (!_.isEmpty(this.props.accounts)) {
            return (
                <li>
                    {_.map(this.props.accounts, (account) => {
                        if (account.type == 'BTC' || account.type == 'ETH') {
                            return (
                                <span key={account.id} className="btn btn-action btn-rounded" style={{ 'margin': '3px 5px 0' }}>
                                    {account.type}: {parseFloat(account.balance).toFixed(account.decimals)}
                                </span>
                            )
                        }
                    })}
                </li>
            );
        } else if (_.isObject(this.props.accounts)) {
            return <li></li>
        } else {
            return (
                <li>
                    <Loading />
                </li>
            )
        }
    }
};


function mapStateToProps({ accounts }) {
    return { accounts };
}

export default connect(mapStateToProps, { fetchAccounts })(AccountsHeaderWidget);