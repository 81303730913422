import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Translate } from "react-localize-redux";
import { fetchAsset } from "../../actions";
import AssetLightWidget from "../../components/assets/AssetLightWidget";

class AssetBuyTextPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Купи ${this.props.asset.type ? this.props.asset.type + " " : ""
          } - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Купи ${this.props.asset.type ? this.props.asset.type + " " : ""
            } - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAsset(this.props.match.params.type);
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        <Translate id="assetBuyText" />
                      </p>
                      {_.map(this.props.rates, rate => {
                        if (rate.type == this.props.asset.type) {
                          return <AssetLightWidget {...rate} key={rate.id} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

function mapStateToProps({ asset, rates, currency }) {
  return { asset, rates, currency };
}

export default {
  component: connect(
    mapStateToProps,
    { fetchAsset }
  )(AssetBuyTextPage)
};
