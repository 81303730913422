import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default (ChildComponent) => {
    class RequireAuth extends Component {
        render() {
            switch(this.props.authenticated) {
                case false:
                    return <Redirect to="/login" />
                case true:
                    return <ChildComponent {...this.props} />;
                default:
                    return <div>Loading...</div>;
            }
        }
    }

    function mapStateToProps({ auth }) {
        return { authenticated: auth.authenticated };
    }


    return connect(mapStateToProps)(RequireAuth);
};