import React from 'react';

const ChatIcon = () => {
    return (
        <a
            href="https://m.me/1184768875010396"
            target="_blank"
            rel="noopener noreferrer"
            className="chat-icon-link"
            title='Help'
        >
            <i className="chat-icon fas fa-comments"></i>
        </a>
    );
};

export default ChatIcon;
