import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import { LocalizeProvider } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import axios from "axios";
import globalTranslations from "../translations/global.json";
import Routes from "./Routes";
import reducers from "./reducers";
import { AUTH_USER } from "./actions/types";

const axiosInstance = axios.create({
  baseURL: "/api"
});

const composeEnchancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  window.INITIAL_STATE,
  composeEnchancers(applyMiddleware(thunk.withExtraArgument(axiosInstance)))
);

const supportedLanguages = ["bg", "en", "ro"]; //, "ro", "gr"];
const languages = [{ name: "BG", code: "bg" }, { name: "EN", code: "en" }, { name: "RO", code: "ro" }]; // , { name: "RO", code: "ro" }, { name: "GR", code: "gr" }];
let defaultLanguage = languages[1].code;

if (window.localStorage) {
  const token = localStorage.getItem("token");
  if (token) {
    store.dispatch({ type: AUTH_USER });
  }
  defaultLanguage = localStorage.getItem("languageCode") || languages[1].code;
  let queryString = window.location.search;
  if (queryString != '') {
    queryString = queryString.replace('?', '');
    const queryStringArr = queryString.split('&');
    let langParam = '';
    queryStringArr.forEach(param => {
      let paramParts = param.split('=');
      if (paramParts[0] == 'lang') {
        langParam = paramParts[1];
      }
    });
    if (langParam != '' && supportedLanguages.indexOf(langParam) != -1) {
      defaultLanguage = langParam;
      localStorage.setItem("languageCode", defaultLanguage);
    }
  }
}

ReactDOM.hydrate(
  <Provider store={store}>
    <LocalizeProvider
      initialize={{
        languages: languages,
        translation: globalTranslations,
        options: {
          defaultLanguage,
          renderToStaticMarkup
        }
      }}
    >
      <Router history={createBrowserHistory()}>
        <div>{renderRoutes(Routes)}</div>
      </Router>
    </LocalizeProvider>
  </Provider>,
  document.querySelector("#root")
);
