import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { fetchUser } from '../../actions';
import SignInForm from '../../components/auth/signInForm';

class SignInPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Вход - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Вход - BoneX | Crypto Exchange`} />
                <meta
                    property="og:description"
                    content="Покупка и продажба на Биткойн, Етер, Биткойн кеш. Софтуер, гарантиращ най-добрата цена на пазара в България."
                />
                <meta property="og:image" content="https://bonex.org/img/bonex_og.jpg" />
                <meta property="og:url" content="https://bonex.org/login" />
                <meta property="og:type" content="website" />
            </Helmet>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="form-cont login-form-cont col-sm-5">
                        <div id="login" className="login-tab-container open">
                            <SignInForm />
                        </div>
                        <div className="form-cont-footer">
                            <p style={{ color: 'white', marginBottom: '30px' }}><Translate id="login2FA" /></p>
                        </div>
                    </div>
                    <div className='login-video-wrapper col-sm-7'>
                        <video className='login-video' width="170%" height="100%" autoPlay loop muted>
                            <source src='/videos/login-video.webm' type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(SignInPage),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
