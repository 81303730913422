import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import { fetchNewsEntry } from '../../actions';
import { imageUrl } from '../../utilities/Files';

class NewsItem extends Component {
    UNSAFE_componentWillMount() {
        this.props.fetchNewsEntry(this.props.slug);
    }

    head() {

        return (
            <Helmet>
                <title>{`${this.props.newsEntry.title} - Новини - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`${this.props.newsEntry.title} - Новини - BoneX | Crypto Exchange`} />
                <meta property="og:description" content={this.props.newsEntry.meta_description} />
                {(this.props.newsEntry && this.props.newsEntry.image) ?
                    <meta property="og:image" content={`https://bonex.org${imageUrl(this.props.newsEntry.image.file, 'lg')}`} />
                    : ''}
                <meta property="og:url" content={`https://bonex.org/news/${this.props.newsEntry.slug}`} />
                <meta property="og:type" content='article' />
            </Helmet>
        );
    }

    image() {
        if (this.props.newsEntry.image) {
            return <img src={imageUrl(this.props.newsEntry.image.file, 'lg')} className="card-img-top img-fluid" alt="" />
        } else {
            return <img src="/img/assets/btc.png" className="card-img-top img-fluid" alt="" />
        }
    }

    render() {
        if (!_.isEmpty(this.props.newsEntry) && _.isObject(this.props.newsEntry)) {
            return (
                <div className="col-lg-12">
                    {this.head()}
                    <div className="intro-text">
                        <h2><span>{this.props.newsEntry.title}</span></h2>
                        <div className="article-full">
                            <figure>
                                {this.image()}
                            </figure>
                            <div className="article-info-share">
                                <div className="article-info">
                                    <time>{new Date(this.props.newsEntry.date).toLocaleDateString()}</time>
                                </div>
                                <div className="article-share-box">
                                    <a target="_blank" href={`https://www.facebook.com/sharer.php?u=https://bonex.org/news/${this.props.newsEntry.slug}&t=${this.props.newsEntry.title}`} title="">
                                        <img src="/img/fb-btn.png" className="card-img-top img-fluid" alt="" />
                                    </a>
                                    <a target="_blank" href={`https://twitter.com/intent/tweet?text=${this.props.newsEntry.title}&url=https://bonex.org/news/${this.props.newsEntry.slug}`}>
                                        <img src="/img/tw-btn.png" className="card-img-top img-fluid" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="article-title">
                                <h1><span>{this.props.newsEntry.title}</span></h1>
                            </div>
                            <div
                                className="text-box"
                                dangerouslySetInnerHTML={{ __html: this.props.newsEntry.content }}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-md-12">
                    </div>
                </div>
            );
        }
    }
};


function mapStateToProps({ newsEntry }) {
    return { newsEntry };
}

export default connect(mapStateToProps, { fetchNewsEntry })(NewsItem);
