import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrency, initCurrency, fetchCurrencies } from "../actions";

class Currencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  UNSAFE_componentWillMount() {
    this.props.fetchCurrencies();
  }

  componentDidMount() {
    const currency = localStorage.getItem("currency") || "BGN";
    // initials
    if (this.props.currencies && this.props.currencies[currency]) {
      this.props.initCurrency(this.props.currencies[currency]);
    }
    // db
    setTimeout(() => {
      if (this.props.currencies && this.props.currencies[currency]) {
        this.props.initCurrency(this.props.currencies[currency]);
      }
    }, 500)
  }

  renderCurrencyButton(currency) {
    return (
      <button
        className="btn btn-action btn-rounded has-currency"
        onClick={() => this.onCurrencySetClick(currency)}
        key={currency.type}
      >
        <img src={`/img/currencies/${currency.type}_Currencies-01.svg`} title="" />
        <img src={`/img/currencies/${currency.type}_Currencies-01-hover.svg`} title="" />
        {currency.type}
      </button>
    )
  }

  onCurrencySetClick(currency) {
    this.setState({ open: !this.state.open });
    this.props.setCurrency(currency);
  }

  renderCurrencyToggle() {
    const { currency, currencies } = this.props;
    if (currencies) {
      return (
        <li>
          {_.map(currencies, curr => {
            if (currency.type != curr.type) {
              return (
                <span key={curr.type}>{this.renderCurrencyButton(curr)}</span>
              )
            } else {
              return <span key={curr.type}></span>;
            }
          })}
        </li>
      );
    } else {
      return <li><span></span></li>
    }
  }

  render() {
    return (
      <div className={this.state.open ? "select-dropdown-box open" : "select-dropdown-box"}>
        <button onClick={() => this.setState({ open: !this.state.open })}>
          <img src={`/img/currencies/${this.props.currency.type}_Currencies-01.svg`} alt="" />
          <img src={`/img/currencies/${this.props.currency.type}_Currencies-01-hover.svg`} alt="" />
          {this.props.currency.type}
        </button>
        <ul>{this.renderCurrencyToggle()}</ul>
      </div>
    );
  }
}

const mapStateToProps = ({ currency, currencies }) => {
  return { currency, currencies };
};

export default connect(
  mapStateToProps,
  { setCurrency, initCurrency, fetchCurrencies }
)(Currencies);
