import { FETCH_BONUSES, CLEAR_BONUSES } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_BONUSES:
      return { ...state, ...action.payload };
    case CLEAR_BONUSES:
      return null;
    default:
      return state;
  }
}