import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fetchUser } from '../../actions';
import ResetForm from '../../components/auth/resetForm';

class ResetPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Нова парола - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Нова парола - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    onSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="form-cont">
                        <div id="auth" className="auth-tab-container open">
                            <ResetForm token={this.props.match.params.token} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, user }) {
    return { auth, user };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(ResetPage),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
