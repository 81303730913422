import _ from 'lodash';
import ExcelJS from 'exceljs'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchOrders, cancelOrder, fetchTransactions } from '../../actions';

const TABLE_TYPES = {
  ORDERS_TABLE: "ordersTable",
  TRANSACTIONS_TABLE: "transactionsTable",
}

const TABLE_ACTIONS = {
  BUY: "BUY",
  SELL: "SELL",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
}

class OrdersTableNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      rowsPerPage: 10,
      selectedTable: TABLE_TYPES.TRANSACTIONS_TABLE,
      tableAction: TABLE_ACTIONS.DEPOSIT,
    };
    this.changeTable = this.changeTable.bind(this)
  }

  changeTable(tableType, actionType) {
    this.setState({ selectedTable: tableType, tableAction: actionType })
  }

  handlePageChange(newPage) {
    this.setState({ currentPage: newPage });
  };

  UNSAFE_componentWillMount() {
    this.props.fetchTransactions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTable !== this.state.selectedTable) {
      if (this.state.selectedTable === TABLE_TYPES.TRANSACTIONS_TABLE) {
        this.props.fetchTransactions();
      } else if (this.state.selectedTable === TABLE_TYPES.ORDERS_TABLE) {
        this.props.fetchOrders();
      }
    }
  }

  async onClickCancel(orderId) {
    let msg = 'Сигурни ли сте, че желаете да откажете поръчката?';
    if (this.props.activeLanguage.code == 'en') {
      msg = 'Are you sure that you wish to cancel the order?';
    } else if (this.props.activeLanguage.code == 'ro') {
      msg = 'Sunteți siguri că doriți să renunțați la comanda dvs.?';
    } else if (this.props.activeLanguage.code == 'gr') {
      msg = 'Είστε σίγουροι, ότι θέλετε να ακυρώσετε την παραγγελία?';
    }
    if (!confirm(msg)) {
      return;
    }

    let action = await this.props.cancelOrder(orderId);
    if (action.success) {
      this.props.fetchOrders();
    }
  }

  //   {
  //     "id": 976,
  //     "user_id": 21529,
  //     "account_id": 579,
  //     "order_id": 1432,
  //     "reference": "222fce8a",
  //     "idn": null,
  //     "encoded": null,
  //     "checksum": null,
  //     "data": "{\"rateSub\":0,\"amount\":\"500\",\"price\":\"138.59\",\"fees\":2.63321,\"total\":141.22321,\"rate\":0.27718032,\"paymentMethod\":\"bank\",\"receiver\":\"\",\"receivingMethod\":\"account\",\"formValid\":true,\"formErrors\":{\"amount\":\"\",\"price\":\"\",\"paymentMethod\":\"\",\"receiver\":\"\",\"receivingMethod\":\"\",\"promoCode\":\"\"},\"amountValid\":true,\"priceValid\":true,\"paymentMethodValid\":true,\"receiverValid\":true,\"receivingMethodValid\":true,\"isLoading\":true,\"promoCode\":\"\",\"promoCodeUse\":false,\"account\":{\"id\":579,\"user_id\":21529,\"address_id\":15320,\"identificator\":\"0x66dfe2803c2455573bfffe2f163f7d68bf25aaeb\",\"title\":\"CHZ\",\"type\":\"CHZ\",\"network\":\"CHZ-ERC20\",\"exchange\":\"\",\"balance\":\"0.00000000\",\"pending\":\"0.00000000\",\"decimals\":8,\"active\":true,\"is_system\":false,\"_created\":\"2024-04-09T11:46:39.000Z\",\"_updated\":null},\"type\":\"BUY\",\"currency\":{\"type\":\"BGN\",\"symbol\":\"bgn\",\"rate\":\"1.00000000\",\"id\":1,\"title\":\"BGN\",\"network\":\"\",\"exchange\":\"\",\"query_currency\":\"\",\"min_deposit\":\"0.00000000\",\"number_confirmations\":0,\"is_fiat\":true,\"is_base\":true,\"show_decimals\":2,\"active\":true,\"featured\":false,\"order_pos\":0,\"trade_disabled\":false,\"_updated\":null}}",
  //     "phyre_data": null,
  //     "phyre_payment": null,
  //     "phyre_order": null,
  //     "phyre_link": null,
  //     "phyre_qr": null,
  //     "account_type": "CHZ",
  //     "account_identificator": "0x66dfe2803c2455573bfffe2f163f7d68bf25aaeb",
  //     "type": "REQUEST",
  //     "action": "BUY",
  //     "amount": "500.00000000",
  //     "price": "138.59",
  //     "total": "141.22",
  //     "rate": "0.28",
  //     "currency": "BGN",
  //     "decimals": 8,
  //     "time": "2024-04-09T11:52:19.000Z",
  //     "confirms": 1,
  //     "status": "PENDING",
  //     "_created": "2024-04-09T11:52:19.000Z",
  //     "_updated": null
  // }

  rows(currentData) {
    return _.map(currentData, (order, number) => {
      if (this.state.selectedTable === TABLE_TYPES.TRANSACTIONS_TABLE) {
        return (
          <tr key={order.id}>
            <td align="center">
              {order.action}
            </td>
            <td align="center">
              {order.account_type}
            </td>
            <td align="center">
              <time>
                {new Intl.DateTimeFormat(this.props.translate("lang"), {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).format(new Date(order._created))}
              </time>
            </td>
            <td align="center" name="action">
              {order.amount}
              {/* {order.amount} {" "} {order.account_type} remove account type */}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={order.id}>
            <td align="center">
              {order.action}
            </td>
            <td align="center">
              {order.account_type}
            </td>
            <td align="center" name="action">
              <time>
                {new Intl.DateTimeFormat(this.props.translate("lang"), {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).format(new Date(order._created))}
              </time>
            </td>
            <td align="center">
              {order.amount} {" "} {order.account_type}
            </td>
          </tr>
        );
      }
    });

  }

  render() {
    const { currentPage, rowsPerPage } = this.state;
    const data = Object.values(this.state.selectedTable === TABLE_TYPES.ORDERS_TABLE ? this.props.orders : this.props.transactions);

    // Determine which rows to display
    const startIdx = (currentPage - 1) * rowsPerPage;
    const filteredByAccountData = data.filter((accountInfo) => accountInfo.account_type === this.props.account.type && accountInfo.action === this.state.tableAction)
    const currentData = filteredByAccountData.slice(startIdx, startIdx + rowsPerPage);

    // Number of total pages
    const totalPages = Math.ceil(data.length / rowsPerPage);

    return (
      <div className="">
        <div>
          <button
            className={`profile-button filter-account-button ${this.state.tableAction === TABLE_ACTIONS.DEPOSIT ? `active-action-button` : ``}`}
            onClick={() => this.changeTable(TABLE_TYPES.TRANSACTIONS_TABLE, TABLE_ACTIONS.DEPOSIT)}
          >
            Deposit
          </button>
          <button
            className={`profile-button filter-account-button ${this.state.tableAction === TABLE_ACTIONS.WITHDRAW ? `active-action-button` : ``}`}
            onClick={() => this.changeTable(TABLE_TYPES.TRANSACTIONS_TABLE, TABLE_ACTIONS.WITHDRAW)}
          >
            Withdraw
          </button>
          <button
            className={`profile-button filter-account-button ${this.state.tableAction === TABLE_ACTIONS.BUY ? `active-action-button` : ``}`}
            onClick={() => this.changeTable(TABLE_TYPES.ORDERS_TABLE, TABLE_ACTIONS.BUY)}
          >
            Buy
          </button>
          <button
            className={`profile-button filter-account-button ${this.state.tableAction === TABLE_ACTIONS.SELL ? `active-action-button` : ``}`}
            onClick={() => this.changeTable(TABLE_TYPES.ORDERS_TABLE, TABLE_ACTIONS.SELL)}
          >
            Sell
          </button>
        </div>
        <div className="">
          <div className="affiliate-box">
            {filteredByAccountData.length === 0
              ?
              <div style={{ margin: "45px auto 20px", width: "100%" }}>
                <h3 style={{ textAlign: "center" }}>You still do not have any records in this category</h3>
              </div>
              :
              <div className="scroll-box records-box-new" >
                <table className="custom-table custom-table-new orders-table records-table-new" >
                  <thead className='cutom-table-head'>
                    <tr className='custom-table-row'>
                      <th style={{ textAlign: "center" }}>
                        <strong><Translate id="action" /></strong>
                      </th>
                      <th style={{ textAlign: "center" }}>
                        <strong><Translate id="name" /></strong>
                      </th>
                      <th style={{ textAlign: "center" }}>
                        <strong><Translate id="date" /></strong>
                      </th>
                      <th style={{ textAlign: "center" }}>
                        <strong><Translate id="amount" /></strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.rows(currentData)}
                  </tbody>
                </table>
              </div>}
            {filteredByAccountData.length < 11 ? null : (
              <div>
                <button className="pagination-button-new" type="button" onClick={() => this.handlePageChange(1)}>
                  <i className="fal fa-chevron-double-left"></i>
                </button>
                <button className="pagination-button-new" onClick={() => currentPage > 1 ? this.handlePageChange(currentPage - 1) : ""}>
                  <i className="fal fa-chevron-left"></i>
                </button>
                {Array.from({ length: totalPages }, (_, idx) => (
                  <button
                    key={idx}
                    onClick={() => this.handlePageChange(idx + 1)}
                    disabled={currentPage === idx + 1}
                    className={`pagination-button-new ${currentPage === idx + 1 ? `active-button` : ``}`}
                  >
                    {idx + 1}
                  </button>
                )).slice(currentPage < 4 ? 0 : currentPage - 3, currentPage < 4 ? 5 : currentPage + 2)
                  // .slice(startingPage, currentPage < 5 ? 5 : startingPage + 5)

                }
                <button className="pagination-button-new" onClick={() => currentPage === totalPages ? "" : this.handlePageChange(currentPage + 1)}>
                  <i className="fal fa-chevron-right"></i>
                </button>
                {
                  <button className="pagination-button-new" onClick={() => this.handlePageChange(totalPages)}>
                    <i className="fal fa-chevron-double-right"></i>
                  </button>
                }
              </div>
            )}
          </div>
        </div>
      </div >
    );
  }
};


function mapStateToProps({ orders, transactions, account, activeLanguage }) {
  return { orders, transactions, account };
}

export default connect(mapStateToProps, { fetchOrders, cancelOrder, fetchTransactions })(withLocalize(OrdersTableNew));