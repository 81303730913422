import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getFAQ } from "../../actions";

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            lang: 'bg'
        };
    }

    async componentDidMount() {
        let faq = await this.props.getFAQ();
        if (faq) {
            this.setState({ items: faq });
        } else {
            this.setState({ items: [] });
        }
        if (this.props.lang != 'bg') {
            this.setState({ lang: this.props.lang });
        }
    }

    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/covers/FAQ.png" alt="" />
                </div>
                <div className="article-full">
                    <div className="transcard">
                        {_.map(this.state.items, section => {
                            return (
                                <React.Fragment>
                                    <h2 className="text-center mb-5">{section[`title_${this.state.lang}`]}</h2>
                                    <div className="panel-group mb-5 accordion" id={`accordion${section.id}`} role="tablist" aria-multiselectable="true">
                                        <div className="panel panel-default">
                                            {_.map(section.items, item => {
                                                return (
                                                    <React.Fragment>
                                                        <div className="panel-heading" role="tab" id={`heading${section.id}${item.id}`}>
                                                            <h4 className="panel-title">
                                                                <a role="button" data-toggle="collapse" data-parent={`#accordion${section.id}`} href={`#collapse${section.id}${item.id}`} aria-expanded="true" aria-controls={`collapse${section.id}${item.id}`}>
                                                                    {item[`question_${this.state.lang}`]}
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id={`collapse${section.id}${item.id}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`collapse${section.id}${item.id}`}>
                                                            <div className="panel-body">
                                                                <p dangerouslySetInnerHTML={{ __html: item[`answer_${this.state.lang}`].replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ auth }, { }) {
    return { auth };
}

export default connect(mapStateToProps, { getFAQ })(Faq);
