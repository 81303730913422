import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { formatMoney } from "../../utilities/Format";

const AssetsHomeTableItemNew = props => {
  const prefix = !_.isEmpty(props.user) ? "accounts" : "accounts"; // "assets";

  const normalPrice = parseFloat(props.rate / props.currency.rate);
  const normalToFixedPrice = normalPrice.toFixed(3)

  const buyPrice = parseFloat(props.rate / props.currency.rate) +
    (parseFloat(props.rate / props.currency.rate) * parseFloat(props.config.buy_percent)) / 100;

  const sellPrice = parseFloat(props.rate / props.currency.rate) -
    (parseFloat(props.rate / props.currency.rate) * parseFloat(props.config.sell_percent)) / 100;

  // if (props.type == "USD" || props.type == "USDT" || props.type == "USDC" ) {

  //   console.log({
  //     props: props,
  //     normalPrice, 
  //     buyPrice, 
  //     sellPrice
  //   });
  // }

  // const аccountTitles = {
  //   BTC: "Bitcoin",
  //   ETH: "Ethereum",
  //   BGN: "Bulgarian Lev",
  //   BCH: "Bitcoin Cash",
  //   XRP: "Ripple",
  //   USDT: "Tether",
  //   RVN: "Ravencoin",
  //   ETC: "Ethereum Classic",
  //   UNI: "Uniswap",
  //   LINK: "Chainlink",
  //   AAVE: "Aave",
  //   USDC: "USDC",
  //   WLD: "Worldcoin",
  //   COMP: "Compound",
  //   AMP: "Amp",
  //   MATIC: "Polygon",
  //   ARB: "Arbitrum",
  //   MKR: "Maker",
  //   MASK: "Mask Network",
  //   SHIB: "Shiba Inu",
  //   PEPE: "Pepe",
  //   APE: "ApeCoin",
  //   SNX: "Synthetix",
  //   LDO: "Lido DAO",
  //   CRV: "Curve DAO Token",
  //   RNDR: "Render",
  //   SAND: "The Sandbox",
  //   MANA: "Decentraland",
  //   CAKE: "PancakeSwap",
  //   FLOKI: "FLOKI",
  //   MEME: "Memecoin"
  // };

  return (
    <tr style={{borderBottom: "1px solid white"}}>
      <td className="">
        <div className="crypto-icon">
          <figure style={{ backgroundColor: props.type == 'WLD' ? 'white' : '' }}>
            <img src={`/img/assets/${props.type.toLowerCase()}.svg`} alt={props.type} />
          </figure>
          <strong> {props.title}</strong>
          <div className="crypto-name">{props.type}</div>
        </div>
      </td>
      {/* <td>
        <span>{formatMoney(normalPrice)} <Translate id={props.currency.symbol} /></span>
      </td> */}
      <td>
        <p
          to={`/${prefix}/${props.type}/buy`}
          className=""
          style={{ color: "white", marginBottom: "0px" }}
        >
          {formatMoney(normalToFixedPrice, props.type === "ETH" ? 2 : props.show_decimals)} <Translate id={props.currency.symbol} />
        </p>
      </td>
      <td>
        <Link
          to={`/${prefix}/${props.type}`}
          className="btn-link-box-new"
          style={{ width: '120px' }}
        >
          <Translate id="trade" />
        </Link>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ currency }) => {
  return { currency };
};

export default connect(mapStateToProps)(AssetsHomeTableItemNew);
