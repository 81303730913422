import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Translate } from "react-localize-redux";
import { fetchUser } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
// import UserSidebar from "../../components/users/UserSidebar";
// import UserHeader from "../../components/users/UserHeader";
import PersonalVerificationForm from "../../components/verification/PersonalVerificationForm";

class PersonalVerificationPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Верификация - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Верификация - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12 col-lg-10 col-md-9 order-cols-right">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  <div className="row">
                    {/* <UserHeader /> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="white-box no-pad">
                        <div className="portlet-title tabbable-line">
                          <span>Select</span>
                          <ul className="nav nav-tabs" id="form-tabs">
                            <li className="active">
                              <a href="#verify-personal" data-toggle="tab">
                                <Translate id="personalData" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="portlet-body">
                          <div className="tab-content">
                            <div
                              className="tab-pane fade in active"
                              id="verify-personal"
                            >
                              <PersonalVerificationForm />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <UserSidebar current="verification" /> */}
          </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default {
  component: connect(mapStateToProps, { fetchUser })(
    requireAuth(PersonalVerificationPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser()),
};
