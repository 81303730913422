import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { fetchMessages } from "../../actions";
import Message from "./Message";

class MessagesList extends Component {
  componentDidMount() {
    this.props.fetchMessages();
  }

  render() {
    if (!_.isEmpty(this.props.messages)) {
      return (
        <div>
          {_.map(this.props.messages, message => {
            return (
              <Message
                entry={message}
                key={message.id}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p>
            <Translate id="empty.messages" />
          </p>
        </div>
      );
    }
  }
}

function mapStateToProps({ messages }) {
  return { messages };
}

export default connect(
  mapStateToProps,
  { fetchMessages }
)(MessagesList);
