import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { validateEmail, clearAuthErrors } from '../../actions';

const FIELDS = {
    token: {
        type: 'text',
        label: 'code',
        required: true,
        errorMsg: 'validationCodeIsInvalid'
    }
};

class ValidateForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            success: false
        }
    }

    handleFormSubmit({ token }) {
        this.props.validateEmail(token);
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;

        const className = `form-group ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input 
                            className="form-control placeholder-no-fix" 
                            {...input}
                            type={type} 
                        />
                        <label className="control-label visible-ie8 visible-ie9">
                            <Translate id={label} />
                        </label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{marginLeft: '16px'}}>
                                 <span><Translate id={`errors.${error}`} /></span>
                            </div> 
                        ) : ''} 
                    </div>
                </div>
            </div>
        );
    }

    renderAlert() {
        if (this.props.errorMessage && this.props.errorMessage != '') {
            return (
                <div className="alert alert-danger">
                    <strong><Translate id="oops" />!</strong> {this.props.errorMessage}
                </div>
            );
        } else if(this.props.user.validated && this.props.auth.authenticated) {
            return <Redirect to="/profile" />
        } else if(this.props.user.validated) {
            return <Redirect to="/" />
        }
    }

    componentWillUnmount() {
        this.props.clearAuthErrors();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div id="validate-form-container" className="validate-form-container">
                <form 
                    className="validate-form default-forms" 
                    id="validate-form" 
                    onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} 
                >
                    <div className="form-cont-header">
                        <h3 className="form-title">E-mail <Translate id="validation" /></h3>
                        <p><Translate id="insertCode" /> <strong>E-mail</strong></p>
                    </div>
                    <div className="form-group-box">
                        {_.map(FIELDS, (field, fieldName) => {
                            return <Field
                                name={fieldName}
                                component={this.renderField}
                                key={fieldName}
                                {...field}
                            />
                        })}
                        {this.renderAlert()}
                        <div className="form-actions">
                            <button type="submit" className="submit"><Translate id="send" /></button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName]) {
            errors[fieldName] = field.errorMsg;
        }
    });

    return errors;
}

function mapStateToProps({ auth, user }, { token }) {
    return {
        auth,
        user,
        initialValues: { token },
        errorMessage: auth && auth.error ? auth.error : ''
    };
}

export default connect(mapStateToProps, { validateEmail, clearAuthErrors })(
    reduxForm({
        validate,
        form: 'validate',
        fields: FIELDS,
        enableReinitialize : true
    })(ValidateForm)
)

