import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { fetchUser } from '../../actions';
import ForgotForm from '../../components/auth/forgotForm';

class ForgotPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Забравена парола - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Забравена парола - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="form-cont col-sm-5">
                        <div id="forgot" className="forgot-tab-container open">
                            <ForgotForm />
                            <div className="form-cont-footer">
                                <p><Translate id="rememberPass" />?</p>
                                <Link to="/login"><Translate id="signinProfile" />!</Link>
                            </div>
                        </div>
                    </div>
                    <div className='login-video-wrapper col-sm-7'>
                        <video className='login-video' width="170%" height="100%" autoPlay loop muted>
                            <source src='/videos/login-video.webm' type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(ForgotPage),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
