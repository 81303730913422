import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import { fetchLocalizedPagesEntry } from '../actions';
import PagesItem from '../components/pages/PagesItem';

class PrivacyPolicyPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Политика на Поверителност - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Политика на Поверителност - BoneX | Crypto Exchange`} />
                <meta property="og:url" content={`https://bonex.org/privacy`} />
            </Helmet>
        );
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        {this.head()}
                        <div className="intro-box">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="intro-text">
                                        <h2><span><Translate id="privacyPolicy" /></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="infoFigureBox p20" style={{display: "block"}}>
                            <PagesItem slug="privacy-policy" />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
};

function mapStateToProps({ pagesEntry }) {
    return { pagesEntry };
}

export default {
    component: connect(mapStateToProps, { fetchLocalizedPagesEntry })(
        PrivacyPolicyPage
    ),
    loadData: ({ dispatch }) => dispatch(fetchLocalizedPagesEntry('bg', 'privacy-policy'))
}