import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class MarginPlatformBG extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/margin/BANNER.png" alt="" />
          <h1 style={{ marginTop: '-30px' }}><Translate id="marginPlatfrom" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more"></h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>Търгуване</h3>
                    <ul className="text-left">
                      <li>Опростени процеси за търговия, които улесняват управлението на позиции и ефективното наблюдение на портфолиото ви.</li>
                      <li>Постоянна подкрепа от нашия екип от експерти, готови да ви помогнат с вашите запитвания и проблеми.</li>
                      <li>Бонуси и стимули, предназначени да подобрят вашето търговско изживяване.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/speed-icon.png" alt="" />
                    </figure>
                    <h3>Ефективност</h3>
                    <ul className="text-left">
                      <li>Bonex Margin Platform използва най-съвременните мерки за сигурност за да гарантира вашето спокойствие.</li>
                      <li>Оптимални печалби, възможни благодарение на нашите изключително ниски такси.</li>
                      <li>Афилиални и брокерски програми, които дават безброй възможности и подкрепят нашите потребители.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/currency-icon.png" alt="" />
                    </figure>
                    <h3>Възможности</h3>
                    <ul className="text-left">
                      <li>Нашата иновативна технология за търговия с Bonex Margin Platform, подходяща както за начинаещи, така и за опитни търговци, предоставя ефективни инструменти за оптимизиране на вашите стратегии.</li>
                      <li>Достъп до разнообразие от токени на нашата платформа, което ви дава нови възможности за търговия и за диверсифициране на портфолиото ви.</li>
                      <li>Търговия с Margin Trading отключва потенциала на търговци, които искат да максимизират стратегиите си.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MarginPlatformBG;
